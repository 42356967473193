import { ICalendar, IOpenHoursSlot } from '@er/data-hiring';
import { AvailabilityEditor } from '@er/ui-lib';
import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface IAddJobProps {
  onClose?: () => void;
  onSubmit?: (data: AddJobFormData) => void;
  addJobOpen: Boolean;
}

export type AddJobFormData = {
  title: string;
  description: string;
};

const defaultValues: AddJobFormData = {
  title: '',
  description: '',
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 700,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export const AddJob: FunctionComponent<IAddJobProps> = ({
  addJobOpen,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const { handleSubmit, formState, control, errors, reset } =
    useForm<AddJobFormData>({
      defaultValues,
    });

  const handleFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data);
  });

  const handleDialogClose = () => {
    reset();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={!!addJobOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="md"
    >
      <DialogTitle>Create New Job</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          style={{ gap: 10 }}
        >
          <Controller
            control={control}
            name="title"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Job Title"
                type="text"
                inputRef={ref}
                value={value}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof AddJobFormData}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Job Description"
                inputRef={ref}
                value={value}
                error={invalid}
                multiline
                minRows={15}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof AddJobFormData}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

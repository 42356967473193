import { Snackbar, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customAlert: {
      backgroundColor: '#ECF9F3',
      color: '#00C853',
      elevation: 6,
      boxShadow: theme.shadows[6],
    },
  })
);

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function SnackBarLayout({
  handleClose,
  open,
  message,
  duration = 3000,
}) {
  const classes = useStyles();

  return (
    <Snackbar
      style={{ marginTop: '45px' }} // to push it below the AppBar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert
        icon={false}
        onClose={handleClose}
        severity="success"
        className={classes.customAlert}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

import React from 'react';
import CentralCardLayout, {
  CentralCardLayoutProps,
} from '../../layout/central-card-layout/central-card-layout';
import SignUpPersonalDetails from '../sign-up-personal-details/sign-up-personal-details';
import PropTypes, { InferProps } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '../../common/stepper/stepper';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 25,
  },
}));

export type SignUpPersonalDetailsPageProps = InferProps<
  typeof SignUpPersonalDetailsPage.propTypes
>;

export function SignUpPersonalDetailsPage(
  props: SignUpPersonalDetailsPageProps
) {
  const { onSubmit, steps, currentStep, redirectLink } = props;
  const classes = useStyles();
  const layoutProps: CentralCardLayoutProps = {
  contentLogo: true,
  };
  if (steps > 0) {
    layoutProps.preContentElementHeight = 30;
    layoutProps.preContentElement = (
      <Stepper steps={steps} current={currentStep} />
    );
  }
  return (
    <CentralCardLayout {...layoutProps}>
      <div className={classes.content}>
        <SignUpPersonalDetails onSubmit={onSubmit} redirectLink={redirectLink} />
      </div>
    </CentralCardLayout>
  );
}

SignUpPersonalDetailsPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.number,
  currentStep: PropTypes.number,
  redirectLink: PropTypes.string,
};

SignUpPersonalDetailsPage.defaultProps = {
  currentStep: 1,
  steps: 0,
};

export default SignUpPersonalDetailsPage;

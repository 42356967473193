import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import PropTypes, { InferProps } from 'prop-types';

export type TextDividerProps = {
  color?: string;
};

const useStyles = makeStyles<Theme, TextDividerProps>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  border: {
    borderBottom: (props) =>
      `1px solid ${props.color || theme.palette.divider}`,
    width: '100%',
  },

  content: {
    padding: '0 10px 0 10px',
    color: (props) => props.color || theme.palette.divider,
  },
}));

export const TextDivider: FunctionComponent<TextDividerProps> = (props) => {
  const { children } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <div className={classes.border} />

      <Typography variant="body1" className={classes.content}>
        {children}
      </Typography>

      <div className={classes.border} />
    </div>
  );
};

TextDivider.propTypes = {
  color: PropTypes.string,
};

TextDivider.defaultProps = {};

export default TextDivider;

import { IUser } from '@er/data-hiring';
import Link from 'next/link';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';

export type GeneralUserPortalProps = {
  user: IUser;
};

export const GeneralUserPortal: FunctionComponent<GeneralUserPortalProps> = ({
  user,
}) => (
  <Container maxWidth="sm">
    <Card>
      <CardHeader title="User Portal"></CardHeader>
      <CardContent>
        <Typography variant="body1">
          Hello {user.firstName}! This is your {user.type} portal
        </Typography>
        <br />
        <Typography variant="body1">
          This is is generic portal page, once {user.type} portal will be
          developed, it will be displayed here instead
        </Typography>
        <Link href="/users/logout">
          <a>Logout</a>
        </Link>
      </CardContent>
    </Card>
  </Container>
);

export default GeneralUserPortal;

import mongoose, { model, Schema } from 'mongoose';
import {
  ICronofyPushNotification,
  ICronofyPushNotificationModel,
} from './cronofy-push-notification.interface';

export const CronofyPushNotificationSchema: Schema<
  ICronofyPushNotification,
  ICronofyPushNotificationModel
> = new Schema(
  {
    user: {
      ref: 'User',
      type: Schema.Types.ObjectId,
      required: true,
    },
    processed: { type: Boolean, default: false },
    changesSince: { type: Date },
  },

  { timestamps: true }
);

CronofyPushNotificationSchema.index({ processed: 1, user: 1 });

export const CronofyPushNotificationModel: ICronofyPushNotificationModel =
  (mongoose.models?.CronofyPushNotification ||
    model(
      'CronofyPushNotification',
      CronofyPushNotificationSchema
    )) as ICronofyPushNotificationModel;

import { IUser, USER_TYPES } from '@er/data-hiring';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export interface IChangeUserRoleProps {
  onClose: () => any;
  onConfirm: (user, selectedRole) => any;
  selectedUser: IUser;
}

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#000000',
    opacity: 0.55,
  },
  titleColorAndOpacity: {
    color: '#000000',
    opacity: 0.65,
  },
}));

export const ChangeUserRole: FunctionComponent<IChangeUserRoleProps> = ({
  selectedUser,
  onConfirm,
  onClose,
}) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(selectedUser?.type);

  const handleChange = (selectedValue) => {
    setSelectedValue(selectedValue);
  };

  return (
    <Dialog
      // open={!!addStageOpen}
      open={true}
      // onClose={onClose}
      onClose={() => console.log('On close')}
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box textAlign="center">
          <Typography variant="h5" className={classes.titleColorAndOpacity}>
            <b>Change User Role</b>
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="subtitle1"
            className={classes.textColorAndOpacity}
          >
            {selectedUser?.firstName + ' ' + selectedUser?.lastName}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <FormGroup>
            <FormControlLabel
              value="text1"
              control={
                <Checkbox
                  style={{ color: '#71A7A6' }}
                  checked={selectedValue === USER_TYPES.COMPANYSPACEOWNER}
                  onChange={(e) => handleChange(USER_TYPES.COMPANYSPACEOWNER)}
                />
              }
              label={<Typography variant="body1">Space Owner</Typography>}
            />
            <FormControlLabel
              value="text2"
              control={
                <Checkbox
                  style={{ color: '#71A7A6' }}
                  checked={selectedValue === USER_TYPES.ADMIN}
                  onChange={(e) => handleChange(USER_TYPES.ADMIN)}
                />
              }
              label={<Typography variant="body1">Admin</Typography>}
            />
            <FormControlLabel
              value="text3"
              control={
                <Checkbox
                  style={{ color: '#71A7A6' }}
                  checked={selectedValue === USER_TYPES.MEMBER}
                  onChange={(e) => handleChange(USER_TYPES.MEMBER)}
                />
              }
              label={<Typography variant="body1">Member</Typography>}
            />
            <Button
              onClick={() => onConfirm(selectedUser, selectedValue)}
              variant="contained"
              color="primary"
              disabled={!selectedValue}
              style={{
                marginTop: '20px',
              }}
            >
              Confirm
            </Button>
          </FormGroup>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeUserRole;

import mongoose, { model, Schema } from 'mongoose';
import { WEEK_DAYS } from './calendar.constants';

import {
  ICalendar,
  ICalendarModel,
  IOpenHoursSlot,
} from './calendar.interface';

export const OpenHoursSlotSchema: Schema<IOpenHoursSlot> = new Schema(
  {
    days: [{ type: Number, enum: Object.values(WEEK_DAYS) }],
    startTimeUTC: Number,
    endTimeUTC: Number,
  },
  {
    timestamps: false,
    _id: false,
  }
);

export const CalendarSchema: Schema<ICalendar, ICalendarModel> = new Schema(
  {
    user: { type: Schema.Types.ObjectId, ref: 'User', required: true },
    name: String,
    isPrimary: { type: Boolean, default: true, required: true },
    openHours: [{ type: OpenHoursSlotSchema }],
  },
  {
    timestamps: true,
  }
);

export const CalendarModel: ICalendarModel = (mongoose.models?.Calendar ||
  model('Calendar', CalendarSchema)) as ICalendarModel;

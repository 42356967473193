import React, { useEffect, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

import {
  IconButton,
  CircularProgress,
  Card,
  CardContent,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Link from 'next/link';
import { capitalize, kebabCase } from 'lodash';
import { AdminSideBar, ADMIN_PAGES } from './admin-sidebar';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    textAlign: 'center',
    alignContent: 'stretch',
    height: '100vh',
    boxSizing: 'border-box',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(4),
  },
  contentSection: {
    flexGrow: 1,
    padding: theme.spacing(0, 3),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export type IAdminLayoutProps = {
  error?: string;
  loading?: boolean;
  ready?: boolean;
  title?: string;
  page: ADMIN_PAGES | number;
};

export const AdminLayout: FunctionComponent<IAdminLayoutProps> = ({
  error = false,
  loading = false,
  ready = true,
  title,
  page,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link href={`/`}>
            <IconButton edge="start" color="inherit">
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <Typography variant="h2">ER Admin</Typography>
        </Toolbar>
      </AppBar>
      <AdminSideBar page={page} />
      <div className={classes.contentSection}>
        <Toolbar />
        <main className={classes.content}>
          {title && (
            <Card className={classes.title}>
              <CardContent>
                <Typography variant="h4">{title}</Typography>
              </CardContent>
            </Card>
          )}
          {loading && <CircularProgress className={classes.loading} />}
          {error && (
            <Alert severity="error">
              <Typography color="error" variant="body1">
                {error}
              </Typography>
            </Alert>
          )}
          {ready ? children : null}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;

import { MenuItem, Menu, Button, Divider, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import React, { useState } from 'react';
import SideBar from './sidebar';
import Alert from '@material-ui/lab/Alert';
import { useRouter } from 'next/router';

import { IUser, USER_TYPES } from '@er/data-hiring';
import { MyAvailabilityCompanyUser } from './my-availability';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSidebar } from './sidebar-context';

const labels = require('@er/ui-lib/lib/common/shared/labels.js');

export const drawerWidth = 150;
export const closedDrawerWidth = 57;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      marginLeft: closedDrawerWidth,
      width: `calc(100% - ${closedDrawerWidth}px)`,
      zIndex: theme.zIndex.drawer,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: -20,
    },
    hide: {
      display: 'none',
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
    },
    contentPadding: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },

    grow: {
      flexGrow: 1,
    },
  })
);

function calculateNumberOfCompletedSteps(user) {
  let totalNumberOfSteps;

  const adminOrSpaceOwner =
    user?.type === USER_TYPES.ADMIN ||
    user?.type === USER_TYPES.COMPANYSPACEOWNER;

  if (adminOrSpaceOwner) {
    totalNumberOfSteps = 4;
  } else {
    totalNumberOfSteps = 3;
  }

  let completedSteps = 0;

  if (user?.calendarSynced) {
    completedSteps += 1;
  }

  if (user?.availabilitySet) {
    completedSteps += 1;
  }

  const company = user?.company;

  if (
    adminOrSpaceOwner &&
    company?.name &&
    company?.description &&
    company?.website
  ) {
    completedSteps += 1;
  }

  if (user?.firstName && user?.lastName && user?.jobTitle) {
    completedSteps += 1;
  }

  return [completedSteps, totalNumberOfSteps];
}

export function userFullyConfigured(user) {
  const fullyConfigured =
    user &&
    user.calendarSynced &&
    user.timeZone &&
    user.availabilitySet &&
    user.firstName &&
    user.lastName &&
    user.jobTitle;

  if (!fullyConfigured) {
    return false;
  }

  if (
    user &&
    (user?.type === USER_TYPES.ADMIN ||
      user?.type === USER_TYPES.COMPANYSPACEOWNER)
  ) {
    return allCompanyDetailsSetup(user);
  }

  return fullyConfigured;
}

export function allCompanyDetailsSetup(user) {
  return (
    user?.company?.name && user?.company?.description && user?.company?.website
  );
}

export default function BaseLayout({
  children,
  noContentPadding = false,
  companyId,
  user,
  myAvailabilityInitialState = false,
}) {
  const classes = useStyles();
  const { open, toggleSidebar } = useSidebar();

  const [myAvailabilityOpen, setMyAvailabilityOpen] = useState<Boolean>(
    myAvailabilityInitialState
  );

  const router = useRouter();

  function handleViewTasksClick() {
    router.push('/user-finish-setup-profile');
  }

  function displayNumberOfCompletedSteps() {
    let [completedSteps, totalNumberOfSteps] =
      calculateNumberOfCompletedSteps(user);

    return labels.CompanyUserSetupUnfinished(
      completedSteps,
      totalNumberOfSteps
    );
  }

  function displayUserDidNotFinalizedSettingUpAccount() {
    if (
      user &&
      (user.type === USER_TYPES.ADMIN ||
        user.type === USER_TYPES.COMPANYSPACEOWNER ||
        user.type === USER_TYPES.MEMBER) &&
      !userFullyConfigured(user)
    ) {
      return (
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={handleViewTasksClick}>
              View tasks
            </Button>
          }
          style={{ marginBottom: '8px' }}
        >
          {displayNumberOfCompletedSteps()}
        </Alert>
      );
    }
  }

  function myAvailabilityClicked() {
    setMyAvailabilityOpen(true);
  }

  const onMyAvailabilityClose = () => {
    setMyAvailabilityOpen(false);
  };

  const hirehacLogoClicked = () => {
    router.push('/interviews/my-interviews');
  };

  const displayCollapse = () => {
    if (open) {
      return (
        <IconButton
          onClick={toggleSidebar}
          style={{ padding: '0px', marginLeft: '-20px' }}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          onClick={toggleSidebar}
          style={{ padding: '0px', marginLeft: '-20px' }}
        >
          <ChevronRightIcon fontSize="large" />
        </IconButton>
      );
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        {myAvailabilityOpen && (
          <MyAvailabilityCompanyUser
            onClose={onMyAvailabilityClose}
            user={user}
          />
        )}
        <AppBar
          position="fixed"
          elevation={0}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {displayCollapse()}
            <div className={classes.grow}></div>
            <Button onClick={() => myAvailabilityClicked()}>
              My Availability
            </Button>
          </Toolbar>
          <Divider />
        </AppBar>
        <SideBar
          open={open}
          onDrawerClose={hirehacLogoClicked}
          onDrawerOpen={hirehacLogoClicked}
          companyId={companyId}
          user={user}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentPadding]: !noContentPadding,
          })}
        >
          <div className={classes.toolbar} />
          {displayUserDidNotFinalizedSettingUpAccount()}
          {children}
        </main>
      </div>
    </div>
  );
}

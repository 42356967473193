import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/Add';

export enum CALENDAR_TYPE {
  GOOGLE = 'GooGle',
  MICROSOFT = 'Microsoft',
}

export interface LogoProps {
  width?: string | number;
  height?: string | number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: (props: LogoProps) => props.width,
      height: (props: LogoProps) => props.height,
    },
  })
);

const textUseStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

export function GoogleCalendarLogo(props: LogoProps) {
  const styleProps = {
    width: props.width,
    height: props.height,
  };

  const classes = useStyles(styleProps);

  let image: any = require('libs/ui-lib/src/lib/common/logo/google-calendar.png?resize&format=webp');

  const imageSrc = require('libs/ui-lib/src/lib/common/logo/google-calendar.png');

  return (
    <picture>
      <source srcSet={image.src} type="image/webp" />
      <source srcSet={imageSrc.src} type="image/png" />
      <img
        src={imageSrc.src}
        alt="elitRecruiter logo"
        className={classes.img}
      />
    </picture>
  );
}

export function OutlookCalendarLogo(props: LogoProps) {
  const styleProps = {
    width: props.width,
    height: props.height,
  };

  const classes = useStyles(styleProps);

  let image: any = require('libs/ui-lib/src/lib/common/logo/outlook-calendar.png?resize&format=webp');

  const imageSrc = require('libs/ui-lib/src/lib/common/logo/outlook-calendar.png');

  return (
    <picture>
      <source srcSet={image.src} type="image/webp" />
      <source srcSet={imageSrc.src} type="image/png" />
      <img
        src={imageSrc.src}
        alt="elitRecruiter logo"
        className={classes.img}
      />
    </picture>
  );
}

export function GoogleMeetLogo(props: LogoProps) {
  const styleProps = {
    width: props.width,
    height: props.height,
  };

  const classes = useStyles(styleProps);

  let image: any = require('libs/ui-lib/src/lib/common/logo/google-meet.png?resize&format=webp');

  const imageSrc = require('libs/ui-lib/src/lib/common/logo/google-meet.png');

  return (
    <picture>
      <source srcSet={image.src} type="image/webp" />
      <source srcSet={imageSrc.src} type="image/png" />
      <img src={imageSrc.src} alt="Google Meet logo" className={classes.img} />
    </picture>
  );
}

export function MicrosoftTeamsLogo(props: LogoProps) {
  const styleProps = {
    width: props.width,
    height: props.height,
  };

  const classes = useStyles(styleProps);

  let image: any = require('libs/ui-lib/src/lib/common/logo/microsoft-teams.png?resize&format=webp');

  const imageSrc = require('libs/ui-lib/src/lib/common/logo/microsoft-teams.png');

  return (
    <picture>
      <source srcSet={image.src} type="image/webp" />
      <source srcSet={imageSrc.src} type="image/png" />
      <img src={imageSrc.src} alt="Google Meet logo" className={classes.img} />
    </picture>
  );
}

export function Office365Logo(props: LogoProps) {
  const styleProps = {
    width: props.width,
    height: props.height,
  };

  const classes = useStyles(styleProps);

  let image: any = require('libs/ui-lib/src/lib/common/logo/office-365.png?resize&format=webp');

  const imageSrc = require('libs/ui-lib/src/lib/common/logo/office-365.png');

  return (
    <picture>
      <source srcSet={image.src} type="image/webp" />
      <source srcSet={imageSrc.src} type="image/png" />
      <img
        src={imageSrc.src}
        alt="elitRecruiter logo"
        className={classes.img}
      />
    </picture>
  );
}

export function IntegrationCard({ onConnectClick = null, calendarType }) {
  const classes = textUseStyles();

  return (
    <Card style={{ width: '400px', margin: '0 10px' }} elevation={2}>
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: '12px', fontWeight: 600 }}
          align="center"
        >
          {calendarType === CALENDAR_TYPE.GOOGLE ? 'Google' : 'Microsoft 365'}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            flex={1}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {calendarType === CALENDAR_TYPE.GOOGLE ? (
              <GoogleCalendarLogo width={'50px'} height={'50px'} />
            ) : (
              <OutlookCalendarLogo width={'50px'} height={'50px'} />
            )}
            <div>
              <Typography
                className={classes.textColorAndOpacity}
                style={{ fontSize: '18px' }}
              >
                Calendar
              </Typography>
              <Divider
                style={{
                  width: '80%',
                  marginTop: '4px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </div>
          </Box>

          <AddIcon style={{ color: 'grey' }} />

          <Box
            flex={1}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {calendarType === CALENDAR_TYPE.GOOGLE ? (
              <GoogleMeetLogo width={'50px'} height={'50px'} />
            ) : (
              <MicrosoftTeamsLogo width={'50px'} height={'50px'} />
            )}

            <div>
              <Typography
                className={classes.textColorAndOpacity}
                style={{ fontSize: '18px' }}
              >
                {calendarType === CALENDAR_TYPE.GOOGLE ? 'Meet' : 'Teams'}
              </Typography>
              <Divider
                style={{
                  width: '80%',
                  marginTop: '4px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </div>
          </Box>
        </Box>
        <Typography
          className={classes.textColorAndOpacity}
          align="center"
          style={{ marginTop: '12px' }}
        >
          {calendarType === CALENDAR_TYPE.GOOGLE
            ? 'Sync Google Calendar to manage your interview availability and automatically add Meet meeting details to scheduled interviews.'
            : 'Sync Office 365 Calendar to manage your interview availability and automatically add Teams meeting details to scheduled interviews.'}
        </Typography>

        {onConnectClick ? (
          <Button
            fullWidth
            style={{
              color: '#0D293A',
              opacity: 0.85,
              marginTop: '48px',
              fontWeight: 600,
            }}
            color="primary"
            type="submit"
            onClick={onConnectClick}
          >
            Connect
          </Button>
        ) : (
          <Typography
            style={{
              color: '#0D293A',
              opacity: 0.85,
              marginTop: '48px',
              fontWeight: 600,
            }}
            align="center"
          >
            CONNECTED
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export const REDIRECT_LINK_CRONOFY = '/settings/integrations/';

export function SyncCalendarForm({ user, absoluteURL, cronofyClientId }) {
  const routeChangeGoogle = () => {
    const url =
      `https://${process.env.NEXT_PUBLIC_CRONOFY_LINK_REGION}.cronofy.com/oauth/v2/authorize?response_type=code&client_id=` +
      cronofyClientId +
      '&redirect_uri=' +
      absoluteURL +
      REDIRECT_LINK_CRONOFY +
      '&scope=read_write&state=' +
      user._id +
      '&avoid_linking=true' +
      '&provider_name=google';

    document.location.href = url;
  };

  const routeChangeOffice = () => {
    const url =
      `https://${process.env.NEXT_PUBLIC_CRONOFY_LINK_REGION}.cronofy.com/oauth/v2/authorize?response_type=code&client_id=` +
      cronofyClientId +
      '&redirect_uri=' +
      absoluteURL +
      REDIRECT_LINK_CRONOFY +
      '&scope=read_write&state=' +
      user._id +
      '&avoid_linking=true' +
      '&provider_name=office365';

    document.location.href = url;
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IntegrationCard
          onConnectClick={routeChangeGoogle}
          calendarType={CALENDAR_TYPE.GOOGLE}
        ></IntegrationCard>

        <IntegrationCard
          onConnectClick={routeChangeOffice}
          calendarType={CALENDAR_TYPE.MICROSOFT}
        ></IntegrationCard>
      </div>
    </Box>
  );
}

export default SyncCalendarForm;

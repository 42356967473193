import { IJob } from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export interface IJobArchiveConfirmProps {
  job: IJob;
  onClose: () => any;
  onSubmit: (job: IJob) => any;
}

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

export const JobArchiveConfirm: FunctionComponent<IJobArchiveConfirmProps> = ({
  job,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={true} disableBackdropClick maxWidth="sm">
      <DialogTitle>
        <Box textAlign="center">
          <Typography variant="h5" className={classes.textColorAndOpacity}>
            <strong>Archive Job</strong>
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="subtitle1"
            className={classes.textColorAndOpacity}
            style={{
              fontWeight: 600,
              marginBottom: '-8px',
            }}
          >
            {job?.title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px' }}
          >
            Upon confirming, this job will be moved to Archived.
          </Typography>

          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px' }}
          >
            Please note, all active invites will expire.
          </Typography>

          <br />
          <Button
            onClick={() => onSubmit(job)}
            style={{
              marginBottom: '15px',
              color: '#FFFFFF',
              backgroundColor: '#FA595F',
              opacity: 0.85,
            }}
            variant="contained"
          >
            CONFIRM
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default JobArchiveConfirm;

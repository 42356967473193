import mongoose, { model, Schema } from 'mongoose';
import { SIGNUP_INVITE_STATUSES } from './signup-invite.constants';
import { ISignUpInvite, ISignUpInviteModel } from './signup-invite.interface';

export const SignUpInviteSchema: Schema<ISignUpInvite, ISignUpInviteModel> =
  new Schema(
    {
      email: { type: String, required: true },
      company: { type: Schema.Types.ObjectId, ref: 'Company' },
      expiresAt: { type: Date, required: true },
      status: {
        type: String,
        enum: Object.values(SIGNUP_INVITE_STATUSES),
        default: SIGNUP_INVITE_STATUSES.ACTIVE,
        required: true,
      },
      token: { type: String, required: true },
    },
    { timestamps: true }
  );

SignUpInviteSchema.index({ company: 1 });
SignUpInviteSchema.index({ email: 1 });

export const SignupInviteModel: ISignUpInviteModel = (mongoose.models
  ?.SignUpInvite ||
  model('SignUpInvite', SignUpInviteSchema)) as ISignUpInviteModel;

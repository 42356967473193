import { IInterview, IInterviewSegment } from '@er/data-hiring';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';

export interface IAdminAgendaEditorMarkAsNoShowProps {
  interview: IInterview;
  onClose: () => any;
  refreshAgendaEditorData: () => any;
  selectCandidateOrInterviewerNoShow: boolean;
  interviewSegment: IInterviewSegment;
}

const CandidateCard = 'CandidateCard';
const InterviewerCard = 'InterviewerCard';

export const AdminAgendaEditorMarkAsNoShow: FunctionComponent<IAdminAgendaEditorMarkAsNoShowProps> =
  ({
    interview,
    interviewSegment,
    onClose,
    refreshAgendaEditorData,
    selectCandidateOrInterviewerNoShow,
  }) => {
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const executeMarkAsNoShow = async () => {
      if (selectedCard == CandidateCard) {
        setLoading(true);
        try {
          const result = await fetch(
            `/api/interviews/mark-as-no-show/candidate/${interview._id}`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'POST',
            }
          ).then((r) => r.json());
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      } else if (selectedCard == InterviewerCard) {
        setLoading(true);
        try {
          await fetch(
            `/api/interviews/mark-as-no-show/interviewer/${interview._id}`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'POST',
            }
          ).then((r) => r.json());
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }

      refreshAgendaEditorData();
      onClose();
    };

    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClick = (card) => {
      setSelectedCard(card);
      setConfirmButtonDisabled(false);
    };

    return (
      <Dialog
        open={true}
        onClose={() => console.log('On close')}
        disableBackdropClick
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5">
              <b>Mark-No-Show</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle1">{interview?.title}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {loading && <CustomCircularLoading />}
          <Box textAlign="center">
            <Typography variant="h6" style={{ marginBottom: '25px' }}>
              Select absent attendee
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Card
                elevation={2}
                onClick={() => handleCardClick(CandidateCard)}
                style={{
                  flex: '1',
                  marginRight: '16px',
                  backgroundColor:
                    selectedCard === CandidateCard ? '#EEE' : '#FFF',
                  maxWidth: '180px',
                }}
              >
                <CardContent style={{ maxWidth: '180px' }}>
                  <IconButton>
                    <PersonOutlineIcon />
                  </IconButton>
                  <Typography variant="body1">Candidate</Typography>
                </CardContent>
              </Card>
              {!!selectCandidateOrInterviewerNoShow && (
                <Card
                  elevation={2}
                  onClick={() => handleCardClick(InterviewerCard)}
                  style={{
                    flex: '1',
                    backgroundColor:
                      selectedCard === InterviewerCard ? '#EEE' : '#FFF',
                    maxWidth: '180px',
                  }}
                >
                  <CardContent style={{ maxWidth: '180px' }}>
                    <IconButton>
                      <PersonIcon />
                    </IconButton>
                    <Typography variant="body1">Interviewer</Typography>
                  </CardContent>
                </Card>
              )}
            </div>
            <Button
              onClick={() => executeMarkAsNoShow()}
              color="primary"
              variant="contained"
              disabled={confirmButtonDisabled}
              style={{
                marginTop: '25px',
                marginBottom: '25px',
              }}
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default AdminAgendaEditorMarkAsNoShow;

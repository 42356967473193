import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AvailabilityEditor, ITimeRange, SelectTimezone } from '@er/ui-lib';
import { Box, Button, IconButton, Link, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { IUser } from '@er/data-hiring';
import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';

export const LOW_AVAILABILITY_THRESHOLD = 20;

const useStyles = makeStyles((theme) => ({}));

export interface ISetAvailabilityPageProps {
  onSubmit?: (
    iavailabilityMap: Map<string, ITimeRange[]>,
    timeZone: string
  ) => any;
  onRefreshClick?: () => any;
  steps?: number;
  currentStep?: number;
  user: IUser;
  longestInterviewDuration?: number;
  schedulingOptionsCount?: number;
  refreshButtonDisabled?: boolean;
  availabilityMap?: any;
  handleAddSlot?: (dayOfWeek: string) => any;
  handleSlotChange?: (
    dayOfWeek: string,
    id: number,
    startTime: string,
    endTime: string
  ) => any;
  handleSlotDelete?: (dayOfWeek: string, id: number) => any;
}

export type IUserTimezoneData = {
  timeZone: string;
};

export function userHasCalendarSynced(user) {
  return user && user?.calendarSynced;
}

export const SetAvailabilityPage: FunctionComponent<ISetAvailabilityPageProps> =
  ({
    onSubmit,
    onRefreshClick,
    steps,
    currentStep,
    user,
    longestInterviewDuration,
    schedulingOptionsCount,
    refreshButtonDisabled,
    availabilityMap,
    handleAddSlot,
    handleSlotChange,
    handleSlotDelete,
  }) => {
    const classes = useStyles();

    const [timeZone, setTimezone] = useState(user.timeZone);

    const defaultValues: IUserTimezoneData = {
      timeZone: user.timeZone,
    };
    const { handleSubmit, formState, control, errors, reset } =
      useForm<IUserTimezoneData>({
        defaultValues,
      });

    const handleSave = (evt) => {
      evt.preventDefault();

      if (onSubmit) onSubmit(availabilityMap, timeZone);
    };

    function displayAvailabilitySlots() {
      if (userHasCalendarSynced(user)) {
        return (
          <div>
            <Box
              textAlign="center"
              style={{ marginBottom: '24px', marginTop: '16px' }}
            >
              <Controller
                control={control}
                name="timeZone"
                rules={{
                  required: 'Timezone is required',
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <SelectTimezone
                    style={{
                      maxWidth: '342px',
                    }}
                    onBlur={onBlur}
                    label="Timezone"
                    inputRef={ref}
                    defaultValue={user.timeZone}
                    value={value}
                    current
                    error={invalid}
                    small
                    onChange={(value) => {
                      setTimezone(value);
                      onChange(value);
                    }}
                  />
                )}
              />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <div
                style={{
                  width: '422px',
                  textAlign: 'center',
                }}
              >
                <AvailabilityEditor
                  availabilityMap={availabilityMap}
                  onAdd={handleAddSlot}
                  onChange={handleSlotChange}
                  onDelete={handleSlotDelete}
                />
              </div>
            </Box>
            <Box
              textAlign="center"
              style={{ marginBottom: '16px', marginTop: '48px' }}
            >
              <Button onClick={handleSave} variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </div>
        );
      }

      return (
        <Typography variant="body1" align="center">
          You have not synced your calendar yet. Please sync your calendar
          before setting up 'My Availability'
        </Typography>
      );
    }

    function displayIconBasedOnSchedulingOptionsCount() {
      if (schedulingOptionsCount === 0) {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <WarningIcon style={{ color: 'red' }} />
            &nbsp; &nbsp;
            <Typography variant="body1" style={{ color: '#0D293A' }}>
              {' '}
              &nbsp; You have zero scheduling options!
            </Typography>
          </Box>
        );
      } else if (schedulingOptionsCount < LOW_AVAILABILITY_THRESHOLD) {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <WarningIcon style={{ color: 'orange' }} />
            &nbsp; &nbsp;
            <Typography variant="body1" style={{ color: '#0D293A' }}>
              {' '}
              &nbsp; You’re below the minimum threshold of scheduling options.
            </Typography>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <DoneIcon style={{ color: 'green' }} />
            &nbsp; &nbsp;
            <Typography variant="body1" style={{ color: '#0D293A' }}>
              {' '}
              &nbsp; You're above the minimum threshold of scheduling options
            </Typography>
          </Box>
        );
      }
    }

    function displaySchedulingOptionsCount() {
      return (
        <Typography variant="body1" align="center">
          {displayIconBasedOnSchedulingOptionsCount()}
          Scheduling options: &nbsp;
          <strong>{schedulingOptionsCount}</strong>
          <IconButton
            color="primary"
            style={{ padding: '4px' }}
            aria-label="settings"
            disabled={refreshButtonDisabled}
            onClick={() => onRefreshClick()}
          >
            <RefreshIcon />
          </IconButton>
          <br />
          <Typography
            variant="body1"
            style={{ color: 'grey', marginTop: '-8px' }}
          >
            {longestInterviewDuration} minutes interview
          </Typography>
        </Typography>
      );
    }

    return (
      <Box maxWidth={1000}>
        <Typography
          variant="h4"
          align="center"
          style={{ marginBottom: '16px' }}
        >
          My Availability
        </Typography>
        {displaySchedulingOptionsCount()}
        {displayAvailabilitySlots()}
      </Box>
    );
  };

export default SetAvailabilityPage;

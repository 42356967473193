import { IJob } from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export interface IJobReactivateConfirmrops {
  job: IJob;
  onClose: () => any;
  onSubmit: (job: IJob) => any;
}

export const JobReactivateConfirm: FunctionComponent<IJobReactivateConfirmrops> =
  ({ job, onClose, onSubmit }) => {
    return (
      <Dialog
        open={true}
        onClose={() => console.log('On close')}
        disableBackdropClick
        maxWidth="md"
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5">
              <b>Reactivate Job?</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="body1">
              Upon confirmation, the job will be moved back to Active state.
            </Typography>
            <br />
            <Button
              onClick={() => onSubmit(job)}
              variant="contained"
              color="primary"
              style={{ marginBottom: '24px' }}
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default JobReactivateConfirm;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { any } from 'prop-types';

/* eslint-disable-next-line */
export interface ElitRecruiterLogoProps {
  width?: string | number;
  height?: string | number;
  light?: boolean;
  onClick?: () => void;
  type?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: (props: ElitRecruiterLogoProps) => props.width,
      height: (props: ElitRecruiterLogoProps) => props.height,
    },
  })
);

export function ElitRecruiterLogo(props: ElitRecruiterLogoProps) {
  const styleProps = {
    width: props.width,
    height: props.height,
  };
  const classes = useStyles(styleProps);

  let image: any;
  let imageSrc;

  if (props?.type === 'short') {
    image = require('./hirehac-logo-short-dark.png?resize&format=webp');
    imageSrc = require('./hirehac-logo-short-dark.png');
  } else {
    image = require('./hirehac-logo.png?resize&format=webp');
    imageSrc = require('./hirehac-logo.png');
  }

  return (
    <picture onClick={props.onClick}>
      <source srcSet={image.src} type="image/webp" />
      <source srcSet={imageSrc.src} type="image/png" />
      <img
        src={imageSrc.src}
        alt="elitRecruiter logo"
        className={classes.img}
      />
    </picture>
  );
}

ElitRecruiterLogo.defaultProps = {
  width: 200,
  height: 'auto',
};

export default ElitRecruiterLogo;

import React, { Component, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes, { InferProps } from 'prop-types';
import { GoogleLogin } from 'react-google-login';
import validate from 'validate.js';
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import SelectTimezone from '../../common/select-timezone/select-timezone';
import router from 'next/router';

type FormData = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  timeZone: string;
};

export type SignUpPersonalDetailsProps = InferProps<
  typeof SignUpPersonalDetails.propTypes
>;

const useStyles = makeStyles((theme) => ({
  google: {
    textAlign: 'center',
  },
  buttonProgress: {
    position: 'relative',
    left: 20,
  },
}));
export function SignUpPersonalDetails(props: SignUpPersonalDetailsProps) {
  const { onSubmit, processing, google, redirectLink } = props;

  const classes = useStyles();

  const [disabled, setDisabled] = useState(true);

  const { handleSubmit, formState, control, errors } = useForm<FormData>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      timeZone: '',
    },
  });
  

  const onFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data);    
    if (redirectLink)
    {
      router.push(redirectLink);
    }
  });

  useEffect(() => {
    setDisabled(processing || !formState.isValid);
  }, [formState, processing]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <form onSubmit={onFormSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Controller
                control={control}
                name="firstName"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="lastName"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>          
            <Grid item>
              <Controller
                control={control}
                name="jobTitle"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="Job Title"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="timeZone"
                rules={{
                  required: 'Timezone is required',
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <SelectTimezone
                    onBlur={onBlur}
                    fullWidth
                    label="Select Timezone"
                    inputRef={ref}
                    value={value}
                    current
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(value) => onChange(value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                disabled={
                  processing || (formState.isDirty && !formState.isValid)
                }
              >
                Next
                {processing && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

SignUpPersonalDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  google: PropTypes.bool,
  erLogo: PropTypes.bool,
  resetPasswordLink: PropTypes.element,
  redirectLink: PropTypes.string,
};

SignUpPersonalDetails.defaultProps = {
  processing: false,
};

export default SignUpPersonalDetails;

import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';

const SidebarContext = createContext({
  open: false,
  toggleSidebar: () => {},
});

export function useSidebar() {
  return useContext(SidebarContext);
}

export function SidebarProvider({ children }) {
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    // Read from localStorage only after the component has mounted
    const savedState = localStorage.getItem('sidebarIsOpen');
    if (savedState !== null) {
      setIsOpen(JSON.parse(savedState));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sidebarIsOpen', JSON.stringify(open));
  }, [open]);

  const toggleSidebar = () => {
    setIsOpen(!open);
  };

  return (
    <SidebarContext.Provider value={{ open, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
}

import mongoose, { Document, model, Model, Schema } from 'mongoose';
import { StageSchema } from '../job';
import { HIRING_PROCESS_STATUSES } from './hiring-process.constants';
import {
  IHiringProcess,
  IHiringProcessModel,
} from './hiring-process.interface';

export const HiringProcessStageSchema = new Schema(
  {
    stage: {
      type: Schema.Types.ObjectId,
      ref: mongoose.models?.Stage2 || model('Stage2', StageSchema),
    },
    interviews: [{ type: Schema.Types.ObjectId, ref: 'Interview' }],
  },
  {
    timestamps: false,
    _id: false,
  }
);

export const HiringProcessSchema: Schema<IHiringProcess, IHiringProcessModel> =
  new Schema(
    {
      status: {
        type: String,
        required: true,
        default: HIRING_PROCESS_STATUSES.ACTIVE,
      },
      candidate: { type: Schema.Types.ObjectId, ref: 'User', required: true },
      job: { type: Schema.Types.ObjectId, ref: 'Job', required: true },
      stages: [{ type: HiringProcessStageSchema }],
      currentStageOnTheBoard: {
        type: Schema.Types.ObjectId,
        ref: mongoose.models?.Stage2 || model('Stage2', StageSchema),
      },
      currentStage: {
        type: Schema.Types.ObjectId,
        ref: mongoose.models?.Stage2 || model('Stage2', StageSchema),
      },
      dispositionedTimeUTC: { type: Date },
      hiredTimeUTC: { type: Date },
      candidateLoginToken: { type: String, select: false },
    },
    {
      timestamps: true,
    }
  );

HiringProcessSchema.index({ candidate: 1 });

export const HiringProcessModel: IHiringProcessModel = (mongoose.models
  ?.HiringProcess ||
  model('HiringProcess', HiringProcessSchema)) as IHiringProcessModel;

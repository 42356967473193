import mongoose, { model, Schema } from 'mongoose';
import { INVITE_STATUSES } from '../invite';
import {
  ICompanySpaceOwnerInvite,
  ICompanySpaceOwnerInviteModel,
} from './company-space-owner-invite.interface';

export const CompanySpaceOwnerInviteSchema: Schema<
  ICompanySpaceOwnerInvite,
  ICompanySpaceOwnerInviteModel
> = new Schema(
  {
    email: { type: String, required: true },
    expiresAt: { type: Date, required: true },
    status: {
      type: String,
      enum: Object.values(INVITE_STATUSES),
      default: INVITE_STATUSES.ACTIVE,
      required: true,
    },
    token: { type: String, required: true },
  },
  { timestamps: true }
);

export const CompanySpaceOwnerInviteModel: ICompanySpaceOwnerInviteModel =
  (mongoose.models?.CompanySpaceOwnerInvite ||
    model(
      'CompanySpaceOwnerInvite',
      CompanySpaceOwnerInviteSchema
    )) as ICompanySpaceOwnerInviteModel;

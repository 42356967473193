import { IHiringProcess, INTERVIEW_STATUSES } from '@er/data-hiring';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  IconButton,
  Box,
  Avatar,
  Snackbar,
  Slide,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
import {
  displayHourAndMinutesInUserTimezone,
  sortInterviews,
} from 'apps/hiring/pages/api/utils/utils';
import moment from 'moment';
import CandidateConfirmMarkAsNoShow from './candidate-confirm-mark-as-no-show';
import { mutate } from 'swr';
import { useRouter } from 'next/router';
import CustomAvatar from '@er/ui-lib/lib/common/shared/custom-avatar';
import { Alert } from '@material-ui/lab';
const labels = require('@er/ui-lib/lib/common/shared/labels.js');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'left',
      width: '100%',
    },
    agendaContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    details: {
      margin: '0 50px',
    },
    textColorAndOpacity: {
      color: '#0D293A',
      opacity: 0.85,
      fontFamily: '"Open Sans", sans-serif',
    },
    root: {
      display: 'inline-flex',
      flexDirection: 'row',

      textTransform: 'capitalize',
      fontWeight: 'bolder',
      [`&.${INTERVIEW_STATUSES.REQUESTED}`]: {
        color: '#ffad33',
      },
      [`&.${INTERVIEW_STATUSES.CANCELED}`]: {
        color: '#FF0000',
      },
      [`&.${INTERVIEW_STATUSES.SCHEDULED}`]: {
        color: '#008000',
      },
      [`&.${INTERVIEW_STATUSES.COMPLETED}`]: {
        color: theme.palette.primary.main,
      },
    },

    customAlert: {
      backgroundColor: '#ECF9F3',
      color: '#00C853',
      elevation: 6,
      boxShadow: theme.shadows[6],
    },
  })
);

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export interface ICandidateInterviewAgendaProps {
  hiringProcess: IHiringProcess;
  openSuccessMessage?: boolean;
  onBack?: () => void;
  onClose?: (event: any, reason?: any) => void;
}

export const icons = {
  [INTERVIEW_STATUSES.REQUESTED]: MailOutlineIcon,
  [INTERVIEW_STATUSES.CANCELED]: CancelIcon,
  [INTERVIEW_STATUSES.SCHEDULED]: EventAvailableIcon,
  [INTERVIEW_STATUSES.COMPLETED]: DoneIcon,
};

export const displayJoinInterview = (interview) => {
  if (!interview || interview.status !== INTERVIEW_STATUSES.SCHEDULED) {
    return;
  }

  const joinLinkEnabled = interview && interview?.meetingLink;

  return (
    <Button
      disabled={!joinLinkEnabled}
      style={{
        color: !joinLinkEnabled ? '' : '#71A7A6',
        display: 'block',
        marginBottom: '12px',
      }}
      component="a"
      href={interview.meetingLink}
      target="_blank"
    >
      <Typography align="center"> Join interview</Typography>
    </Button>
  );
};

export const CandidateInterviewAgenda: FunctionComponent<ICandidateInterviewAgendaProps> =
  ({ hiringProcess, openSuccessMessage, onClose, onBack }) => {
    const classes = useStyles();
    const router = useRouter();

    const [markAsNoShowOpen, setMarkAsNoShowOpen] = useState<Boolean>(false);
    const [selectedInterview, setSelectedInterview] = useState(null);

    const interviewInProgress = (interview) => {
      var currentUTCTime = moment().utc().format();

      return (
        interview &&
        interview.startTime < currentUTCTime &&
        currentUTCTime < interview.endTime
      );
    };

    const markAsNoShowClicked = (interview) => {
      setMarkAsNoShowOpen(true);
      setSelectedInterview(interview);
    };

    const markAsNoShowDisabledForCandidateSchdeduledInterview = (interview) => {
      if (interviewInProgress(interview)) {
        return false;
      }

      return true;
    };

    const displayMarkAsNoShowForCandidate = (interview) => {
      if (!interview || interview.status !== INTERVIEW_STATUSES.SCHEDULED) {
        return;
      }

      const markAsNoShowDisabled =
        markAsNoShowDisabledForCandidateSchdeduledInterview(interview);

      return (
        <Button
          onClick={() => markAsNoShowClicked(interview)}
          style={{
            color: markAsNoShowDisabled ? '' : '#71A7A6',
            display: 'block',
          }}
          disabled={markAsNoShowDisabled}
        >
          <Typography align="center"> Mark-no show</Typography>
        </Button>
      );
    };

    const displayReschedule = (interview) => {
      // It was decided that this button is not that important, so from MVP scope it was commentd out.
      // if (interview?.status === INTERVIEW_STATUSES.REQUESTED) {
      //   return (
      //     <Button
      //       style={{ color: '#FFC74C' }}
      //       onClick={() => rescheduleClicked()}
      //     >
      //       <Typography align="center"> Reschedule</Typography>
      //     </Button>
      //   );
      // }
    };

    const displayFocusAreaNameAndIcon = (interview) => {
      return (
        <Box
          display="flex"
          alignItems="center"
          style={{
            overflowX: 'visible', // Allow overflow in the horizontal direction
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 600,
              whiteSpace: 'nowrap',
            }}
            className={classes.textColorAndOpacity}
          >
            {interview.title}
          </Typography>
          &nbsp;&nbsp;
          <Typography style={{ color: 'grey' }}> &mdash;</Typography>
          {displayStatusIcon(interview)}
        </Box>
      );
    };

    const displayFocusAreaDetails = (interview) => {
      if (interview.status === INTERVIEW_STATUSES.REQUESTED) {
        return (
          <Grid container>
            <Grid item xs={9}>
              <div style={{ marginBottom: '26px', marginTop: '16px' }}>
                {displayFocusAreaNameAndIcon(interview)}
              </div>
              <Typography
                style={{ fontSize: '16px' }}
                className={classes.textColorAndOpacity}
              >
                Please pick a new day/time to reschedule {interview.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignItems="center"
            ></Grid>
          </Grid>
        );
      }

      if (interview.status === INTERVIEW_STATUSES.CANCELED) {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">
                <div style={{ marginBottom: '26px', marginTop: '16px' }}>
                  {displayFocusAreaNameAndIcon(interview)}
                </div>
                <Typography
                  className={classes.textColorAndOpacity}
                  style={{ fontSize: '16px' }}
                >
                  Your interview was canceled. Please await further instructions
                  from {hiringProcess?.job?.company?.name}.
                </Typography>
              </Typography>
            </Grid>{' '}
          </Grid>
        );
      }

      if (
        interview.status != INTERVIEW_STATUSES.CANCELED &&
        interview.status != INTERVIEW_STATUSES.REQUESTED
      ) {
        return (
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="body1">
                <div style={{ marginTop: '16px' }}>
                  <div style={{ marginBottom: '12px' }}>
                    {displayFocusAreaNameAndIcon(interview)}
                  </div>
                  <Typography
                    className={classes.textColorAndOpacity}
                    variant="body1"
                    display="inline"
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Date:{' '}
                  </Typography>
                  <Typography
                    className={classes.textColorAndOpacity}
                    variant="body1"
                    display="inline"
                  >
                    {displayHourAndMinutesInUserTimezone(
                      interview.startTime,
                      hiringProcess.candidate
                    ).format('MMMM DD, YYYY')}
                  </Typography>
                </div>
                <div>
                  <Typography
                    className={classes.textColorAndOpacity}
                    variant="body1"
                    display="inline"
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Time:{' '}
                  </Typography>
                  <Typography
                    className={classes.textColorAndOpacity}
                    variant="body1"
                    display="inline"
                  >
                    {displayHourAndMinutesInUserTimezone(
                      interview.startTime,
                      hiringProcess.candidate
                    ).format('h:mma')}{' '}
                    -{' '}
                    {displayHourAndMinutesInUserTimezone(
                      interview.endTime,
                      hiringProcess.candidate
                    ).format('h:mma')}
                  </Typography>
                </div>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              container
              justifyContent="center"
              alignItems="center"
            >
              <CustomAvatar interview={interview} mustDisplayLinkedInIcon />
            </Grid>
            <Grid
              item
              container
              xs={4}
              alignItems="center"
              justifyContent="flex-end"
            >
              <div
                style={{
                  maxWidth: '300px',
                  textAlign: 'center',
                }}
              >
                {displayJoinInterview(interview)}
                {displayMarkAsNoShowForCandidate(interview)}
              </div>
            </Grid>
          </Grid>
        );
      }
    };

    const displayStatusIcon = (interview) => {
      const Icon: typeof MailOutlineIcon = icons[interview.status];
      return (
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: 600,
          }}
          className={classNames(classes.root, interview.status)}
        >
          &nbsp; &nbsp;
          {!!Icon && <Icon fontSize="small" />}
          &nbsp;
          {interview.status}
        </Typography>
      );
    };

    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    let currentStage = hiringProcess.job.stages.find(
      (stage) => stage._id === hiringProcess.currentStage
    );

    const onMarkAsNoShowClose = () => {
      setMarkAsNoShowOpen(false);
      setSelectedInterview(null);
    };

    const displayInterviewsForHiringProcessStage = () => {
      hiringProcessStage?.interviews.sort(sortInterviews);

      return (
        hiringProcessStage &&
        hiringProcessStage.interviews.map((interview) => (
          <Grid
            key={interview._id}
            item
            style={{
              width: '100%',
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card
              elevation={2}
              style={{
                maxWidth: '1000px',
                width: '100%',
                minHeight: '160px',
                maxHeight: '160px',
                border: '1px solid #71A7A6',
                display: 'flex',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  width: '100%',
                  paddingBottom: '0px',
                  paddingTop: '0px',
                }}
              >
                {displayFocusAreaDetails(interview)}
              </CardContent>
            </Card>
          </Grid>
        ))
      );
    };

    const executeMarkAsNoShow = async () => {
      const result = await fetch(
        `/api/interviews/mark-as-no-show/interviewer/${selectedInterview._id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      ).then((r) => r.json());

      mutate(`/api/hiring-processes/${hiringProcess?._id}`);
      onMarkAsNoShowClose();
    };

    return (
      <div style={{ display: 'flex', marginTop: '8px' }}>
        {markAsNoShowOpen && (
          <CandidateConfirmMarkAsNoShow
            interview={selectedInterview}
            onClose={onMarkAsNoShowClose}
            onConfirmMarkAsNoShow={executeMarkAsNoShow}
          />
        )}
        <div>
          <IconButton
            style={{
              padding: '4px',
            }}
            onClick={onBack}
          >
            <BackIcon />
          </IconButton>
        </div>
        <div
          style={{
            flexGrow: 1,
            marginBottom: '24px',
          }}
        >
          <Card
            className={classes.details}
            style={{
              flex: 1,
              overflow: 'visible',
            }}
            elevation={2}
          >
            <CardContent>
              <div className={classes.agendaContainer}>
                <Typography
                  variant="h4"
                  style={{ marginBottom: '-4px', fontSize: '38px' }}
                  className={classes.textColorAndOpacity}
                >
                  <strong>Agenda</strong>
                </Typography>

                <Snackbar
                  style={{
                    position: 'absolute',
                    right: 0,
                    marginTop: '-70px',
                    marginRight: '-65px',
                    maxWidth: 'calc(50% - 40px)',
                    whiteSpace: 'normal', // Allows the text to wrap
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={openSuccessMessage}
                  onClose={onClose}
                  TransitionComponent={SlideTransition}
                >
                  <Alert
                    icon={false}
                    onClose={onClose}
                    severity="success"
                    className={classes.customAlert}
                  >
                    Your interview is scheduled and a calendar invitation has
                    been sent to your email. You can{' '}
                    <strong> join interview</strong> from the Hirehac agenda or
                    directly from your calendar.
                  </Alert>
                </Snackbar>
              </div>

              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ width: '100%' }}
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    className={classes.textColorAndOpacity}
                  >
                    {currentStage?.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      maxWidth: '1000px',
                      width: '100%',
                      marginTop: '12px',
                      marginBottom: '36px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        marginBottom: '4px',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                      className={classes.textColorAndOpacity}
                    >
                      Event description
                    </Typography>
                    <Typography
                      align="center"
                      style={{
                        fontSize: '16px',
                      }}
                      className={classes.textColorAndOpacity}
                    >
                      {currentStage && currentStage.eventDescription
                        ? currentStage.eventDescription
                        : ''}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <Grid container direction="column" alignItems="center">
                    {displayInterviewsForHiringProcessStage()}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

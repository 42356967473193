import React, { useState } from 'react';

import AsyncSelect from 'react-select/async';

const InterviewerFilter = ({
  onFilterChange,
  selectedInterviewers,
  companyId,
}) => {
  const handleSelectChange = (selectedInterviewers) => {
    onFilterChange(selectedInterviewers);
  };

  const promiseOptions = (inputValue: string) => {
    const url = `/api/users/all-company-users/${companyId}/autocomplete${
      inputValue ? '?searchName=' + inputValue : ''
    }`;

    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        return res.data;
      });
  };

  return (
    <AsyncSelect
      placeholder="Select Interviewers"
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      instanceId="1"
      onChange={handleSelectChange}
      components={{
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null,
      }}
      defaultValue={selectedInterviewers}
    />
  );
};

export default InterviewerFilter;

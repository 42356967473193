import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { USER_TYPES } from '@er/data-hiring';
import { signOut } from 'next-auth/client';
import { useRouter } from 'next/router';
import { ElitRecruiterLogo } from '../elit-recruiter-logo';
import { closedDrawerWidth, drawerWidth } from './base-layout';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closedDrawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    cursor: 'pointer',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    height: '100vh',
  },
  selected: {
    '& svg': { fill: theme.palette.primary.main },
    color: theme.palette.primary.main,
  },
}));

export default function SideBar({
  open,
  onDrawerClose,
  onDrawerOpen,
  companyId,
  user,
}) {
  const classes = useStyles();
  const router = useRouter();

  const buildIconsList = () => {
    let iconsList = [];

    if (
      user &&
      (user.type === USER_TYPES.ADMIN ||
        user.type === USER_TYPES.COMPANYSPACEOWNER)
    ) {
      iconsList = [
        {
          title: 'Jobs',
          icon: WorkIcon,
          href: `/jobs-page/${companyId}`,
        },
        {
          title: 'Interviews',
          icon: PeopleIcon,
          href: '/interviews/my-interviews',
        },
        {
          title: 'My Profile',
          icon: AccountCircleIcon,
          href: `/settings`,
        },
        {
          title: 'Company',
          icon: SettingsIcon,
          href: `/company-settings`,
        },
      ];
    } else {
      iconsList = [
        {
          title: 'Jobs',
          icon: WorkIcon,
          href: `/jobs-page/${companyId}`,
        },
        {
          title: 'Interviews',
          icon: PeopleIcon,
          href: '/interviews/my-interviews',
        },
        {
          title: 'My Profile',
          icon: AccountCircleIcon,
          href: `/settings`,
        },
      ];
    }

    return iconsList;
  };

  const handleLogOutClick = async () => {
    await signOut({ redirect: false });

    router.push('/');
  };

  return (
    <Drawer
      variant="permanent"
      elevation={2}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <ElitRecruiterLogo
          type={open ? 'long' : 'short'}
          light
          onClick={open ? onDrawerClose : onDrawerOpen}
          height={50}
          width="auto"
        />
      </div>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.drawerContainer}
      >
        <Grid item>
          <List>
            {buildIconsList().map((item) => (
              <Link key={item.title} href={item.href} passHref>
                <ListItem
                  key={item.title}
                  button
                  component="a"
                  selected={item.href.startsWith(
                    '/' + router.pathname.split('/')[1]
                  )}
                  classes={{
                    selected: classes.selected,
                  }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: '35px',
                      marginTop: '3px',
                      marginBottom: '3px',
                    }}
                  >
                    <item.icon />
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      style={{ marginLeft: '0px', paddingLeft: '0px' }}
                      primary={
                        <Typography
                          style={{
                            fontSize: '14px',
                            color: item.href.startsWith(
                              '/' + router.pathname.split('/')[1]
                            )
                              ? ''
                              : '0D293A',
                          }}
                        >
                          {item.title}
                        </Typography>
                      }
                    />
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <List>
            {[
              {
                title: 'Logout',
                icon: ExitToAppOutlinedIcon,
              },
            ].map((item) => (
              <ListItem
                button
                component="a"
                onClick={() => handleLogOutClick()}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText secondary={item.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}

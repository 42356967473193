import {
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import React, { FunctionComponent } from 'react';
import { IUploadedFile } from './file-uploader.interface';
import classNames from 'classnames';
import { FileUploadStatus } from './file-uploader.constants';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },

    item: {
      '&.error': {
        color: theme.palette.error.main,
      },
      '&.complete': {
        color: theme.palette.success.main,
        '& svg': {
          color: theme.palette.success.main,
        },
      },
      '&.pending': {
        color: theme.palette.warning.main,
        '& svg': {
          color: theme.palette.warning.main,
        },
      },
    },
  })
);

export interface IFileListItemProps {
  file: IUploadedFile;
  onDelete?: (file: IUploadedFile) => any;
}

const ErrorListItem = ({ file, onDelete }: IFileListItemProps) => {
  const classes = useStyles();
  const handleDelete = () => {
    if (onDelete) {
      onDelete(file);
    }
  };
  return (
    <ListItem className={classNames(classes.item, 'error')}>
      <ListItemIcon>
        <ErrorIcon color="error" />
      </ListItemIcon>
      <ListItemText primary={`${file.name}`} secondary="File upload failed" />
      <ListItemSecondaryAction>
        {onDelete && (
          <IconButton edge="end" onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
const CompleteListItem = ({ file, onDelete }: IFileListItemProps) => {
  const classes = useStyles();
  const handleDelete = () => {
    if (onDelete) {
      onDelete(file);
    }
  };
  return (
    <ListItem className={classNames(classes.item, 'complete')}>
      <ListItemIcon>
        <AttachFileIcon />
      </ListItemIcon>
      <ListItemText primary={`${file.name}`} />
      <ListItemSecondaryAction>
        {onDelete && (
          <IconButton edge="end" onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const InProgressListItem = ({ file, onDelete }: IFileListItemProps) => {
  const classes = useStyles();
  const handleDelete = () => {
    if (onDelete) {
      onDelete(file);
    }
  };
  return (
    <ListItem
      className={classNames(classes.item, 'uploading')}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ListItemIcon>
        <AttachFileIcon />
      </ListItemIcon>

      <Typography variant="body1" color="textPrimary">
        {file.name}
      </Typography>
      {onDelete && (
        <IconButton edge="end" onClick={handleDelete}>
          <DeleteIcon color="error" />
        </IconButton>
      )}
    </ListItem>
  );
};

export interface IFileListProps {
  files: IUploadedFile[];
  onDelete?: (file: IUploadedFile) => any;
}

export const FileList: FunctionComponent<IFileListProps> = ({
  files,
  onDelete,
}) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {files.map((file, idx) => {
        const UploadListItem =
          {
            [FileUploadStatus.COMPLETE]: CompleteListItem,
            [FileUploadStatus.IN_PROGRESS]: InProgressListItem,
            [FileUploadStatus.ERROR]: ErrorListItem,
          }[file.uploadStatus] || null;
        return <UploadListItem file={file} key={idx} onDelete={onDelete} />;
      })}
    </List>
  );
};

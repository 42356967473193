import { IInterview, IInterviewSegment, IUser } from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { displayHourAndMinutesInUserTimezone } from 'apps/hiring/pages/api/utils/utils';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';

export interface IAdminAgendaEditorConfirmCancelInterviewProps {
  interview: IInterview;
  onClose: () => any;
  refreshAgendaEditorData: () => any;
  interviewSegment: IInterviewSegment;
  user: IUser;
}

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
  titleColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

export const AdminAgendaEditorConfirmCancelInterview: FunctionComponent<IAdminAgendaEditorConfirmCancelInterviewProps> =
  ({ interview, interviewSegment, user, onClose, refreshAgendaEditorData }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const executeCancelInterview = async () => {
      setLoading(true);
      try {
        await fetch(`/api/interviews/cancel-interview/${interview._id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
          .then(() => {})
          .then();

        refreshAgendaEditorData();
        onClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Dialog
        // open={!!addStageOpen}
        open={true}
        // onClose={onClose}
        onClose={() => console.log('On close')}
        disableBackdropClick
        maxWidth="sm"
      >
        <DialogTitle>
          {loading && <CustomCircularLoading />}
          <Box textAlign="center">
            <Typography variant="h5" className={classes.titleColorAndOpacity}>
              <b>Cancel interview</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 600,
                marginBottom: '-8px',
              }}
              className={classes.textColorAndOpacity}
            >
              {interview?.title
                ? interview?.title
                : interviewSegment?.focusArea}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.textColorAndOpacity}
            >
              {interview?.candidate?.firstName +
                ' ' +
                interview?.candidate?.lastName}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography
              style={{ fontSize: '15px' }}
              className={classes.textColorAndOpacity}
              display="inline"
            >
              After confirming, the event scheduled on{' '}
            </Typography>
            <Typography
              style={{ fontSize: '15px', fontWeight: 600 }}
              className={classes.textColorAndOpacity}
              display="inline"
            >
              {displayHourAndMinutesInUserTimezone(
                interview?.startTime,
                user
              ).format('MMMM-DD-YYYY')}{' '}
            </Typography>
            <Typography
              style={{ fontSize: '15px' }}
              className={classes.textColorAndOpacity}
              display="inline"
            >
              at{' '}
            </Typography>
            <Typography
              style={{ fontSize: '15px', fontWeight: 600 }}
              className={classes.textColorAndOpacity}
              display="inline"
            >
              {displayHourAndMinutesInUserTimezone(
                interview?.startTime,
                user
              ).format('h:mm a')}{' '}
            </Typography>

            <Typography
              style={{ fontSize: '15px' }}
              className={classes.textColorAndOpacity}
              display="inline"
            >
              will be canceled.
            </Typography>
            <br />
            <Button
              onClick={() => executeCancelInterview()}
              style={{
                color: '#FFFFFF',
                backgroundColor: '#FA595F',
                opacity: 0.85,
                marginTop: '25px',
                marginBottom: '25px',
              }}
              variant="contained"
              disabled={loading}
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default AdminAgendaEditorConfirmCancelInterview;

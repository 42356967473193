import React, { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash';
import PropTypes, { InferProps } from 'prop-types';
import {
  Card,
  Grid,
  CardContent,
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ElitRecruiterLogo from '../../common/elit-recruiter-logo/elit-recruiter-logo';

/* eslint-disable-next-line */
export type CentralCardLayoutProps = {
  preContentElement?: ReactNode;
  contentLogo?: boolean;
  alignContentLogo?: 'left' | 'right' | 'center';
  width?: number;
  height?: number;
  preContentElementHeight?: number;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  children: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  content: {
    height: '100%',
    overflowY: 'scroll',
  },
}));

export const CentralCardLayout: FunctionComponent<CentralCardLayoutProps> = (
  props
) => {
  const { preContentElement, contentLogo, children } = props;

  const classes = useStyles();
  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.content}
          spacing={4}
          wrap="nowrap"
          style={{ width: '100%' }}
        >
          <Grid item>
            <Card elevation={8}>
              <CardContent>
                <Typography component="h1" variant="h5" align="center">
                  <ElitRecruiterLogo />
                </Typography>
                <div className={classes.children}>{children}</div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

CentralCardLayout.propTypes = {
  preContentElement: PropTypes.node,
  contentLogo: PropTypes.bool,
  alignContentLogo: PropTypes.oneOf(['left', 'right', 'center']),
  width: PropTypes.number,
  height: PropTypes.number,
  preContentElementHeight: PropTypes.number,
};

CentralCardLayout.defaultProps = {
  width: 382,
  contentLogo: false,
  preContentElementHeight: 0,
  alignContentLogo: 'center',
};

export default CentralCardLayout;

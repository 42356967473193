import { IInterview } from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';

export interface IAdminAgendaEditorSendReminderProps {
  currentInterview: IInterview;
  hiringProcessId: any;
  refreshData: () => any;
  focusArea: string;
}

export const SendReminder: FunctionComponent<IAdminAgendaEditorSendReminderProps> =
  ({ currentInterview, refreshData, hiringProcessId, focusArea }) => {
    const [loading, setLoading] = useState(false);

    const sendRemainder = async () => {
      setLoading(true);
      try {
        await fetch(`/api/hiring-processes/send-reminder/${hiringProcessId}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            focusArea: focusArea,
            interviewId: currentInterview._id,
          }),
          method: 'POST',
        })
          .then(() => {})
          .then();

        refreshData();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const buildSendReminderButton = () => {
      const currentTime = moment.utc(new Date());

      if (!currentInterview.sentReminderAt) {
        return (
          <div>
            {loading && <CustomCircularLoading />}
            <Button
              onClick={() => sendRemainder()}
              style={{ color: '#ffad33', width: '100%' }}
              disabled={false}
            >
              Send Reminder
            </Button>
          </div>
        );
      }

      const hoursPassedSinceReminderSent = currentTime.diff(
        moment.utc(currentInterview.sentReminderAt),
        'hours'
      );

      if (hoursPassedSinceReminderSent >= 24) {
        return (
          <div>
            {loading && <CustomCircularLoading />}
            <Button
              onClick={() => sendRemainder()}
              style={{ color: '#ffad33', width: '100%' }}
              disabled={false}
            >
              Send Reminder
            </Button>
          </div>
        );
      } else {
        return (
          <div>
            {loading && <CustomCircularLoading />}
            <Button
              onClick={() => sendRemainder()}
              style={{ width: '100%' }}
              disabled={true}
            >
              Send Reminder
            </Button>
          </div>
        );
      }
    };

    return buildSendReminderButton();
  };

export default SendReminder;

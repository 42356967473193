import { STAGE_INTERVIEWER_ROTATION } from '@er/data-hiring';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

export interface IShareLinkProps {
  handleChange?: (rotation: STAGE_INTERVIEWER_ROTATION) => void;
  interviewerRotation: STAGE_INTERVIEWER_ROTATION;
}

export const InterviewerRotation: FunctionComponent<IShareLinkProps> = ({
  interviewerRotation,
  handleChange,
}) => {
  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        Interviewer rotation
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: '#71A7A6' }}
            checked={
              interviewerRotation ===
              STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION
            }
            onChange={() => {
              handleChange(STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION);
            }}
            name={STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION}
          />
        }
        label={STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION}
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: '#71A7A6' }}
            checked={
              interviewerRotation ===
              STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY
            }
            onChange={() => {
              handleChange(STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY);
            }}
            name={STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY}
          />
        }
        label={STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY}
      />
    </div>
  );
};

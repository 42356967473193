import {
  Grid,
  makeStyles,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Box,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Controller, useForm } from 'react-hook-form';
import { EventSettingsForm } from 'apps/hiring/components';
import { IInterviewer, INTERVIEW_TYPES } from '@er/data-hiring';
import TimeSegmentPicker from './availability-segment-editor';
import { TimeRangePicker } from '../time-range-picker';
import { ITimeRange } from './availability-editor-slot';

export interface ITimeRangeSlot {
  startTime: string;
  endTime: string;
  id?: any;
  dayOfWeek: string;
  onDelete?: (dayOfWeek: string, id: number) => any;
  onChange?: (
    dayOfWeek: string,
    id: number,
    startTime: string,
    endTime: string
  ) => any;
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 700,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  timePicker: {
    lineHeight: '35px',
    marginLeft: 10,
  },
}));

export const TimeRangeSlot: FunctionComponent<ITimeRangeSlot> = ({
  dayOfWeek,
  id,
  startTime,
  endTime,
  onChange,
  onDelete,
}) => {
  const handleTimeRangeChange = (timeRange: ITimeRange) => {
    if (!onChange) return;
    onChange(dayOfWeek, id, timeRange.startTime, timeRange.endTime);
  };

  const handleDeleteClick = (evt) => {
    evt.preventDefault();
    if (onDelete) {
      return onDelete(dayOfWeek, id);
    }
  };

  const classes = useStyles();
  return (
    <div>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <TimeRangePicker
          startTime={startTime}
          endTime={endTime}
          className={classes.timePicker}
          onChange={handleTimeRangeChange}
        />
        <IconButton onClick={handleDeleteClick} style={{ padding: '4px' }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </div>
  );
};

import { IHiringProcess } from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#000000',
    opacity: 0.55,
  },
  titleColorAndOpacity: {
    color: '#000000',
    opacity: 0.65,
  },
}));

export interface ICandidateConfirmAdvanceWithInterviewRequestedOrCanceledProps {
  hiringProcess: IHiringProcess;
  sourceStage: any;
  destinationStage: any;
  advanceCandidatePosition: number;

  onClose: () => any;
  onSubmit: (
    hiringProcess: IHiringProcess,
    sourceStage: any,
    destinationStage: any,
    advanceCandidatePosition: number
  ) => any;
  loading: boolean;
}

export const CandidateConfirmAdvanceWithInterviewRequestedOrCanceled: FunctionComponent<ICandidateConfirmAdvanceWithInterviewRequestedOrCanceledProps> =
  ({
    hiringProcess,
    sourceStage,
    destinationStage,
    advanceCandidatePosition,
    onClose,
    onSubmit,
    loading,
  }) => {
    const classes = useStyles();

    return (
      <Dialog open={true} disableBackdropClick maxWidth="sm">
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.titleColorAndOpacity}>
              <b>Confirm Advance</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="body1" className={classes.textColorAndOpacity}>
              You are attempting to move a candidate from {sourceStage?.title}{' '}
              to {destinationStage?.title}. After confirming, interviews with a
              'Requested' or 'Canceled' status associated with{' '}
              {sourceStage?.title} will be deleted.
            </Typography>
            <Button
              onClick={() =>
                onSubmit(
                  hiringProcess,
                  sourceStage,
                  destinationStage,
                  advanceCandidatePosition
                )
              }
              color="primary"
              style={{
                marginTop: '25px',
                marginBottom: '15px',
              }}
              variant="contained"
              disabled={loading}
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default CandidateConfirmAdvanceWithInterviewRequestedOrCanceled;

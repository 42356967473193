import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import useSWR, { mutate } from 'swr';

export interface IAdminAgendaEditorConfirmCancelInterviewProps {
  onClose: () => any;
  onConfirm: () => any;
}

export const DeleteCompanyUserConfirmation: FunctionComponent<IAdminAgendaEditorConfirmCancelInterviewProps> =
  ({ onClose, onConfirm }) => {
    return (
      <Dialog
        // open={!!addStageOpen}
        open={true}
        // onClose={onClose}
        onClose={() => console.log('On close')}
        disableBackdropClick
        maxWidth="md"
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5">
              <b>Delete user?</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="body1">
              Are you sure you want to delete the account for this user?
            </Typography>
            <br />
            <Button
              onClick={() => onConfirm()}
              style={{
                color: '#FFFFFF',
                backgroundColor: '#FF0000',
              }}
              variant="contained"
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default DeleteCompanyUserConfirmation;

export enum FREE_BUSY_STATUSES {
  FREE = 'free',
  BUSY = 'busy',
}

export enum CALENDAR_EVENT_STATUSES {
  CONFIRMED = 'confirmed',
  TENTATIVE = 'tentative',
  CANCELED = 'canceled',
}

export enum WEEK_DAYS {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum CALENDAR_PROVIDER {
  MANUAL,
  NYLAS,
}

import { ICalendar, INTERVIEW_TYPES, IOpenHoursSlot } from '@er/data-hiring';
import { AvailabilityEditor } from '@er/ui-lib';
import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface IAddStageProps {
  onClose?: () => void;
  onSubmit?: (data: AddStageFormData) => void;
  addStageOpen: Boolean;
}

export type AddStageFormData = {
  title: string;
  interviewType: INTERVIEW_TYPES;
};

const defaultValues: AddStageFormData = {
  title: '',
  interviewType: INTERVIEW_TYPES.ON_ON_ONE,
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 700,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export const AddStage: FunctionComponent<IAddStageProps> = ({
  addStageOpen,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const { handleSubmit, formState, control, errors, reset } =
    useForm<AddStageFormData>({
      defaultValues,
    });

  const handleFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data);
  });

  const handleDialogClose = () => {
    reset();
    if (onClose) {
      onClose();
    }
  };

  const labelRef = useRef();
  const labelWidth =
    labelRef && labelRef.current ? (labelRef.current as any).clientWidth : 110;

  return (
    <Dialog
      open={!!addStageOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="md"
    >
      <DialogTitle>Add a Stage</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          style={{ gap: 10 }}
        >
          Name the Stage
          <Controller
            control={control}
            name="title"
            rules={{
              required: 'Stage name is required',
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Stage name"
                type="text"
                inputRef={ref}
                value={value}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof AddStageFormData}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          Select stage type
          <Controller
            control={control}
            name="interviewType"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl variant="outlined" error={invalid}>
                <InputLabel
                  ref={labelRef}
                  shrink
                  htmlFor="interview_form_interview_type"
                >
                  Stage Type
                </InputLabel>
                <Select
                  onBlur={onBlur}
                  id="interview_form_interview_type"
                  inputRef={ref}
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                >
                  {Object.values(INTERVIEW_TYPES)
                    .filter(
                      (type) =>
                        type !== INTERVIEW_TYPES.REVIEW &&
                        type !== INTERVIEW_TYPES.OFFER
                    )
                    .map((type) => (
                      <MenuItem key={type} value={type}>
                        {capitalize(type)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

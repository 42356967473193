import {
  Grid,
  makeStyles,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Box,
  FormHelperText,
  OutlinedInput,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Controller, useForm } from 'react-hook-form';
import { EventSettingsForm } from 'apps/hiring/components';
import { IInterviewer, INTERVIEW_TYPES } from '@er/data-hiring';
import { ErrorMessage } from '@hookform/error-message';
import { ObjectId } from 'mongoose';

export interface IInterviewSegmentSlotProps {
  id?: any;
  durationMinutes: number;
  focusArea: string;
  interviewers: string[];
  allInterviewers?: IInterviewer[];
  control: any;
  formState: any;
  onChange?: (
    id: any,
    focusArea: string,
    durationMinutes: number,
    interviewers: string[]
  ) => any;

  onInterviewSegmentInterviewerChange?: (
    id: ObjectId | any,
    interviewers: string[]
  ) => any;

  onInterviewSegmentDurationChange?: (
    id: ObjectId | any,
    durationMinutes: number
  ) => any;

  onInterviewSegmentFocusAreaChange?: (
    id: ObjectId | any,
    focusArea: string
  ) => any;

  onInterviewSegmentInterviewerRemove?: (
    id: ObjectId | any,
    interviewerToRemove: ObjectId | any
  ) => any;

  onAddInterviewSegment?: () => any;

  onDeleteInterviewSegment?: (id: ObjectId | any) => any;
}

export const InterviewSegmentSlot: FunctionComponent<IInterviewSegmentSlotProps> =
  ({
    id,
    interviewers,
    allInterviewers,
    focusArea,
    durationMinutes,
    onDeleteInterviewSegment,
    onInterviewSegmentInterviewerChange,
    onInterviewSegmentDurationChange,
    onInterviewSegmentFocusAreaChange,
    onInterviewSegmentInterviewerRemove,
    control,
    formState,
  }) => {
    const [selectedInterviewersIds, setSelectedInterviewersIds] =
      useState(interviewers);

    const onChangeFocusArea = (value) => {
      onInterviewSegmentFocusAreaChange(id, value);
    };

    const updateDuration = (duration) => {
      onInterviewSegmentDurationChange(id, duration);
    };

    const updateInterviewers = (newInterviewersList) => {
      onInterviewSegmentInterviewerChange(id, newInterviewersList);
    };

    const handleInterviewSegmentDelete = () => {
      onDeleteInterviewSegment(id);
    };

    const handleRemoveInterviewer = (interviewerToRemove) => {
      onInterviewSegmentInterviewerRemove(id, interviewerToRemove);
    };

    return (
      <Grid container direction="row">
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={8}>
            <Controller
              control={control}
              name={'focusArea' + id}
              defaultValue={focusArea}
              rules={{
                required: 'Focus Area is required',
              }}
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  fullWidth
                  onBlur={onBlur}
                  variant="outlined"
                  label="Focus Area"
                  type="text"
                  inputRef={ref}
                  value={focusArea}
                  error={invalid}
                  helperText={
                    <ErrorMessage
                      errors={formState.errors}
                      name={name as keyof IInterviewSegmentSlotProps}
                    />
                  }
                  onChange={(e) => {
                    onChangeFocusArea(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            alignItems="center"
            style={{ gap: '8px' }}
          >
            <Controller
              control={control}
              name={'durationMinutes' + id}
              defaultValue={60}
              rules={{
                required: 'Duration minutes must be set',
                validate: (value) => {
                  if (value < 15) {
                    return 'Duration must be at least 15 minutes';
                  }
                },
              }}
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  style={{ flex: 1 }}
                  fullWidth
                  onBlur={onBlur}
                  variant="outlined"
                  label="DurationMinutes"
                  name="durationMinutes"
                  type="number"
                  inputRef={ref}
                  value={durationMinutes}
                  error={invalid}
                  helperText={
                    <ErrorMessage
                      errors={formState.errors}
                      name={name as keyof IInterviewSegmentSlotProps}
                    />
                  }
                  onChange={(e) => {
                    updateDuration(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <IconButton onClick={handleInterviewSegmentDelete}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="interviewers"
            defaultValue={interviewers}
            rules={{
              validate: (value) => {
                if (interviewers.length <= 0) {
                  return 'At least one interviewer must be selected';
                }
              },
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl
                variant="outlined"
                error={invalid}
                fullWidth
                style={{ marginTop: '8px' }}
              >
                <InputLabel id="event_settings_form_interviewers">
                  Interviewers
                </InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null, // This line is essential to make it work
                  }}
                  onBlur={onBlur}
                  labelId="event_settings_form_interviewers"
                  inputRef={ref}
                  value={interviewers}
                  multiple
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Interviewers"
                    />
                  }
                  renderValue={(selected: string[]) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => {
                        const interviewer: IInterviewer = allInterviewers.find(
                          (i) => i._id === value
                        );

                        if (!interviewer) {
                          return null;
                        }

                        return (
                          <Chip
                            style={{
                              marginBottom: '2px',
                              backgroundColor: interviewer.calendarSynced
                                ? 'rgba(209, 226, 226, 0.35)'
                                : 'red',
                            }}
                            label={`${interviewer?.lastName} ${interviewer?.firstName}`}
                            key={value}
                            onDelete={() => {
                              handleRemoveInterviewer(interviewer);
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                  onChange={(e) => {
                    updateInterviewers(e.target.value);
                    onChange(e.target.value);
                    setSelectedInterviewersIds(e.target.value as string[]);
                  }}
                >
                  {allInterviewers
                    ?.filter((interviewer) => interviewer.calendarSynced)
                    .map((interviewer) => (
                      <MenuItem key={interviewer._id} value={interviewer._id}>
                        {interviewer.lastName} {interviewer.firstName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {invalid ? 'At least one interviewer must be selected' : ''}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    );
  };

import React, { FunctionComponent, Ref } from 'react';
import classNames from 'classnames';
import { makeStyles, Theme, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    border: `1px dashed ${theme.palette.primary.main}`,
    textAlign: 'center',
    borderRadius: 10,
    padding: 5,
    height: '100%',
    minHeight: 200,
    minWidth: 300,
    '&.accepting': {
      border: `1px dashed ${theme.palette.primary.main}`,
    },
    '&.disabled': {
      border: `1px dashed ${theme.palette.text.disabled}`,
    },
    '&.rejecting': {
      border: `1px dashed ${theme.palette.error.main}`,
    },
    '& .dropzone-content': {
      height: '100%',
    },
  },
}));

export interface IFileDropzoneContainerProps {
  active?: boolean;
  accepting?: boolean;
  rejecting?: boolean;
  disabled?: boolean;
  containerProps?: any;
}

export const FileDropzoneContainer: FunctionComponent<IFileDropzoneContainerProps> =
  ({
    containerProps = {},
    children,
    active = false,
    rejecting = false,
    accepting = false,
    disabled = false,
  }) => {
    const classes = useStyles();
    return (
      <Paper
        {...containerProps}
        className={classNames(classes.dropzone, {
          accepting,
          rejecting,
          active,
          disabled,
        })}
        elevation={0}
      >
        {children}
      </Paper>
    );
  };

import React, {
  FunctionComponent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {
  listTimeZones,
  ITimeZone,
  getCurrentTimeZone,
  timeZoneFromName,
} from '@er/util-time';
import PropTypes, { InferProps } from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { CSSProperties } from '@material-ui/styles';

const timezones: ITimeZone[] = listTimeZones();
const currentTimezoneName = getCurrentTimeZone();

export type SelectTimezoneProps = {
  defaultValue?: string;
  useCurrent?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  small?: boolean;
  current?: any;
  onChange?: (string) => void;
  onBlur?: (SyntheticEvent) => void;
  helperText?: string | ReactElement;
  label?: string;
  inputRef?: any;
  variant?: 'filled' | 'outlined' | 'standard';
  style?: CSSProperties;
  value?: 'string';
};

export const SelectTimezone: FunctionComponent<SelectTimezoneProps> = ({
  defaultValue,
  fullWidth,
  onChange,
  error,
  helperText,
  useCurrent,
  label,
  current,
  inputRef,
  style,
  variant,
  value,
  onBlur,
  small,
}: SelectTimezoneProps) => {
  let initialValue: ITimeZone | string = defaultValue;

  if (!initialValue && current) {
    initialValue = currentTimezoneName;
  }
  if (initialValue) {
    initialValue = timeZoneFromName(initialValue);
  }

  // eslint-disable-next-line prefer-const
  let [timezoneValue, setValue] = useState(initialValue);

  if (value) {
    timezoneValue = timeZoneFromName(value);
  }

  if (!value && typeof value === 'string') {
    timezoneValue = null;
  }

  const handleChange = (evt, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue ? newValue.value : null);
    }
  };

  useEffect(() => {
    if (
      !defaultValue &&
      initialValue &&
      onChange &&
      typeof initialValue !== 'string'
    ) {
      setTimeout(() => onChange((initialValue as ITimeZone).value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={timezones}
      getOptionLabel={(option) => option.label}
      fullWidth={fullWidth}
      getOptionSelected={(option, value) => option.value === value.value}
      value={timezoneValue}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          variant={variant}
          inputRef={inputRef}
          onBlur={onBlur}
          style={style}
          size={small ? 'small' : 'medium'}
        />
      )}
    />
  );
};

SelectTimezone.defaultProps = {
  fullWidth: false,
  error: false,
  useCurrent: false,
  variant: 'outlined',
};

export default SelectTimezone;

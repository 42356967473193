import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { userHasCalendarSynced, SetAvailabilityPage } from '../../users';
import { ITimeRange } from '../availability-editor';
import { IUser } from '@er/data-hiring';
import CloseIcon from '@material-ui/icons/Close';
import { getCurrentTimeZone } from '@er/util-time';
import CustomCircularLoading from './custom-circular-loading';
import { capitalizeFirstLetter } from 'apps/hiring/pages/jobs/[id]';
import SnackBarErrorLayout from './snackbar-error-layout';
import SnackBarLayout from './snackbar-layout';
const labels = require('@er/ui-lib/lib/common/shared/labels.js');

interface IMyAvailabilityCompanyUserProps {
  onClose: () => any;
  user: IUser;
}

const useStyles = makeStyles((theme) => ({
  customWidth: {
    height: '90vh',
    width: '780px',
    maxWidth: '780px', // This ensures that the dialog doesn't grow beyond 780px
  },
}));

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export const MyAvailabilityCompanyUser: FunctionComponent<IMyAvailabilityCompanyUserProps> =
  ({ user, onClose }) => {
    const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

    const classes = useStyles();

    useEffect(() => {
      handleViewAvailabilityClick();

      let timeoutId = null;

      if (user && user?.lastReadMyAvailabilitySchedulingOptions) {
        let clickTimePlusOneMinute = new Date(
          user?.lastReadMyAvailabilitySchedulingOptions
        );
        clickTimePlusOneMinute.setTime(
          clickTimePlusOneMinute.getTime() + 1 * 60 * 1000
        );

        const currentTime = new Date();
        if (currentTime < clickTimePlusOneMinute) {
          setRefreshButtonDisabled(true);

          const timeDifference =
            clickTimePlusOneMinute.getTime() - currentTime.getTime();

          timeoutId = setTimeout(() => {
            setRefreshButtonDisabled(false);
          }, timeDifference);
        }
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, []);

    const userResponse = useSWR('/api/users/' + user._id, fetchFetcher, {
      fallbackData: user && { data: user },
    })?.data?.data;

    const [availabilityMap, setAvailabilityMap] =
      useState<Map<string, ITimeRange[]>>(null);

    async function createDefaultAvailability() {
      const defaultTimezone = user?.timeZone
        ? user?.timeZone
        : getCurrentTimeZone();

      await fetch(
        `${origin}/api/cronofy/availability-rule/${user._id}/create-default`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'PATCH',
          body: JSON.stringify({
            selectedTimeZone: defaultTimezone,
          }),
        }
      )
        .then((r) => {
          if (!r.ok) {
            console.log('Something bad happened');
          }

          return r.json();
        })
        .then((serverResult) => {
          console.log('Result of the Availability update call');
          console.log(serverResult);
        });

      mutate('/api/users/' + user._id);
    }

    async function handleViewAvailabilityClick() {
      if (userHasCalendarSynced(user)) {
        setLoading(true);

        try {
          if (!user?.availabilitySet) {
            await createDefaultAvailability();
          }

          await fetch(`${origin}/api/cronofy/availability-rule/${user._id}`, {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'GET',
          })
            .then((r) => {
              return r.json();
            })
            .then((serverResult) => {
              const result = serverResult?.data;

              const newAvailabilityMap = new Map<string, ITimeRange[]>(
                JSON.parse(result)
              );

              setAvailabilityMap(newAvailabilityMap);
            });
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }

    const handleAvailabilitySave = async (availabilityMap, timeZone) => {
      setLoading(true);
      try {
        const result = await fetch(
          `/api/cronofy/availability-rule/${user._id}`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              availability: Array.from(availabilityMap.entries()),
              selectedTimeZone: timeZone,
            }),
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((r) => {
            if (r?.success) {
              setOpenSuccessSnackbar(true);
              setOpenSnackbar(false);
            } else {
              setOpenSnackbar(true);
              setOpenSuccessSnackbar(false);
              setErrorMessage(
                capitalizeFirstLetter(r?.errorMessage) ||
                  'Change could not be saved.'
              );
            }

            mutate('/api/users/' + user._id);
            return r;
          });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    async function onRefreshClick() {
      const result = await fetch(
        `/api/cronofy/availability-rule/${user._id}/refresh`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          mutate('/api/users/' + user._id);
          return r;
        });

      setRefreshButtonDisabled(true);
      setTimeout(() => {
        setRefreshButtonDisabled(false);
      }, 60000); // Enable the button after 1 minute (60,000 milliseconds)
    }

    const handleSlotChange = (
      dayOfWeek: string,
      id: number,
      startTime: string,
      endTime: string
    ) => {
      let newAvailabilityMap = new Map(availabilityMap);
      const dayOfWeekTimeSegments = newAvailabilityMap.get(dayOfWeek);
      dayOfWeekTimeSegments[id] = { startTime, endTime };

      setAvailabilityMap(newAvailabilityMap);
    };

    const handleAddSlot = (dayOfWeek: string) => {
      let newAvailabilityMap = new Map(availabilityMap);
      const dayOfWeekTimeSegments = newAvailabilityMap.get(dayOfWeek);
      if (dayOfWeekTimeSegments) {
        const newTimeSegments = [
          ...dayOfWeekTimeSegments,
          { startTime: '09:00', endTime: '17:00' },
        ];
        newAvailabilityMap.set(dayOfWeek, newTimeSegments);
      } else {
        newAvailabilityMap.set(dayOfWeek, [
          { startTime: '09:00', endTime: '17:00' },
        ]);
      }

      setAvailabilityMap(newAvailabilityMap);
    };

    const handleSlotDelete = (dayOfWeek: string, id: number) => {
      let newAvailabilityMap = new Map(availabilityMap);

      const dayOfWeekTimeSegments = newAvailabilityMap.get(dayOfWeek);
      newAvailabilityMap.set(
        dayOfWeek,
        dayOfWeekTimeSegments.filter((_, index) => index !== id)
      );

      setAvailabilityMap(newAvailabilityMap);
    };

    const handleClose = (
      event: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpenSnackbar(false);
    };

    const handleSuccessClose = (
      event: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpenSuccessSnackbar(false);
    };

    return (
      <Dialog
        // open={!!addStageOpen}
        open={true}
        // onClose={onClose}
        onClose={() => console.log('On close')}
        disableBackdropClick
        fullWidth
        classes={{
          paper: classes.customWidth,
        }}
      >
        <DialogTitle>
          <Box textAlign="center">
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <SnackBarErrorLayout
            open={openSnackbar}
            handleClose={handleClose}
            errorMessage={errorMessage}
          ></SnackBarErrorLayout>
          <SnackBarLayout
            open={openSuccessSnackbar}
            handleClose={handleSuccessClose}
            message={labels.SUCCESS_IN_APP_MESSAGE}
          ></SnackBarLayout>
          {loading && <CustomCircularLoading />}
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <SetAvailabilityPage
                user={userResponse}
                onSubmit={handleAvailabilitySave}
                longestInterviewDuration={
                  userResponse?.cachedLongestInterviewDuration
                }
                schedulingOptionsCount={
                  userResponse?.cachedSchedulingOptionsCount
                }
                onRefreshClick={onRefreshClick}
                refreshButtonDisabled={refreshButtonDisabled}
                availabilityMap={availabilityMap}
                handleAddSlot={handleAddSlot}
                handleSlotChange={handleSlotChange}
                handleSlotDelete={handleSlotDelete}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

import {
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { JobPageTabs, JOB_PAGE_TABS } from './job-page-tabs';
import ShareIcon from '@material-ui/icons/Share';
import { ShareLink } from './share-link';
import {
  JobArchiveConfirm,
  JobReactivateConfirm,
} from 'apps/hiring/components';
import { JOB_STATUSES } from '@er/data-hiring';
import { mutate } from 'swr';
import SnackBarErrorLayout from '../shared/snackbar-error-layout';
const labels = require('@er/ui-lib/lib/common/shared/labels.js');

export const canCreateInvite = async (job): Promise<[boolean, string]> => {
  const anyStageFullyConfiguredResult = await canSendInvite(job);

  if (!anyStageFullyConfiguredResult) {
    return [false, labels.JobNoFirstStageNotification];
  }

  return [true, ''];
};

const canSendInvite = async (job) => {
  const canSendInvite = await fetch(
    `${origin}/api/invites/job/${job._id}/can-send-invite`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  )
    .then((r) => r.json())
    .then((r) => {
      return r.data;
    });

  return canSendInvite;
};

export function ShowJob({ job, selectedTab = JOB_PAGE_TABS.BOARD }) {
  const [shareLinkOpen, setShareLinkOpen] = useState<Boolean>(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmReactivateJobOpen, setConfirmReactivateJobOpen] =
    useState<Boolean>(false);

  const onClickShareButton = async () => {
    let [canCreateInviteResult, errorMessage] = await canCreateInvite(job);

    if (!canCreateInviteResult) {
      setOpenSnackbar(true);
      setErrorMessage(errorMessage);
      return;
    }

    setShareLinkOpen(true);
  };

  const handleShareLinkClose = () => {
    setShareLinkOpen(false);
  };

  const [archiveJobOpen, setArchiveJobOpen] = React.useState(false);

  async function archiveJobClick() {
    await fetch(`${origin}/api/jobs/${job._id}/can-archive-job`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then((r) => r.json())
      .then((r) => {
        if (r?.status === 'Error') {
          setOpenSnackbar(true);
          setErrorMessage(r.errorMessage);
        } else {
          setArchiveJobOpen(true);
        }
      });
  }

  const handleArchiveJobSubmit = async (job) => {
    await fetch(`/api/jobs/${job._id}/archive`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }).then(() => {
      mutate(`/api/jobs/${job._id}`);
    });

    handleJobArchiveClose();
  };

  const handleReactivateJobSubmit = async (job) => {
    await fetch(`/api/jobs/${job._id}/reactivate`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }).then(() => {
      mutate(`/api/jobs/${job._id}`);
      mutate(`/api/jobs/${job._id}/stages-sorted-by-order`);
    });

    handleJobReactivateClose();
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleJobArchiveClose = () => {
    setArchiveJobOpen(false);
  };

  const handleJobReactivateClose = () => {
    setConfirmReactivateJobOpen(false);
  };

  const reactivateJobClick = () => {
    setConfirmReactivateJobOpen(true);
  };

  function displayJobActionButton() {
    if (job && job?.status === JOB_STATUSES.ACTIVE) {
      return (
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={() => archiveJobClick()}
            style={{
              borderWidth: '1px',
              borderStyle: 'solid',
              color: '#FF7262',
              borderColor: '#FF7262',
              opacity: 0.85,
              width: '100px',
              marginRight: '24px',
            }}
          >
            Archive
          </Button>
        </Box>
      );
    } else if (job && job?.status === JOB_STATUSES.ARCHIVED) {
      return (
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={{
              borderWidth: '1px',
              borderStyle: 'solid',
              color: '#71A7A6',
              borderColor: '#71A7A6',
              opacity: 0.85,
              width: '130px',
              marginRight: '24px',
            }}
            onClick={() => reactivateJobClick()}
            color="primary"
          >
            <Typography align="right">Reactivate</Typography>
          </Button>
        </Box>
      );
    }
  }

  function displayShareIcon() {
    if (job && job?.status === JOB_STATUSES.ACTIVE) {
      return (
        <IconButton
          key={job._id}
          onClick={() => onClickShareButton()}
          aria-label="share"
        >
          <ShareIcon fontSize="large" />
        </IconButton>
      );
    }
  }

  return (
    <Grid container direction="column">
      <SnackBarErrorLayout
        open={openSnackbar}
        handleClose={handleClose}
        errorMessage={errorMessage}
      ></SnackBarErrorLayout>
      <ShareLink
        job={job}
        shareLinkOpen={shareLinkOpen}
        onClose={handleShareLinkClose}
      />
      {archiveJobOpen && (
        <JobArchiveConfirm
          job={job}
          onClose={handleJobArchiveClose}
          onSubmit={handleArchiveJobSubmit}
        />
      )}
      {confirmReactivateJobOpen && (
        <JobReactivateConfirm
          job={job}
          onClose={handleJobReactivateClose}
          onSubmit={handleReactivateJobSubmit}
        />
      )}
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h4">
              {job.title}
              &nbsp; &nbsp;
              {displayShareIcon()}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {displayJobActionButton()}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <JobPageTabs job={job} tabId={selectedTab} />
      </Grid>
    </Grid>
  );
}

ShowJob.layoutProps = {};

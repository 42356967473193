import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes, { InferProps } from 'prop-types';
import validate from 'validate.js';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import { useRouter } from 'next/router';

import { makeStyles } from '@material-ui/styles';

type FormData = {
  email: string;
  password: string;
  agreement: boolean;
  firstName: string;
  lastName: string;
};

export type SignUpFormProps = InferProps<typeof SignUpForm.propTypes>;

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'relative',
    left: 20,
  },
}));
export function SignUpForm(props: SignUpFormProps) {
  const {
    onSubmit,
    processing,
    agreement,
    redirectLink,
    defaultEmail = '',
  } = props;

  const classes = useStyles();

  const router = useRouter();

  const [disabled, setDisabled] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { handleSubmit, formState, control, errors } = useForm<FormData>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: defaultEmail,
      password: '',
      agreement: false,
    },
  });

  const onFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data);

    if (redirectLink) {
      router.push(redirectLink);
    }
  });

  useEffect(() => {
    setDisabled(
      processing ||
        !formState.isDirty ||
        (formState.isDirty && !formState.isValid)
    );
  }, [formState, processing]);

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <form onSubmit={onFormSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: 'First Name is required',
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="First name"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: 'Last Name is required',
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="email"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    disabled
                    fullWidth
                    variant="outlined"
                    label="Work Email"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: 'password is required',
                  minLength: {
                    value: 8,
                    message: 'password length should be at least 8 characters',
                  },
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="Password"
                    type="password"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            {agreement && (
              <Grid item>
                <Controller
                  control={control}
                  name="agreement"
                  render={(
                    { onChange, onBlur, name, ref, value },
                    { invalid, isTouched, isDirty }
                  ) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            handleTermsChange(e);
                            onChange(e.target.checked);
                          }}
                          inputRef={ref}
                          onBlur={onBlur}
                          name={name}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body1">
                          I accept the
                          <Link
                            href="https://www.hirehac.com/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Terms of service{' '}
                          </Link>
                          and
                          <Link
                            href="https://www.hirehac.com/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Privacy policy
                          </Link>
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <Box textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={!termsAccepted}
                >
                  Sign Up
                  {processing && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  erLogo: PropTypes.bool,
  resetPasswordLink: PropTypes.element,
  processing: PropTypes.bool,
  agreement: PropTypes.bool,
  redirectLink: PropTypes.string,
  defaultEmail: PropTypes.string,
};

SignUpForm.defaultProps = {
  defaultEmail: '',
  processing: false,
  agreement: true,
};

export default SignUpForm;

import {
  IInterviewer,
  IJobDocument,
  INTERVIEW_TYPES,
  IStage,
  STAGE_INTERVIEWER_ROTATION,
} from '@er/data-hiring';
import { PanelType } from '@er/ui-lib';
import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';
import { InterviewerRotation } from './interviewer-rotation';
import CustomCircularLoading from '../shared/custom-circular-loading';

export interface IOneOnOneProps {
  onClose?: () => void;
  onSubmit?: (
    data: OneOnOneStageDataForm,
    selectedInterviewers,
    interviewerRotation: STAGE_INTERVIEWER_ROTATION
  ) => void;
  onTypeChange?: (data: any) => void;
  addStageOpen: Boolean;
  interviewSegment?: PanelType;
  job?: IJobDocument;
  stage: IStage;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: 2,
    padding: 0,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    zIndex: 1000,
  },
}));

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export type OneOnOneStageDataForm = {
  type: INTERVIEW_TYPES;
  durationMinutes: number;
  title: string;
  description?: string;
};

export const SetupOneOnOneStage: FunctionComponent<IOneOnOneProps> = ({
  addStageOpen,
  onSubmit,
  onClose,
  onTypeChange,
  interviewSegment,
  job,
  stage,
  loading,
}) => {
  const classes = useStyles();

  const queryString = interviewSegment?.interviewers
    ? interviewSegment?.interviewers
        .map((id) => `ids=${encodeURIComponent(id)}`)
        .join('&')
    : '';

  const interviewersResponse = useSWR(
    `/api/users/company/${job.company}/interviewers?${queryString}`,
    fetchFetcher,
    {
      fallbackData: [] && { data: [] },
    }
  );

  let interviewers = interviewersResponse?.data?.data;

  const defaultValues: OneOnOneStageDataForm = {
    type: INTERVIEW_TYPES.ON_ON_ONE,
    durationMinutes: interviewSegment ? interviewSegment.durationMinutes : 60,
    title: stage?.title,
    description: stage?.eventDescription,
  };

  const [selectedInterviewers, setSelectedInterviewers] = useState(
    interviewSegment ? interviewSegment.interviewers : []
  );

  const { handleSubmit, formState, control, errors, reset } =
    useForm<OneOnOneStageDataForm>({
      defaultValues,
    });

  const handleFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data, selectedInterviewers, interviewerRotation);
  });

  const updateInterviewers = (newInterviewersList) => {
    setSelectedInterviewers(newInterviewersList);
  };

  const removeInterviewer = (interviewerToRemove) => {
    const newSelectedInterviewers = selectedInterviewers.filter(
      (interviewer, idx) => interviewer !== interviewerToRemove._id
    );

    setSelectedInterviewers(newSelectedInterviewers);
  };

  const labelRef = useRef();
  const labelWidth =
    labelRef && labelRef.current ? (labelRef.current as any).clientWidth : 110;

  let initialInterviewRotation = STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION;

  if (stage?.interviewerRotation) {
    initialInterviewRotation = stage?.interviewerRotation;
  }

  const [interviewerRotation, setInterviewerRotation] =
    useState<STAGE_INTERVIEWER_ROTATION>(initialInterviewRotation);

  const handleChange = (action) => {
    if (action === STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY) {
      setInterviewerRotation(STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY);
    } else if (action === STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION) {
      setInterviewerRotation(STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION);
    }
  };

  return (
    <Dialog
      open={!!addStageOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          One-on-one: Setting
        </Typography>
      </DialogTitle>
      <DialogContent>
        {interviewersResponse.isValidating && <CustomCircularLoading />}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          style={{ gap: 10 }}
        >
          <Controller
            control={control}
            name="type"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl variant="outlined" error={invalid}>
                <InputLabel
                  ref={labelRef}
                  shrink
                  htmlFor="interview_form_interview_type"
                >
                  Stage Type
                </InputLabel>
                <Select
                  onBlur={onBlur}
                  id="interview_form_interview_type"
                  inputRef={ref}
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={value}
                  IconComponent={() => null}
                  onChange={(e) => {
                    onTypeChange(e.target.value);
                    onChange(e.target.value);
                  }}
                >
                  <MenuItem
                    key={INTERVIEW_TYPES.ON_ON_ONE}
                    value={INTERVIEW_TYPES.ON_ON_ONE}
                  >
                    {capitalize(INTERVIEW_TYPES.ON_ON_ONE)}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="title"
            rules={{
              required: 'Stage name is required',
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Stage name"
                type="text"
                inputRef={ref}
                value={value}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof OneOnOneStageDataForm}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            control={control}
            name="durationMinutes"
            rules={{
              required: 'Duration minutes must be set',
              validate: (value) => {
                if (value < 15) {
                  return 'Duration must be at least 15 minutes';
                }
              },
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Duration (Minutes)"
                type="number"
                inputRef={ref}
                value={value}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof OneOnOneStageDataForm}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Controller
            control={control}
            name="interviewers"
            defaultValue={interviewers}
            rules={{
              validate: (value) => {
                if (selectedInterviewers.length <= 0) {
                  return 'At least one interviewer must be selected';
                }
              },
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl variant="outlined" error={invalid}>
                <InputLabel id="event_settings_form_interviewers">
                  Interviewers
                </InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null, // This line is essential to make it work
                  }}
                  onBlur={onBlur}
                  labelId="event_settings_form_interviewers"
                  inputRef={ref}
                  value={selectedInterviewers}
                  multiple
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Interviewers"
                    />
                  }
                  renderValue={(selected: string[]) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => {
                        const interviewer: IInterviewer = interviewers.find(
                          (i) => i._id === value
                        );

                        if (!interviewer) {
                          return null;
                        }

                        return (
                          <Chip
                            style={{
                              backgroundColor: interviewer.calendarSynced
                                ? 'rgba(209, 226, 226, 0.35)'
                                : 'red',
                            }}
                            className={classes.chip}
                            label={`${interviewer?.lastName} ${interviewer?.firstName}`}
                            key={value}
                            onDelete={() => {
                              removeInterviewer(interviewer);
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                  onChange={(e) => {
                    updateInterviewers(e.target.value);
                    onChange(e.target.value);
                  }}
                >
                  {interviewers
                    ?.filter((interviewer) => interviewer.calendarSynced)
                    .map((interviewer) => (
                      <MenuItem key={interviewer._id} value={interviewer._id}>
                        {interviewer.lastName} {interviewer.firstName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {invalid ? 'At least one interviewer must be selected' : ''}
                </FormHelperText>
              </FormControl>
            )}
          />
          <InterviewerRotation
            interviewerRotation={interviewerRotation}
            handleChange={handleChange}
          ></InterviewerRotation>
          <Controller
            control={control}
            name="description"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Event Description"
                type="text"
                inputRef={ref}
                value={value}
                multiline
                rows={5}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof OneOnOneStageDataForm}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Box
            display="flex"
            justifyContent="center"
            mt={2}
            style={{ marginBottom: '24px' }}
          >
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleFormSubmit}
              variant="contained"
              color="primary"
              style={{ marginLeft: '24px' }}
              disabled={loading}
            >
              Update
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

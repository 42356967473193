import { debounce } from 'lodash';
import {
  IInterviewer,
  IJob,
  INTERVIEW_TYPES,
  IOpenHoursSlot,
  USER_TYPES,
} from '@er/data-hiring';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { EventSettingsForm } from 'apps/hiring/components';
import useSWR from 'swr';
import { InterviewSegmentSlot } from './interview-segment-slot';
import { ObjectId } from 'mongoose';
import CustomCircularLoading from '../shared/custom-circular-loading';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 700,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export interface IInterviewSegmentsProps {
  interviewSegments: PanelType[];
  control: any;
  formState: any;

  onInterviewSegmentInterviewerChange?: (
    id: ObjectId | any,
    interviewers: string[]
  ) => any;

  onInterviewSegmentDurationChange?: (
    id: ObjectId | any,
    durationMinutes: number
  ) => any;

  onInterviewSegmentFocusAreaChange?: (
    id: ObjectId | any,
    focusArea: string
  ) => any;

  onInterviewSegmentInterviewerRemove?: (
    id: ObjectId | any,
    interviewerToRemove: ObjectId | any
  ) => any;

  onAddInterviewSegment?: () => any;

  onDeleteInterviewSegment?: (id: ObjectId | any) => any;

  job: IJob;
}

export type PanelType = {
  _id?: ObjectId | any;
  durationMinutes: number;
  focusArea: string;
  interviewers: string[];
};

const defaultValues: EventSettingsForm = {
  type: INTERVIEW_TYPES.ON_ON_ONE,
  durationMinutes: 60,
  title: '',
  description: '',
  meetingLink: '',
  interviewers: [] as string[],
};

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export const InterviewSegment: FunctionComponent<IInterviewSegmentsProps> = ({
  interviewSegments,
  onInterviewSegmentInterviewerChange,
  onInterviewSegmentDurationChange,
  onInterviewSegmentFocusAreaChange,
  onInterviewSegmentInterviewerRemove,
  onAddInterviewSegment,
  onDeleteInterviewSegment,
  control,
  formState,
  job,
}) => {
  function buildQueryString() {
    const combinedInterviewerIds = [
      ...new Set(
        interviewSegments.reduce((acc, segment) => {
          return acc.concat(segment.interviewers || []);
        }, [])
      ),
    ];

    const queryString =
      combinedInterviewerIds?.length > 0
        ? combinedInterviewerIds
            .map((id) => `ids=${encodeURIComponent(id)}`)
            .join('&')
        : '';

    return queryString;
  }

  const queryString = buildQueryString();

  const interviewersResponse = useSWR(
    `/api/users/company/${job.company}/interviewers?${queryString}`,
    fetchFetcher,
    {
      fallbackData: [] && { data: [] },
    }
  );

  let allInterviewers = interviewersResponse.data.data;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      {interviewersResponse.isValidating && <CustomCircularLoading />}
      {interviewSegments.map((slot, idx) => (
        <Grid key={idx} item style={{ marginBottom: '12px' }}>
          <InterviewSegmentSlot
            id={idx}
            {...slot}
            allInterviewers={allInterviewers}
            onInterviewSegmentInterviewerChange={
              onInterviewSegmentInterviewerChange
            }
            onInterviewSegmentDurationChange={onInterviewSegmentDurationChange}
            onInterviewSegmentFocusAreaChange={
              onInterviewSegmentFocusAreaChange
            }
            onInterviewSegmentInterviewerRemove={
              onInterviewSegmentInterviewerRemove
            }
            onDeleteInterviewSegment={onDeleteInterviewSegment}
            control={control}
            formState={formState}
          />
        </Grid>
      ))}
      <Grid item>
        <Button
          onClick={onAddInterviewSegment}
          style={{
            border: '1px solid',
            borderColor: 'currentColor',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
          color="primary"
        >
          Add Segment
        </Button>
      </Grid>
    </Grid>
  );
};

export default InterviewSegment;

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IInterview } from '@er/data-hiring';

export interface IAdminAgendaEditorMarkAsNoShowProps {
  onClose: () => any;
  onConfirmMarkAsNoShow: () => any;
  interview: IInterview;
}

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

export const CandidateConfirmMarkAsNoShow: FunctionComponent<IAdminAgendaEditorMarkAsNoShowProps> =
  ({ interview, onClose, onConfirmMarkAsNoShow }) => {
    const classes = useStyles();

    return (
      <Dialog open={true} maxWidth="sm">
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.textColorAndOpacity}>
              <b>Confirm No-Show</b>
            </Typography>
            <Typography
              align="center"
              style={{
                fontSize: '15px',
                fontWeight: 600,
              }}
              className={classes.textColorAndOpacity}
            >
              Absent Attendee:
            </Typography>
            <Typography
              align="center"
              style={{
                fontSize: '15px',
              }}
              className={classes.textColorAndOpacity}
            >
              {interview?.interviewers?.[0]?.firstName +
                ' ' +
                interview?.interviewers?.[0]?.lastName}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography
              style={{
                fontSize: '16px',
              }}
              className={classes.textColorAndOpacity}
            >
              We apologize for any inconvenience this may have caused.
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
              }}
              className={classes.textColorAndOpacity}
            >
              Upon confirmation, you will receive an email notification with a
              link to reschedule.
            </Typography>
            <br />
            <Button
              onClick={() => onConfirmMarkAsNoShow()}
              color="primary"
              variant="contained"
              style={{ marginBottom: '25px', backgroundColor: '#FFC74C' }}
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default CandidateConfirmMarkAsNoShow;

import { HiringProcessModel, INTERVIEW_STATUSES } from '@er/data-hiring';
import moment from 'moment-timezone';
import absoluteUrl from 'next-absolute-url';

const displayHourAndMinutesInUserTimezone = (time, user) => {
  if (!user?.timeZone) {
    return moment(time).tz('UTC');
  }
  return moment(time).tz(user.timeZone);
};

const displayDateInUserTimezone = (startTime, endTime, user) => {
  return (
    displayHourAndMinutesInUserTimezone(startTime, user).format(
      'MMM DD, YYYY'
    ) +
    ' ' +
    displayHourAndMinutesInUserTimezone(startTime, user).format('h:mm a') +
    ' - ' +
    displayHourAndMinutesInUserTimezone(endTime, user).format('h:mm a')
  );
};

const generateRescheduleLink = async (req, candidate, hiringProcessId) => {
  const hiringProcessWithCandidateLoginToken =
    await HiringProcessModel.findById(hiringProcessId).select(
      '+candidateLoginToken'
    );

  return (
    absoluteUrl(req).origin +
    '/users/' +
    candidate?._id +
    '/reschedule/' +
    hiringProcessWithCandidateLoginToken._id +
    '?token=' +
    hiringProcessWithCandidateLoginToken.candidateLoginToken
  );
};

function sortInterviews(a, b) {
  if (a == null) return 1;
  if (b == null) return -1;

  if (a.status === INTERVIEW_STATUSES.CANCELED) return -1;
  if (b.status === INTERVIEW_STATUSES.CANCELED) return 1;

  if (a.status === INTERVIEW_STATUSES.REQUESTED) return -1;
  if (b.status === INTERVIEW_STATUSES.REQUESTED) return 1;

  if (
    a.status === INTERVIEW_STATUSES.SCHEDULED &&
    b.status === INTERVIEW_STATUSES.SCHEDULED
  ) {
    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  }

  if (a.status === INTERVIEW_STATUSES.SCHEDULED) return -1;
  if (b.status === INTERVIEW_STATUSES.SCHEDULED) return 1;

  return 0;
}

export {
  displayHourAndMinutesInUserTimezone,
  displayDateInUserTimezone,
  generateRescheduleLink,
  sortInterviews,
};

import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { USER_TYPES } from '@er/data-hiring';
import { signOut } from 'next-auth/client';
import { useRouter } from 'next/router';
import { ElitRecruiterLogo } from '../elit-recruiter-logo';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    cursor: 'pointer',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    height: '100vh',
  },
  selected: {
    '& svg': { fill: theme.palette.primary.main },
    color: theme.palette.primary.main,
  },
}));

export default function CandidatePortalSideBar({ open }) {
  const classes = useStyles();
  const router = useRouter();

  const buildIconsList = () => {
    let iconsList = [];

    iconsList = [
      {
        title: 'Jobs',
        icon: ShareOutlinedIcon,
        href: '/users/me',
      },
      {
        title: 'Profile',
        icon: PermIdentityOutlinedIcon,
        href: '/users/profile',
      },
    ];

    return iconsList;
  };

  const handleLogOutClick = async () => {
    await signOut({ redirect: false });

    router.push('/users/logout');
  };

  return (
    <Drawer
      variant="permanent"
      elevation={2}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <ElitRecruiterLogo
          type={open ? 'long' : 'short'}
          light
          height={60}
          width="auto"
        />
      </div>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.drawerContainer}
      >
        <Grid item>
          <List>
            {buildIconsList().map((item) => (
              <Link key={item.title} href={item.href} passHref>
                <ListItem
                  key={item.title}
                  button
                  component="a"
                  selected={router.pathname == item.href}
                  classes={{
                    selected: classes.selected,
                  }}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <List>
            {[
              {
                title: 'Logout',
                icon: ExitToAppOutlinedIcon,
              },
            ].map((item) => (
              <ListItem
                button
                component="a"
                onClick={() => handleLogOutClick()}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText secondary={item.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}

import { IHiringProcess } from '@er/data-hiring';
import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { CandidateJobListItem } from './candidate-job-list-item';

export interface ICandidateJobListProps {
  hiringProcesses: IHiringProcess[];
  onHiringProcessWithdraw?: (_id: string) => any;
  onHiringProcessScheduleOpen: (_id: string) => any;
  onViewInterviewAgenda: (hiringProcess: IHiringProcess) => any;
}

export const CandidateJobList: FunctionComponent<ICandidateJobListProps> = ({
  hiringProcesses = [],
  onHiringProcessWithdraw,
  onHiringProcessScheduleOpen,
  onViewInterviewAgenda,
}) => {
  if (!hiringProcesses.length) {
    return (
      <Typography variant="body1">
        You were not invited to any jobs yet.
      </Typography>
    );
  }

  return (
    <Grid container direction="column" spacing={2}>
      {hiringProcesses.map((hp) => (
        <Grid item key={hp._id}>
          <CandidateJobListItem
            hiringProcess={hp}
            onWithdraw={onHiringProcessWithdraw}
            onScheduleOpen={onHiringProcessScheduleOpen}
            onViewAgenda={onViewInterviewAgenda}
          />
        </Grid>
      ))}
    </Grid>
  );
};

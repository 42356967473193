import {
  IInterview,
  IInterviewSegment,
  IInterviewer,
  IUser,
} from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { FunctionComponent, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { displaySchedulingCountIconForAssignedInterviewer } from './admin-agenda-editor-interview-segment-slot';
import RefreshIcon from '@material-ui/icons/Refresh';
import { displayHourAndMinutesInUserTimezone } from 'apps/hiring/pages/api/utils/utils';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
  },
  listItemSelected: {
    color: theme.palette.primary.main,
  },
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

export interface IAdminAgendaEditorChangeInterviewerProps {
  changeInterviewerList: IInterviewer[];
  user: IUser;
  interview: IInterview;
  onClose: () => any;
  refreshAgendaEditorData: () => any;
  refreshInterviewerAvailability: () => any;
  interviewSegment: IInterviewSegment;
}

export const getChangeInterviewerListForRequestedInterview = (
  currentInterview
) => {
  return fetch(
    `/api/interviews/change-interviewer/requested-interview/${currentInterview._id}`,
    {
      method: 'GET',
    }
  ).then((r) => r.json());
};

export const UNASSIGN = 'Unassign';

function CustomListItem({
  interviewer,
  selectedInterviewerId,
  theme,
  handleListItemClick,
  refreshAvailability,
  user,
  index,
}) {
  const rippleRef = useRef(null);
  const classes = useStyles();

  async function onRefreshClick(event, interviewer) {
    event.stopPropagation();
    event.preventDefault();

    rippleRef.current.stop(event, true);

    refreshAvailability(interviewer);
  }

  const determineRefreshButtonDisabled = (interviewer) => {
    let lastAvailabilityReadPlusOneMinute = new Date(
      interviewer?.lastReadMyAvailabilitySchedulingOptions
    );
    lastAvailabilityReadPlusOneMinute.setTime(
      lastAvailabilityReadPlusOneMinute.getTime() + 1 * 60 * 1000
    );

    const currentTime = new Date();
    if (currentTime < lastAvailabilityReadPlusOneMinute) {
      return true;
    }

    return false;
  };

  const displayLastRefreshTimeForInterviewer = (interviewer, user) => {
    if (interviewer && interviewer?.lastReadMyAvailabilitySchedulingOptions) {
      return (
        <div style={{ textAlign: 'right' }}>
          {displayHourAndMinutesInUserTimezone(
            interviewer.lastReadMyAvailabilitySchedulingOptions,
            user
          ).format('MM-DD-YYYY')}
          <br />
          {displayHourAndMinutesInUserTimezone(
            interviewer.lastReadMyAvailabilitySchedulingOptions,
            user
          ).format('h:mm a')}
        </div>
      );
    } else {
      return (
        <div>
          <strong>Last Refresh: </strong>
          No data
        </div>
      );
    }
  };

  return (
    <ListItem
      button
      selected={selectedInterviewerId === interviewer._id}
      style={{
        border:
          selectedInterviewerId === interviewer._id
            ? `3px solid ${theme.palette.primary.main}`
            : '1px solid lightgray',
        marginBottom: '15px',
        borderRadius: '8px',
        paddingBottom: '12px',
        paddingTop: '12px',
      }}
      onClick={(event) => handleListItemClick(event, interviewer._id)}
      TouchRippleProps={{ ref: rippleRef }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Box display="flex" height="100%">
            <Box display="flex" alignItems="center" height="100%">
              {displaySchedulingCountIconForAssignedInterviewer(interviewer)}
            </Box>
            &nbsp; &nbsp;
            <div>
              <Typography
                variant="body1"
                key={index}
                className={classes.textColorAndOpacity}
                style={{ fontWeight: 600, fontSize: '14px' }}
              >
                {interviewer.firstName} {interviewer.lastName}
              </Typography>
              <Typography
                variant="body1"
                key={'job' + index}
                className={classes.textColorAndOpacity}
                style={{ fontSize: '14px' }}
              >
                {interviewer?.jobTitle}
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid item xs={5}>
          {displayLastRefreshTimeForInterviewer(interviewer, user)}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            color="primary"
            onClick={(e) => onRefreshClick(e, interviewer)}
            disabled={determineRefreshButtonDisabled(interviewer)}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export const AdminAgendaEditorChangeInterviewerRequestedInterview: FunctionComponent<IAdminAgendaEditorChangeInterviewerProps> =
  ({
    changeInterviewerList,
    interview,
    interviewSegment,
    onClose,
    refreshAgendaEditorData,
    user,
    refreshInterviewerAvailability,
  }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [
      switchInterviewerButtonDisabled,
      setSwitchInterviewerButtonDisabled,
    ] = useState(true);
    const [selectedInterviewerId, setSelectedInterviewerId] = useState('');

    const handleListItemClick = (event, interviewerId) => {
      setSelectedInterviewerId(interviewerId);
      setSwitchInterviewerButtonDisabled(false);
    };

    const executeSwitchInterviewer = async () => {
      await fetch(
        `/api/interviews/switch-interviewer/requested-interview/${interview._id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ selectedInterviewerId }),
          method: 'POST',
        }
      ).then((r) => r.json());

      refreshAgendaEditorData();
      onClose();
    };

    const displayUnAssignOption = () => {
      if (
        interview &&
        interview.interviewers &&
        interview.interviewers.length > 0
      ) {
        return (
          <ListItem
            button
            selected={selectedInterviewerId === UNASSIGN}
            style={{
              border:
                selectedInterviewerId === UNASSIGN
                  ? `3px solid ${theme.palette.primary.main}`
                  : '1px solid lightgray',
              marginBottom: '15px',
              borderRadius: '8px',
              paddingBottom: '25px',
              paddingTop: '25px',
            }}
            onClick={(event) => handleListItemClick(event, UNASSIGN)}
          >
            <Typography key={-1} style={{ fontSize: '14px' }}>
              Unassign
            </Typography>
          </ListItem>
        );
      }
    };

    async function refreshAvailability(interviewer) {
      await fetch(`/api/cronofy/availability-rule/${interviewer._id}/refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          return r;
        });

      refreshInterviewerAvailability();
    }

    return (
      <Dialog
        // open={!!addStageOpen}
        open={true}
        onClose={() => console.log('On close')}
        disableBackdropClick
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.textColorAndOpacity}>
              <b>Assign Interviewer</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.textColorAndOpacity}>
              {interviewSegment?.focusArea}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <List component="nav" aria-label="main mailbox folders">
              {displayUnAssignOption()}
              {changeInterviewerList.map((interviewer, index) => (
                <Box display="flex">
                  <CustomListItem
                    refreshAvailability={refreshAvailability}
                    interviewer={interviewer}
                    selectedInterviewerId={selectedInterviewerId}
                    theme={theme}
                    handleListItemClick={handleListItemClick}
                    user={user}
                    index={index}
                  ></CustomListItem>
                </Box>
              ))}
            </List>

            <Button
              onClick={() => executeSwitchInterviewer()}
              color="primary"
              disabled={switchInterviewerButtonDisabled}
              variant="contained"
              style={{ marginBottom: '15px' }}
            >
              Assign Interviewer
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default AdminAgendaEditorChangeInterviewerRequestedInterview;

import mongoose, { model, Schema } from 'mongoose';
import {
  CALENDAR_EVENT_STATUSES,
  FREE_BUSY_STATUSES,
  WEEK_DAYS,
} from './calendar.constants';

import {
  ICalendarEvent,
  ICalendarEventModel,
} from './calendar-event.interface';

export const CalendarEventSchema: Schema<
  ICalendarEvent,
  ICalendarEventModel
> = new Schema(
  {
    user: { type: Schema.Types.ObjectId, ref: 'User' },
    title: String,
    description: String,
    location: String,
    calendar: { type: Schema.Types.ObjectId, ref: 'Calendar' },
    masterEventID: { type: Schema.Types.ObjectId, ref: 'CalendarEvent' },
    iCalUID: String,
    status: {
      type: String,
      enum: Object.values(CALENDAR_EVENT_STATUSES),
      required: true,
    },
    isAllDay: { type: Boolean, default: false, required: true },
    isReoccurring: { type: Boolean, default: false, required: true },
    recurrence: String,
    startDateUTC: { type: Date, required: true },
    endDateUTC: { type: Date, required: true },
    durationMinutes: { type: Number, required: true },
    freeBusy: {
      type: String,
      enum: Object.values(FREE_BUSY_STATUSES),
      default: FREE_BUSY_STATUSES.BUSY,
      required: true,
    },
  },
  {
    timestamps: true,
  }
);

export const CalendarEventModel: ICalendarEventModel = (mongoose.models
  ?.CalendarEvent ||
  model('CalendarEvent', CalendarEventSchema)) as ICalendarEventModel;

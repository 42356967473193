import {
  HIRING_PROCESS_STATUSES,
  IHiringProcess,
  IInterviewSegment,
  INTERVIEW_COLOR_AGES,
  INTERVIEW_STATUSES,
  IUser,
} from '@er/data-hiring';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { displayHourAndMinutesInUserTimezone } from 'apps/hiring/pages/api/utils/utils';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { icons } from './candidate-interview-agenda';
import AdminAgendaEditorConfirmRequestReschedule from './admin-agenda-editor-confirm-request-reschedule';
import AdminAgendaEditorConfirmCancelInterview from './admin-agenda-editor-confirm-cancel-interview';
import AdminAgendaEditorChangeInterviewer from './admin-agenda-editor-change-interviewer';
import AdminAgendaEditorMarkAsNoShow from './admin-agenda-editor-mark-as-no-show';
import moment from 'moment';
import SendReminder from './admin-agenda-editor-confirm-send-reminder';
import interviewActions from './interview-actions';
import AdminAgendaEditorChangeInterviewerRequestedInterview from './admin-agenda-editor-change-interviewer-requested-interview';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import CustomAvatar from '@er/ui-lib/lib/common/shared/custom-avatar';
import { capitalizeFirstLetter } from 'apps/hiring/pages/jobs/[id]';
import { LOW_AVAILABILITY_THRESHOLD } from '@er/ui-lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    reactCalendar: {
      background: 'transparent',
      border: 'none',
      maxWidth: 300,
      width: 300,
      height: 350,
      maxHeight: 350,
    },
    interviewStatus: {
      display: 'inline-flex',
      flexDirection: 'row',

      textTransform: 'capitalize',
      fontWeight: 'bolder',
    },
  })
);

export const assignInterviewerDisabled = (interview, interviewSegment) => {
  if (interviewSegment && interviewSegment?.interviewers?.length <= 1) {
    const assignedInterviewer = interview?.interviewers?.[0];

    const interviewSegmentInterviewerId = interviewSegment?.interviewers?.[0]
      ?._id
      ? interviewSegment?.interviewers?.[0]?._id
      : interviewSegment?.interviewers?.[0];

    if (
      assignedInterviewer &&
      assignedInterviewer?._id !== interviewSegmentInterviewerId
    ) {
      return false;
    }

    return true;
  }

  return false;
};

export interface IAdminAgendaEditorInterviewSegmentSlotProps {
  hiringProcess: IHiringProcess;
  refreshAgendaEditorData: () => void;
  interviewSegment: IInterviewSegment;
  user: IUser;
}

export const interviewActive = (currentInterview, hiringProcess) => {
  if (
    hiringProcess &&
    hiringProcess.status &&
    hiringProcess.status == HIRING_PROCESS_STATUSES.ACTIVE &&
    hiringProcess.currentStage &&
    currentInterview.stage &&
    new String(currentInterview.stage).valueOf() ==
      new String(hiringProcess.currentStage).valueOf()
  ) {
    return true;
  }

  return false;
};

export const displaySchedulingCountIconForAssignedInterviewer = (
  interviewer
) => {
  if (
    !interviewer ||
    interviewer?.cachedLongestInterviewDuration == null ||
    !interviewer?.cachedSchedulingOptionsCount == null
  ) {
    return;
  }

  const schedulingOptionsCount = interviewer?.cachedSchedulingOptionsCount;

  if (schedulingOptionsCount === 0) {
    return (
      <Typography variant="body1">
        <WarningIcon style={{ color: 'red' }} />
      </Typography>
    );
  } else if (schedulingOptionsCount < LOW_AVAILABILITY_THRESHOLD) {
    return (
      <Typography variant="body1">
        <WarningIcon style={{ color: 'orange' }} />
      </Typography>
    );
  } else {
    return (
      <Typography variant="body1">
        <DoneIcon style={{ color: 'green' }} />
      </Typography>
    );
  }
};

export const getChangeInterviewerList = (currentInterview) => {
  return fetch(`/api/interviews/change-interviewer/${currentInterview._id}`, {
    method: 'GET',
  }).then((r) => r.json());
};

export const timeNowAfterInterviewStart = (interview) => {
  var currentUTCTime = moment().utc().format();

  return interview && interview.startTime < currentUTCTime;
};

export const AdminAgendaEditorInterviewSegmentSlot: FunctionComponent<IAdminAgendaEditorInterviewSegmentSlotProps> =
  ({ interviewSegment, hiringProcess, refreshAgendaEditorData, user }) => {
    const classes = useStyles();

    const {
      confirmRequestRescheduleOpen,
      confirmCancelInterviewOpen,
      markAsNoShowOpen,
      changeInterviewerOpen,
      selectedInterview,
      onMarkAsNoShowClose,
      onChangeInterviewerClose,
      onCancelInterviewClose,
      onRequestRescheduleClose,
      markAsNoShowClicked,
      cancelInterviewClicked,
      requestRescheduleClicked,
      changeInterviewerClicked,
      changeInterviewerMap,
      changeInterviewerClickedForRequestedInterview,
      changeInterviewerForRequestedInterviewOpen,
      changeInterviewerForRequestedInterviewList,
      onChangeInterviewerForRequestedInterviewClose,
      refreshInterviewerAvailability,
    } = interviewActions();

    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    const displaySentReminderAt = (currentInterview) => {
      if (!currentInterview || !currentInterview.sentReminderAt) {
        return (
          <Typography variant="body1" display="inline">
            None sent
          </Typography>
        );
      }

      return (
        <Typography variant="body1" display="inline">
          {displayHourAndMinutesInUserTimezone(
            currentInterview.sentReminderAt,
            user
          ).format('MMMM-DD-YYYY h:mm a')}
        </Typography>
      );
    };

    const displayLastRefreshTimeForInterviewer = (interviewer, user) => {
      if (interviewer && interviewer?.lastReadMyAvailabilitySchedulingOptions) {
        return (
          <div style={{ marginTop: '-6px' }}>
            <Typography display="inline">
              <strong>Refresh:</strong>
            </Typography>
            &nbsp;
            <Typography display="inline">
              {displayHourAndMinutesInUserTimezone(
                interviewer.lastReadMyAvailabilitySchedulingOptions,
                user
              ).format('MMMM-DD-YYYY h:mm a')}
            </Typography>
          </div>
        );
      } else {
        return (
          <div>
            <strong>Last availability refresh: </strong>
            No data
          </div>
        );
      }
    };

    const displayAssignedInterviewer = (currentInterview) => {
      if (
        currentInterview &&
        currentInterview.interviewers &&
        currentInterview.interviewers.length > 0
      ) {
        return (
          <div>
            <Box
              display="flex"
              alignItems="center"
              style={{ marginTop: '-6px' }}
            >
              <Typography variant="body1" display="inline">
                <strong>Assigned:</strong>
              </Typography>
              &nbsp;
              <Typography variant="body1" display="inline">
                {currentInterview.interviewers[0].firstName +
                  ' ' +
                  currentInterview.interviewers[0].lastName}
              </Typography>
              &nbsp; &nbsp;
              {displaySchedulingCountIconForAssignedInterviewer(
                currentInterview?.interviewers?.[0]
              )}
            </Box>
            <Box>
              {displayLastRefreshTimeForInterviewer(
                currentInterview?.interviewers?.[0],
                user
              )}
            </Box>
          </div>
        );
      }
    };

    const buildRequestedInterviewText = (currentInterview) => {
      const text =
        currentInterview.requestInformation +
        displayHourAndMinutesInUserTimezone(
          currentInterview.requestAtUTC,
          user
        ).format('MMMM-DD-YYYY h:mm a');

      return (
        <Typography variant="body1" style={{ marginTop: '12px' }}>
          {text}
        </Typography>
      );
    };

    const buildRequestedInterviewComponent = (currentInterview) => {
      return (
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={8}
            style={{
              display: 'flex',
            }}
          >
            <Box style={{ marginTop: '12px' }}>
              <div style={{ marginBottom: '12px' }}>
                {displayFocusAreaWithIcon()}
              </div>
              <Typography variant="body1" display="inline">
                {' '}
                <strong>Reminder: </strong>{' '}
              </Typography>
              &nbsp; {displaySentReminderAt(currentInterview)}
              {displayAssignedInterviewer(currentInterview)}
              {buildRequestedInterviewText(currentInterview)}
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '24px',
              }}
            >
              <SendReminder
                currentInterview={currentInterview}
                refreshData={refreshAgendaEditorData}
                hiringProcessId={hiringProcess._id}
                focusArea={interviewSegment.focusArea}
              ></SendReminder>

              <Button
                onClick={() =>
                  changeInterviewerClickedForRequestedInterview(
                    currentInterview
                  )
                }
                style={{
                  width: '100%',
                  color: assignInterviewerDisabled(
                    currentInterview,
                    interviewSegment
                  )
                    ? ''
                    : '#ffad33',
                }}
                disabled={assignInterviewerDisabled(
                  currentInterview,
                  interviewSegment
                )}
              >
                Assign Interviewer
              </Button>
            </div>
          </Grid>
        </Grid>
      );
    };

    const markAsNoShowDisabledForSchdeduledInterview = (currentInterview) => {
      if (timeNowAfterInterviewStart(currentInterview)) {
        return false;
      }

      return true;
    };

    const displayMarkAsNoShowForScheduledInterview = (currentInterview) => {
      const markAsNoShowDisabled =
        markAsNoShowDisabledForSchdeduledInterview(currentInterview);

      return (
        <Button
          onClick={() => markAsNoShowClicked(currentInterview)}
          style={{
            color: markAsNoShowDisabled ? '' : '#C4C4C4',
            width: '100%',
          }}
          disabled={markAsNoShowDisabled}
        >
          Mark as no show
        </Button>
      );
    };

    const buildScheduledInterviewComponent = (
      currentInterview,
      interviewSegment
    ) => {
      return (
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
            }}
          >
            <Box
              style={{
                marginTop: '10px',
              }}
            >
              <div style={{ marginBottom: '12px' }}>
                {displayFocusAreaWithIcon()}
              </div>
              <Typography variant="body1">
                <strong>Day: </strong>{' '}
                {displayHourAndMinutesInUserTimezone(
                  currentInterview.startTime,
                  user
                ).format('MMM DD, YYYY')}
              </Typography>
              <Typography variant="body1">
                <strong>Time: </strong>{' '}
                {displayHourAndMinutesInUserTimezone(
                  currentInterview.startTime,
                  user
                ).format('h:mm a')}{' '}
                -{' '}
                {displayHourAndMinutesInUserTimezone(
                  currentInterview.endTime,
                  user
                ).format('h:mm a')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomAvatar interview={currentInterview} />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '24px',
              }}
            >
              <Button
                onClick={() => changeInterviewerClicked(currentInterview)}
                style={{
                  color: assignInterviewerDisabled(
                    currentInterview,
                    interviewSegment
                  )
                    ? ''
                    : '#71A7A6',

                  width: '100%',
                }}
                disabled={assignInterviewerDisabled(
                  currentInterview,
                  interviewSegment
                )}
              >
                Change interviewer
              </Button>
              <Button
                onClick={() => requestRescheduleClicked(currentInterview)}
                style={{
                  color: '#FFC74C',
                  width: '100%',
                }}
              >
                Request reschedule
              </Button>
              <Button
                onClick={() => cancelInterviewClicked(currentInterview)}
                style={{
                  color: '#FF7262',
                  width: '100%',
                }}
              >
                Cancel Interview
              </Button>
              {displayMarkAsNoShowForScheduledInterview(currentInterview)}
            </div>
          </Grid>
        </Grid>
      );
    };

    const buildCancelledInterviewText = (currentInterview) => {
      let text = '';

      if (currentInterview.cancelAtUTC) {
        text =
          currentInterview.cancelInformation +
          displayHourAndMinutesInUserTimezone(
            currentInterview.cancelAtUTC,
            user
          ).format('MMMM-DD-YYYY h:mm a');
      } else {
        text = currentInterview.cancelInformation;
      }

      return (
        <Typography variant="body1" style={{ marginBottom: '24px' }}>
          {text}
        </Typography>
      );
    };

    const buildCancelledInterviewComponent = (currentInterview) => {
      return (
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={8}
            style={{
              display: 'flex',
            }}
          >
            <Box style={{ marginTop: '12px' }}>
              <div style={{ marginBottom: '30px' }}>
                {displayFocusAreaWithIcon()}
              </div>
              <div style={{ marginBottom: '12px' }}>
                {buildCancelledInterviewText(currentInterview)}
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '24px',
              }}
            >
              <Button
                onClick={() => requestRescheduleClicked(currentInterview)}
                style={{ color: '#FFC74C', width: '100%' }}
              >
                Request reschedule
              </Button>
            </div>
          </Grid>
        </Grid>
      );
    };

    const buildCompletedInterviewComponent = (currentInterview) => {
      return (
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
            }}
          >
            <Typography variant="body1">
              <Box style={{ marginTop: '12px' }}>
                <div style={{ marginBottom: '12px' }}>
                  {displayFocusAreaWithIcon()}
                </div>
                <Box>
                  <strong>Day: </strong>{' '}
                  {displayHourAndMinutesInUserTimezone(
                    currentInterview.startTime,
                    user
                  ).format('MMM DD, YYYY')}
                </Box>
                <Box>
                  <strong>Time: </strong>{' '}
                  {displayHourAndMinutesInUserTimezone(
                    currentInterview.startTime,
                    user
                  ).format('h:mm a')}{' '}
                  -{' '}
                  {displayHourAndMinutesInUserTimezone(
                    currentInterview.endTime,
                    user
                  ).format('h:mm a')}
                </Box>
              </Box>
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomAvatar interview={currentInterview} />
          </Grid>

          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '24px',
              }}
            >
              <Button
                onClick={() => markAsNoShowClicked(currentInterview)}
                style={{ color: '#808080', float: 'right' }}
              >
                Mark as no show
              </Button>
            </div>
          </Grid>
        </Grid>
      );
    };

    const displayInterviewDialog = (interviewSegment) => {
      let currentInterview;

      if (hiringProcessStage && hiringProcessStage.interviews) {
        currentInterview = hiringProcessStage.interviews.find(
          (interview) => interview.interviewSegment === interviewSegment._id
        );
      }

      if (!currentInterview) {
        return;
      }

      switch (currentInterview.status) {
        case INTERVIEW_STATUSES.REQUESTED:
          return buildRequestedInterviewComponent(currentInterview);
          break;
        case INTERVIEW_STATUSES.SCHEDULED:
          return buildScheduledInterviewComponent(
            currentInterview,
            interviewSegment
          );
          break;
        case INTERVIEW_STATUSES.CANCELED:
          return buildCancelledInterviewComponent(currentInterview);
          break;
        case INTERVIEW_STATUSES.COMPLETED:
          return buildCompletedInterviewComponent(currentInterview);
          break;
        default:
          console.log('This interview status is not fully supported yet');
      }
    };

    const displayStatusIcon = (interviewSegment) => {
      let currentInterview;

      if (hiringProcessStage && hiringProcessStage.interviews) {
        currentInterview = hiringProcessStage.interviews.find(
          (interview) => interview.interviewSegment === interviewSegment._id
        );
      }

      if (!currentInterview) {
        return <div></div>;
      }

      const currentInterviewStatus = currentInterview.status;

      let colorAge = currentInterview?.colorAge
        ? currentInterview.colorAge
        : INTERVIEW_COLOR_AGES.GREY;

      const Icon: typeof MailOutlineIcon = icons[currentInterviewStatus];

      return (
        <Box display="flex" alignItems="center" style={{ color: colorAge }}>
          {!!Icon && <Icon fontSize="small" />}
          &nbsp; &nbsp;
          <Typography variant="body1">
            {capitalizeFirstLetter(currentInterviewStatus)}
          </Typography>
        </Box>
      );
    };

    const displayFocusAreaWithIcon = () => {
      return (
        <Box fontWeight="bold" sx={{ display: 'inline' }}>
          <Typography style={{ fontStyle: 'italic', color: 'grey' }}>
            Focus Area
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">
              {interviewSegment.focusArea}
            </Typography>
            &nbsp;&nbsp;
            <Typography style={{ color: 'grey' }}> &mdash;</Typography>
            &nbsp;&nbsp;
            {displayStatusIcon(interviewSegment)}
          </Box>
        </Box>
      );
    };

    return (
      <Card
        style={{
          marginBottom: '12px',
          border: '1px solid lightgray',
          height: '200px',
        }}
      >
        <CardContent
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '0px',
            paddingTop: '0px',
          }}
        >
          {confirmRequestRescheduleOpen && (
            <AdminAgendaEditorConfirmRequestReschedule
              interviewSegment={interviewSegment}
              refreshAgendaEditorData={refreshAgendaEditorData}
              interview={selectedInterview}
              onClose={onRequestRescheduleClose}
              user={user}
            />
          )}
          {changeInterviewerForRequestedInterviewOpen && (
            <AdminAgendaEditorChangeInterviewerRequestedInterview
              refreshAgendaEditorData={refreshAgendaEditorData}
              changeInterviewerList={changeInterviewerForRequestedInterviewList}
              interview={selectedInterview}
              onClose={onChangeInterviewerForRequestedInterviewClose}
              user={user}
              refreshInterviewerAvailability={refreshInterviewerAvailability}
              interviewSegment={interviewSegment}
            />
          )}
          {confirmCancelInterviewOpen && (
            <AdminAgendaEditorConfirmCancelInterview
              refreshAgendaEditorData={refreshAgendaEditorData}
              interview={selectedInterview}
              interviewSegment={interviewSegment}
              onClose={onCancelInterviewClose}
              user={user}
            />
          )}
          {changeInterviewerOpen && (
            <AdminAgendaEditorChangeInterviewer
              refreshAgendaEditorData={refreshAgendaEditorData}
              changeInterviewerMap={changeInterviewerMap}
              interview={selectedInterview}
              interviewSegment={interviewSegment}
              onClose={onChangeInterviewerClose}
            />
          )}
          {markAsNoShowOpen && (
            <AdminAgendaEditorMarkAsNoShow
              refreshAgendaEditorData={refreshAgendaEditorData}
              interview={selectedInterview}
              interviewSegment={interviewSegment}
              onClose={onMarkAsNoShowClose}
              selectCandidateOrInterviewerNoShow
            />
          )}
          {displayInterviewDialog(interviewSegment)}
        </CardContent>
      </Card>
    );
  };

export default AdminAgendaEditorInterviewSegmentSlot;

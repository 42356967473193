import { Tab, Tabs } from '@material-ui/core';
import NextLink from 'next/link';
import React from 'react';

export enum INTERVIEW_TABS {
  MYINTERVIEWS = 0,
  COMPANYINTERVIEWS = 1,
}

export function InterviewTabs({ tabId = INTERVIEW_TABS.MYINTERVIEWS }) {
  return (
    <Tabs
      value={tabId}
      indicatorColor="primary"
      textColor="primary"
      aria-label="disabled tabs example"
    >
      <NextLink
        href={`/interviews/my-interviews`}
        as={`/interviews/my-interviews`}
        passHref
      >
        <Tab
          component="a"
          value={INTERVIEW_TABS.MYINTERVIEWS}
          label="My interviews"
        />
      </NextLink>
      <NextLink
        href={`/interviews/company-interviews`}
        as={`/interviews/company-interviews`}
        passHref
      >
        <Tab
          component="a"
          label="All interviews"
          value={INTERVIEW_TABS.COMPANYINTERVIEWS}
        />
      </NextLink>
    </Tabs>
  );
}

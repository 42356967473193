import { Tab, Tabs } from '@material-ui/core';
import NextLink from 'next/link';
import React from 'react';

export enum JOBS_PAGE_TABS {
  MYJOBS = 0,
  COMPANYJOBS = 1,
}

export function JobsPageTabs({ companyId, tabId = JOBS_PAGE_TABS.MYJOBS }) {
  return (
    <Tabs
      value={tabId}
      indicatorColor="primary"
      textColor="primary"
      aria-label="basic tabs example"
    >
      <NextLink
        href={`/jobs-page/[id]`}
        as={`/jobs-page/${companyId}`}
        passHref
      >
        <Tab
          component="a"
          style={{ minWidth: 'auto', paddingLeft: 20, paddingRight: 20 }}
          value={JOBS_PAGE_TABS.MYJOBS}
          label="My Jobs"
        />
      </NextLink>
      <NextLink
        href={`/jobs-page/[id]/all-company-jobs`}
        as={`/jobs-page/${companyId}/all-company-jobs`}
        passHref
      >
        <Tab
          component="a"
          style={{ minWidth: 'auto', paddingLeft: 20, paddingRight: 20 }}
          label="All jobs"
          value={JOBS_PAGE_TABS.COMPANYJOBS}
        />
      </NextLink>
    </Tabs>
  );
}

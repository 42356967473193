import mongoose, { model, Schema } from 'mongoose';
import { IStage, IStageModel, STAGE_INTERVIEWER_ROTATION } from '../stage';
import { IJob, IJobModel } from './job.interface';
import { JOB_STATUSES } from './job.constants';

export const InterviewSegmentSchema: Schema = new Schema(
  {
    intervalMinutes: { type: Number },
    durationMinutes: { type: Number },
    focusArea: String,
    interviewers: [{ type: Schema.Types.ObjectId, ref: 'User' }],
    evenDistributionInterviewerList: [
      { type: Schema.Types.ObjectId, ref: 'User' },
    ],
    maximizeAvailabilityInterviewerList: [
      {
        _id: {
          type: Schema.Types.ObjectId,
          ref: 'User',
          required: true,
        },
        score: {
          type: Number,
          required: true,
        },
      },
    ],
  },
  {
    timestamps: false,
  }
);

export const StageSchema: Schema<IStage, IStageModel> = new Schema(
  {
    title: { type: String, required: true },
    eventDescription: { type: String },
    type: {
      type: String,
      // enum: Object.values(INTERVIEW_TYPES),
      // default: INTERVIEW_TYPES.ON_ON_ONE,
    },
    interviewSegment: [{ type: InterviewSegmentSchema }],
    fullyConfigured: { type: Boolean, default: false },
    stageOrder: { type: Number, required: true },
    parentStage: {
      type: Schema.Types.ObjectId,
    },
    hiringProcessOrdered: [
      { type: Schema.Types.ObjectId, ref: 'HiringProcess' },
    ],
    interviewerRotation: {
      type: String,
      enum: Object.values(STAGE_INTERVIEWER_ROTATION),
    },
    lastJobOwnerNotificationSent: {
      type: Date,
    },
    lastRefreshAvailabilityClicked: {
      type: Date,
    },
  },
  {
    timestamps: true,
  }
);

export const EventSettingsSchema: Schema = new Schema(
  {
    type: { type: String, required: true },
    durationMinutes: { type: Number, required: true },
    title: { type: String, required: true },
    description: String,
    interviewers: [
      { type: Schema.Types.ObjectId, ref: 'User', required: true },
    ],
  },
  {
    timestamps: false,
    _id: false,
  }
);

export const JobSchema: Schema<IJob, IJobModel> = new Schema(
  {
    title: { type: String, required: true },
    description: String,
    company: { type: Schema.Types.ObjectId, ref: 'Company' },
    eventSettings: {
      type: EventSettingsSchema,
      required: false,
    },
    stages: [{ type: StageSchema }],
    jobOwners: [{ type: Schema.Types.ObjectId, ref: 'User' }],
    status: {
      type: String,
      enum: Object.values(JOB_STATUSES),
      default: JOB_STATUSES.ACTIVE,
      required: true,
    },
  },
  {
    timestamps: true,
  }
);

JobSchema.index({ jobOwners: 1 });
JobSchema.index({ company: 1 });

export const JobModel: IJobModel = (mongoose.models?.Job ||
  model('Job', JobSchema)) as IJobModel;

import { IInterview, IInterviewSegment } from '@er/data-hiring';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  statusDot: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
}));

export interface IAdminAgendaEditorChangeInterviewerProps {
  changeInterviewerMap: {};
  interviewSegment: IInterviewSegment;
  interview: IInterview;
  onClose: () => any;
  refreshAgendaEditorData: () => any;
}

export const AdminAgendaEditorChangeInterviewer: FunctionComponent<IAdminAgendaEditorChangeInterviewerProps> =
  ({
    changeInterviewerMap,
    interview,
    interviewSegment,
    onClose,
    refreshAgendaEditorData,
  }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [
      switchInterviewerButtonDisabled,
      setSwitchInterviewerButtonDisabled,
    ] = useState(true);
    const [selectedInterviewerId, setSelectedInterviewerId] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleListItemClick = (event, index, interviewerId) => {
      setSelectedInterviewerId(interviewerId);
      setSelectedIndex(index);
      setSwitchInterviewerButtonDisabled(false);
    };

    const executeSwitchInterviewer = async () => {
      setLoading(true);
      try {
        await fetch(`/api/interviews/switch-interviewer/${interview._id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ selectedInterviewerId }),
          method: 'POST',
        }).then((r) => r.json());

        refreshAgendaEditorData();
        onClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    return (
      <Dialog
        // open={!!addStageOpen}
        open={true}
        // onClose={onClose}
        disableBackdropClick
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5">
              <b>Change Interviewer</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle1">
              {interviewSegment?.focusArea}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {loading && <CustomCircularLoading />}
          <Box textAlign="center">
            <List component="nav" aria-label="main mailbox folders">
              {Object.keys(changeInterviewerMap).map((key, index) => (
                <ListItem
                  style={{
                    border:
                      selectedIndex === index
                        ? `3px solid ${theme.palette.primary.main}`
                        : '1px solid lightgray',
                    marginBottom: '15px',
                    borderRadius: '8px',
                    paddingBottom: '25px',
                    paddingTop: '25px',
                  }}
                  button
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index, key)}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item>
                          <Avatar style={{ backgroundColor: 'primary' }}>
                            {changeInterviewerMap[key].firstName?.[0] +
                              changeInterviewerMap[key].lastName?.[0]}
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {changeInterviewerMap[key].firstName +
                              ' ' +
                              changeInterviewerMap[key]?.lastName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {changeInterviewerMap[key]?.jobTitle}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{ display: 'inline-block' }}
                      >
                        {changeInterviewerMap[key].available
                          ? 'Available'
                          : 'Busy'}
                      </Typography>
                      <div
                        className={classes.statusDot}
                        style={{
                          backgroundColor: changeInterviewerMap[key].available
                            ? '#92D88C'
                            : '#FF7262',
                          marginLeft: 5,
                          display: 'inline-block',
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={() => executeSwitchInterviewer()}
              color="primary"
              disabled={switchInterviewerButtonDisabled}
              variant="contained"
              style={{
                marginTop: '20px',
                marginBottom: '40px',
              }}
            >
              Change interviewer
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default AdminAgendaEditorChangeInterviewer;

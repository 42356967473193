import { useState } from 'react';
import { getChangeInterviewerListForRequestedInterview } from './admin-agenda-editor-change-interviewer-requested-interview';
import { getChangeInterviewerList } from './admin-agenda-editor-interview-segment-slot';

const interviewActions = () => {
  const [confirmRequestRescheduleOpen, setConfirmRequestRescheduleOpen] =
    useState<Boolean>(false);
  const [confirmCancelInterviewOpen, setConfirmCancelInterviewOpen] =
    useState<Boolean>(false);
  const [markAsNoShowOpen, setMarkAsNoShowOpen] = useState<Boolean>(false);
  const [changeInterviewerOpen, setChangeInterviewerOpen] =
    useState<Boolean>(false);
  const [
    changeInterviewerForRequestedInterviewOpen,
    setChangeInterviewerForRequestedInterviewOpen,
  ] = useState<Boolean>(false);

  const [
    changeInterviewerForRequestedInterviewList,
    setChangeInterviewerForRequestedInterviewList,
  ] = useState([]);

  const [selectedInterview, setSelectedInterview] = useState(null);

  const [changeInterviewerMap, setChangeInterviewerMap] = useState({});

  const onRequestRescheduleClose = () => {
    setConfirmRequestRescheduleOpen(false);
    setSelectedInterview(null);
  };

  const onCancelInterviewClose = () => {
    setConfirmCancelInterviewOpen(false);
    setSelectedInterview(null);
  };

  const onChangeInterviewerClose = () => {
    setChangeInterviewerMap({});
    setChangeInterviewerOpen(false);
    setSelectedInterview(null);
  };

  const onChangeInterviewerForRequestedInterviewClose = () => {
    setChangeInterviewerForRequestedInterviewOpen(false);
    setSelectedInterview(null);
  };

  const onMarkAsNoShowClose = () => {
    setMarkAsNoShowOpen(false);
    setSelectedInterview(null);
  };

  const changeInterviewerClicked = async (currentInterview) => {
    const result = await getChangeInterviewerList(currentInterview);

    const map = result.data;

    setChangeInterviewerMap(map);
    setChangeInterviewerOpen(true);
    setSelectedInterview(currentInterview);
  };

  const requestRescheduleClicked = (currentInterview) => {
    setConfirmRequestRescheduleOpen(true);
    setSelectedInterview(currentInterview);
  };

  const changeInterviewerClickedForRequestedInterview = async (
    currentInterview
  ) => {
    const result = await getChangeInterviewerListForRequestedInterview(
      currentInterview
    );

    setChangeInterviewerForRequestedInterviewOpen(true);
    setSelectedInterview(currentInterview);
    setChangeInterviewerForRequestedInterviewList(result.data);
  };

  const refreshInterviewerAvailability = async () => {
    if (selectedInterview) {
      const result = await getChangeInterviewerListForRequestedInterview(
        selectedInterview
      );
      setChangeInterviewerForRequestedInterviewList(result.data);
    }
  };

  const cancelInterviewClicked = (currentInterview) => {
    setConfirmCancelInterviewOpen(true);
    setSelectedInterview(currentInterview);
  };

  const markAsNoShowClicked = (currentInterview) => {
    setMarkAsNoShowOpen(true);
    setSelectedInterview(currentInterview);
  };

  return {
    confirmRequestRescheduleOpen: confirmRequestRescheduleOpen,
    confirmCancelInterviewOpen: confirmCancelInterviewOpen,
    markAsNoShowOpen: markAsNoShowOpen,
    changeInterviewerOpen: changeInterviewerOpen,
    selectedInterview: selectedInterview,
    onMarkAsNoShowClose: onMarkAsNoShowClose,
    onChangeInterviewerClose: onChangeInterviewerClose,
    onCancelInterviewClose: onCancelInterviewClose,
    onRequestRescheduleClose: onRequestRescheduleClose,
    markAsNoShowClicked: markAsNoShowClicked,
    cancelInterviewClicked: cancelInterviewClicked,
    requestRescheduleClicked: requestRescheduleClicked,
    changeInterviewerClicked: changeInterviewerClicked,
    changeInterviewerMap: changeInterviewerMap,
    changeInterviewerForRequestedInterviewOpen:
      changeInterviewerForRequestedInterviewOpen,
    changeInterviewerClickedForRequestedInterview:
      changeInterviewerClickedForRequestedInterview,
    onChangeInterviewerForRequestedInterviewClose:
      onChangeInterviewerForRequestedInterviewClose,
    changeInterviewerForRequestedInterviewList:
      changeInterviewerForRequestedInterviewList,
    refreshInterviewerAvailability: refreshInterviewerAvailability,
    setSelectedInterview: setSelectedInterview,
  };
};

export default interviewActions;

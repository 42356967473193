module.exports = {
  SUCCESS_IN_APP_MESSAGE: 'Your changes were saved.',
  JobNoFirstStageNotification:
    'To invite candidates, configure the first interview stage.',
  IntegrationDifferentEmailThanHirehacEmail:
    'To proceed, please use the email address associated with your invitation; a different one cannot be used.',
  CompanyUserRoleChange: (userName, role) =>
    `${userName}'s user role has been changed to ${role}`,
  CompanyUserHasScheduleOrRequestedInterviewCannotBeDeleted: `The user is assigned to at least one Scheduled or Requested interview. Please check the ‘All Interviews’ tab to locate this interview and take appropriate action before deleting the user.`,
  CompanyUserOnlyInterviewerForStageCannotBeDeleted: (jobTitle, stageTitle) =>
    `This user is the sole interviewer for the ${stageTitle} stage of ${jobTitle}. Please find a temporary or permanent replacement before deleting the user.`,
  CompanyUserSetupUnfinished: (completedSteps, totalNumberOfSteps) =>
    `Please finish setting up your account. You have completed ${completedSteps}/${totalNumberOfSteps} steps.`,
  SelectedTimeAreOverlapping:
    'You selected times that overlap on the same day.',
  CandidateCannotScheduleCompanyIsInactive:
    'You are not allowed to perform this operation. Please contact the company for more information.',
  FailurePasswordChangeNewAndConfirmPasswordDoNotMatch:
    'The new password and confirmation password do not match. Please ensure they match and try again.',
  SuccessPasswordChange: 'Your password was changed successfully.',
  ResetPasswordEmailSent:
    'A message with instructions to reset your password has been sent to your email. Please check your inbox.',
  DragDropSuccess: (candidateName, stageName) =>
    `${candidateName} was successfully added to ${stageName} and a scheduling request was sent to their email.`,
  CannotMoveCandidateToStageNotConfigured:
    'You cannot move a candidate to a stage that is not fully configured.',
  CannotMoveCandidateToReviewStageNotAssociatedWithCurrent:
    'Candidates can only be moved to review stages belonging to their current stage.',
  CannotDeleteStageWithActiveCandidates:
    'You cannot delete a stage with active candidates.',
  CannotArchiveJobActiveHiringProcess:
    'You cannot archive a job with active candidates.',
  DeleteReviewStageBeforeRealStage:
    'You cannot delete a stage when it has a review stage. You must delete the review stage first.',
  LoginPasswordFailure:
    'Login failed. Please check your password and try again',
  CompanyUserInvitationSent: (email) =>
    `An invitation has been sent to ${email}.`,
  CandidateInPortalNoActiveJobs:
    'You currently do not have any active Jobs in Hirehac.',
  FileIsTooLarge: 'File is too large. Maximum size allowed is 3MB',
  CandidateAlreadyInJobHiringProcess:
    'The email you provided is already associated with a candidate for this position.',
  FailurePasswordChangeCurrentPasswordIncorrect:
    'The current password you entered is incorrect. Please check your current password and try again.',
  ResetPasswordEmailNotFound:
    'The email address you entered is not associated with any account.',
  CompanyUserInviteBlockedByExistingUser: (email) =>
    `A Hirehac user is already associated with ${email}.`,
  CompanyUserInviteBlockedByActiveInvite: (email) =>
    `An invite is already pending for ${email}.`,
  InterviewScheduledCalendarInviteSent:
    'Your interview is scheduled! A calendar invitation has been sent to your email. You can <strong>join interview</strong> via the Hirehac Agenda or directly from your calendar.',
  CannotMoveScheduledCandidate: (candidateName, stageName) =>
    `${candidateName} is scheduled for ${stageName} and cannot be moved. Please cancel the interview first.`,
  CompanyUserInvitationDeleted: 'Invite has been deleted.',
};

import {
  IHiringProcess,
  INTERVIEW_STATUSES,
  INTERVIEW_TYPES,
} from '@er/data-hiring';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import * as moment from 'moment';
import React, { FunctionComponent, useCallback, useState } from 'react';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
  })
);
export interface ICandidateJobListItemProps {
  hiringProcess: IHiringProcess;
  onWithdraw?: (hiringProcessId: string) => any;
  onReschedule?: (hiringProcessId: string) => any;
  onScheduleOpen?: (hiringProcessId: string) => any;
  onViewAgenda?: (hiringProcess: IHiringProcess) => any;
}

const ConfirmDialog = ({ onClose, onConfirm, title, text }) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          No
        </Button>
        <Button onClick={onConfirm} color="secondary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CandidateJobListItem: FunctionComponent<ICandidateJobListItemProps> =
  ({
    hiringProcess,
    onWithdraw,
    onReschedule,
    onScheduleOpen,
    onViewAgenda,
  }) => {
    const classes = useStyles();
    const [withdrawConfirmation, setWithdrawConfirmation] = useState(false);
    const { job = null } = hiringProcess;

    let currentStage = null;
    for (const val of job.stages) {
      if (val._id == hiringProcess.currentStage) {
        currentStage = val;
      }
    }

    let currentHiringProcessStage = null;
    for (const val of hiringProcess.stages) {
      if (val.stage == hiringProcess.currentStage) {
        currentHiringProcessStage = val;
      }
    }

    const dialogClose = () => {
      setWithdrawConfirmation(false);
    };

    const handleWithdrawClick = () => setWithdrawConfirmation(true);

    const confirmWithdraw = useCallback(() => {
      dialogClose();
      if (!onWithdraw) return;
      onWithdraw(hiringProcess._id);
    }, [hiringProcess, onWithdraw, dialogClose]);

    const confirmReschedule = useCallback(() => {
      dialogClose();
      if (!onReschedule) return;
      onReschedule(hiringProcess._id);
    }, [hiringProcess, onReschedule, dialogClose]);

    const hasEventSettings =
      job &&
      currentStage &&
      currentStage.interviewSegment &&
      currentStage.interviewSegment[0];

    const handleScheduleClicked = useCallback(() => {
      if (!onScheduleOpen) return;
      onScheduleOpen(hiringProcess._id);
    }, [hiringProcess, onScheduleOpen]);

    const handleViewAgenda = useCallback(() => {
      if (!onViewAgenda) return;

      onViewAgenda(hiringProcess);
    }, [hiringProcess, onViewAgenda]);

    if (!job) {
      return null;
    }

    const mustDisplayScheduleButton = (hiringProcess) => {
      return !hiringProcessHasScheduledInterviews(hiringProcess);
    };

    const mustDisplayViewAgendaButton = (hiringProcess) => {
      return hiringProcessHasScheduledInterviews(hiringProcess);
    };

    const mustDisplayRescheduleButton = (hiringProcess) => {
      return hiringProcessHasScheduledInterviews(hiringProcess);
    };

    const displayAdditionalDetails = () => {
      if (currentStage?.type === INTERVIEW_TYPES.OFFER) {
        return (
          <Typography variant="body1">
            You’ve completed the interview process.
          </Typography>
        );
      }

      if (!hasEventSettings) {
        return (
          <Typography variant="body1">
            Awaiting interview configuration
          </Typography>
        );
      }
    };

    const hiringProcessHasScheduledInterviews = (hiringProcess) => {
      let hiringProcessStage = hiringProcess.stages.find(
        (stage) => stage.stage === hiringProcess.currentStage
      );

      const scheduledInterview = hiringProcessStage?.interviews?.find(
        (interview) => interview.status != INTERVIEW_STATUSES.REQUESTED
      );

      return scheduledInterview;
    };

    return (
      <Card
        style={{
          minHeight: '194px',
          paddingTop: '16x',
          display: 'flex',
        }}
      >
        {withdrawConfirmation && (
          <ConfirmDialog
            onClose={dialogClose}
            onConfirm={confirmWithdraw}
            title="Withdraw confirmation"
            text={`Are you sure you want to withdraw your candidacy from ${job.title} ? If you have any scheduled interviews, they will be cancelled and removed from your calendar. `}
          />
        )}
        <CardContent
          style={{ flex: '1', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              marginTop: '8px',
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" style={{ fontWeight: 600 }}>
                {job.title}
              </Typography>
              {!!job.company?.name && (
                <Typography variant="body1">{job.company?.name}</Typography>
              )}
              {hasEventSettings && (
                <Box display="inline">
                  <Typography display="inline" style={{ fontWeight: 600 }}>
                    Stage Type:{' '}
                  </Typography>
                  <Typography display="inline">{currentStage.type}</Typography>
                </Box>
              )}
              {displayAdditionalDetails()}

              {!!hiringProcess.interview && (
                <Typography variant="body1">
                  <u>Date & Time:</u>{' '}
                  {moment
                    .utc(hiringProcess.interview.startTime)
                    .local()
                    .format('zz YYYY-MM-DD: h:mm a')}{' '}
                  -{' '}
                  {moment
                    .utc(hiringProcess.interview.endTime)
                    .local()
                    .format('h:mm a')}
                </Typography>
              )}
            </Box>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
              paddingRight: '36px',
            }}
          >
            <Box display="flex" flexDirection="column">
              {mustDisplayScheduleButton(hiringProcess) && (
                <Button
                  disabled={!hasEventSettings}
                  variant="contained"
                  color="primary"
                  onClick={handleScheduleClicked}
                  style={{ minWidth: '150px', marginBottom: '16px' }}
                >
                  Schedule
                </Button>
              )}
              {mustDisplayViewAgendaButton(hiringProcess) && (
                <Button
                  disabled={!hasEventSettings}
                  variant="contained"
                  color="primary"
                  onClick={handleViewAgenda}
                  style={{ minWidth: '150px', marginBottom: '16px' }}
                >
                  View Agenda
                </Button>
              )}
              {mustDisplayRescheduleButton(hiringProcess) && (
                <Button
                  disabled={!hasEventSettings}
                  onClick={handleScheduleClicked}
                  style={{
                    minWidth: '150px',
                    marginBottom: '16px',
                    color: '#FFC74C',
                  }}
                >
                  Reschedule
                </Button>
              )}
              <Button
                onClick={handleWithdrawClick}
                variant="text"
                style={{
                  minWidth: '150px',
                  color: '#FF7262',
                  opacity: 0.75,
                }}
              >
                Withdraw
              </Button>
            </Box>
          </div>
        </CardContent>
      </Card>
    );
  };

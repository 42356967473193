import mongoose, { model, Schema } from 'mongoose';
import { IResume, IResumeModel } from './resume.interface';

export const ResumeSchema: Schema<IResume, IResumeModel> = new Schema(
  {
    owner: { type: Schema.Types.ObjectId, ref: 'User', required: true },
    file: { type: Schema.Types.ObjectId, ref: 'File', required: true },
    isPrimary: Boolean,
  },
  { timestamps: true }
);

export const ResumeModel: IResumeModel = (mongoose.models?.Resume ||
  model('Resume', ResumeSchema)) as IResumeModel;

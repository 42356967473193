import React, { Component, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes, { InferProps } from 'prop-types';
import validate from 'validate.js';
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import Link from 'next/link';

type FormData = {
  email: string;
  password: string;
  remember: boolean;
};

export type LoginFormProps = InferProps<typeof LoginForm.propTypes>;

const useStyles = makeStyles((theme) => ({
  signInButton: {
    marginTop: '16px',
  },

  buttonProgress: {
    position: 'relative',
    left: 20,
  },
}));
export function LoginForm(props: LoginFormProps) {
  const { onSubmit, processing, erLogo, resetPasswordLink, remember } = props;

  const classes = useStyles();

  const [disabled, setDisabled] = useState(true);

  const { handleSubmit, formState, control, errors } = useForm<FormData>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  });

  const onFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data);
  });

  useEffect(() => {
    setDisabled(
      processing ||
        !formState.isDirty ||
        (formState.isDirty && !formState.isValid)
    );
  }, [formState, processing]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <form onSubmit={onFormSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: 'email is required',
                  validate: (value) => {
                    const err = validate.single(value, {
                      presence: true,
                      email: true,
                    });
                    if (!err) return;
                    return err[0];
                  },
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="Work email"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: 'password is required',
                  minLength: {
                    value: 8,
                    message: 'password length should be at least 8 characters',
                  },
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    label="Password"
                    type="password"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof FormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Box textAlign="center">
                <Button
                  className={classes.signInButton}
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={
                    processing ||
                    !formState.isDirty ||
                    (formState.isDirty && !formState.isValid)
                  }
                >
                  Sign In
                  {processing && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Box>
            </Grid>

            <Grid item>
              <Typography align="center">Forgot password?</Typography>
              <Typography align="center" color="primary">
                <Link href="/reset-password">
                  <a style={{ textDecoration: 'none', color: 'inherit' }}>
                    Reset password
                  </a>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resetPasswordLink: PropTypes.node,

  erLogo: PropTypes.bool,
  processing: PropTypes.bool,
  remember: PropTypes.bool,
};

LoginForm.defaultProps = {
  processing: false,
  remember: true,
  erLogo: false,
};

export default LoginForm;

import { IEventAvailabilityOption, IHiringProcess } from '@er/data-hiring';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AvailabilityCalendar } from '@er/ui-lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

export interface IScheduleInterviewProps {
  availability?: Partial<IEventAvailabilityOption>[];
  hiringProcess: IHiringProcess;
  open?: boolean;
  fullScreen?: boolean;
  startDate?: Date;
  onClose: () => any;
  onSchedule?: (av: Partial<IEventAvailabilityOption>) => any;
}

export const ScheduleInterview: FunctionComponent<IScheduleInterviewProps> = ({
  hiringProcess,
  availability = [],
  startDate,
  open = true,
  fullScreen = true,
  onClose,
  onSchedule,
}) => {
  const classes = useStyles();

  startDate = startDate || new Date();

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Schedule for{' '}
            {hiringProcess.job.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AvailabilityCalendar
          startDate={startDate}
          availability={availability}
          onSchedule={onSchedule}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { IUser, USER_TYPES } from '@er/data-hiring';
import {
  IconButton,
  Snackbar,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { FunctionComponent, useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';

import { mutate } from 'swr';
import { Alert } from '@material-ui/lab';
import { DeleteCompanyUserConfirmation } from 'apps/hiring/components';
import CreateIcon from '@material-ui/icons/Create';
import ChangeUserRole from './change-user-role';
import SnackBarLayout from '../shared/snackbar-layout';
import SnackBarErrorLayout from '../shared/snackbar-error-layout';

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export interface IDisplayCompanyMembersProps {
  currentUser: IUser;
  companyId: string;
  usersForCompany: IUser[];
}

const labels = require('@er/ui-lib/lib/common/shared/labels.js');

export const DisplayCompanyMembers: FunctionComponent<IDisplayCompanyMembersProps> =
  ({ currentUser, usersForCompany, companyId }) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [successNotificationOpen, setSuccessNotificationOpen] =
      useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [confirmDeleteUserOpen, setConfirmDeleteUserOpen] =
      useState<Boolean>(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [changeRoleOpen, setChangeRoleOpen] = useState<Boolean>(false);

    const onUserRoleChangeConfirm = async (user, selectedRole) => {
      await fetch(`/api/users/${user?._id}/change-role`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userRole: selectedRole,
        }),
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.status === 'success') {
            setSuccessNotificationOpen(true);

            const userName =
              selectedUser?.firstName + ' ' + selectedUser?.lastName;

            setSuccessMessage(
              labels.CompanyUserRoleChange(
                userName,
                displayTextFromUserType(selectedRole)
              )
            );
          }
        });

      onCloseChangeUserRole();

      return mutate('/api/users/all-company-users/' + companyId);
    };

    const displayDeleteCompanyUserButton = (user) => {
      if (user && user?.type === USER_TYPES.COMPANYSPACEOWNER) {
        // It was implemented like this to make sure the size of the rows is the same for SpaceOwners and other user roles
        return (
          <IconButton style={{ pointerEvents: 'none' }}>
            <DeleteIcon style={{ visibility: 'hidden' }} />
          </IconButton>
        );
      }

      return (
        <IconButton onClick={() => handleDeleteClick(user)}>
          <DeleteIcon />
        </IconButton>
      );
    };

    const handleDeleteClick = async (userToDelete) => {
      await fetch(`/api/users/${userToDelete._id}/can-delete`, {
        method: 'GET',
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.status == 'error') {
            setErrorMessage(response?.errorMessage);
            setPopupOpen(true);
          } else {
            setConfirmDeleteUserOpen(true);
            setSelectedUser(userToDelete);
          }
        });
    };

    const handleChangeRoleClick = async (userToDelete) => {
      setChangeRoleOpen(true);
      setSelectedUser(userToDelete);
    };

    const onUserDeleteClick = async () => {
      await fetch(`/api/users/${selectedUser._id}`, {
        method: 'DELETE',
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.status == 'Error') {
            setErrorMessage(response?.errorMessage);
            setPopupOpen(true);
          } else {
            mutate('/api/users/all-company-users/' + selectedUser.company);
            return response.data;
          }
        });

      onDeleteUserClose();
    };

    const handlePopupClose = () => {
      setPopupOpen(false);
    };

    const onDeleteUserClose = () => {
      setConfirmDeleteUserOpen(false);
      setSelectedUser(null);
    };

    const displayEditIcon = (user) => {
      if (
        currentUser?.type === USER_TYPES.COMPANYSPACEOWNER &&
        user?.type !== USER_TYPES.COMPANYSPACEOWNER
      ) {
        return (
          <IconButton onClick={() => handleChangeRoleClick(user)}>
            <CreateIcon fontSize="small" color="primary" />
          </IconButton>
        );
      }
    };

    const onCloseChangeUserRole = () => {
      setChangeRoleOpen(false);
      setSelectedUser(null);
    };

    const displayTextFromUserType = (type) => {
      if (type === USER_TYPES.ADMIN) {
        return 'Admin';
      }

      if (type === USER_TYPES.COMPANYSPACEOWNER) {
        return 'Space Owner';
      }

      if (type === USER_TYPES.MEMBER) {
        return 'Member';
      }
    };

    const handleClose = (
      event: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setSuccessNotificationOpen(false);
    };

    return (
      <TableBody>
        <SnackBarLayout
          open={successNotificationOpen}
          handleClose={handleClose}
          message={successMessage}
        ></SnackBarLayout>
        <SnackBarErrorLayout
          open={popupOpen}
          handleClose={handlePopupClose}
          errorMessage={errorMessage}
        ></SnackBarErrorLayout>
        {confirmDeleteUserOpen && (
          <DeleteCompanyUserConfirmation
            onConfirm={onUserDeleteClick}
            onClose={onDeleteUserClose}
          />
        )}
        {changeRoleOpen && (
          <ChangeUserRole
            selectedUser={selectedUser}
            onConfirm={onUserRoleChangeConfirm}
            onClose={onCloseChangeUserRole}
          />
        )}
        {usersForCompany?.map((user) => (
          <TableRow key={user._id}>
            <TableCell component="th" scope="row">
              {user?.firstName + ' ' + user?.lastName}
            </TableCell>
            <TableCell component="th" scope="row" style={{ color: '#707683' }}>
              {user?.email}
            </TableCell>
            <TableCell component="th" scope="row" style={{ color: '#707683' }}>
              {user?.jobTitle}
            </TableCell>
            <TableCell component="th" scope="row" style={{ color: '#707683' }}>
              {user?.timeZone}
            </TableCell>
            <TableCell component="th" scope="row" style={{ color: '#707683' }}>
              {displayTextFromUserType(user?.type)}
              {displayEditIcon(user)}
            </TableCell>
            <TableCell component="th" scope="row">
              {displayDeleteCompanyUserButton(user)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactTimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ITimeRange } from '../availability-editor';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .react-timerange-picker': {
      '&, & *, & *:before, & *:after': {
        boxSizing: 'border-box',
      },
      '&--disabled': {
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },

    '& .react-timerange-picker__wrapper': {
      display: 'flex',
      flexGrow: '1',
      flexShrink: '0',
      alignItems: 'center',
    },
    '& .react-timerange-picker__inputGroup': {
      minWidth: 'calc((4px * 3) + 0.54em * 6 + 0.217em * 2)',
      height: '100%',
      flexGrow: '1',
      padding: '0 10px',
      boxSizing: 'content-box',
      border: '1px solid rgba(0, 0, 0, 0.4)',
      borderRadius: '5px',
      '& input:focus': {
        outline: 'none',
      },
      '&__amPm': {
        font: 'inherit',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        '&::-ms-expand': {
          display: 'none',
        },
      },

      '&__divider': {
        padding: '1px 0',
        whiteSpace: 'pre',
      },
      '&__input': {
        minWidth: '1.5em',
        height: '100%',
        position: 'relative',
        padding: '0 1px',
        border: '0',
        background: 'none',
        font: 'inherit',
        boxSizing: 'content-box',
        '-moz-appearance': 'textfield',

        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: '0',
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: '0',
        },
        '&:invalid': {
          background: '#f44335',
          color: '#ffffff',
        },

        '&--hasLeadingZero': {
          marginLeft: '-0.54em',
          paddingLeft: 'calc(1px + 0.54em)',
        },
      },
    },

    '& .react-timerange-picker__range-divider': {
      display: 'inline-block',
      padding: '0 10px',
    },

    '& .react-timerange-picker__button': {
      border: '0',
      background: 'transparent',
      padding: '4px 6px',
      '&:enabled': {
        cursor: 'pointer',
      },
      '& .react-timerange-picker__button__icon': {
        stroke: 'rgba(0, 0, 0, 0.4)',
      },
      '&:enabled:hover .react-timerange-picker__button__icon, &:enabled:focus .react-timerange-picker__button__icon':
        {
          stroke: '#71a7a6',
        },

      '&:disabled .react-timerange-picker__button__icon': {
        stroke: '#6d6d6d',
      },
      '& svg': {
        display: 'inherit',
      },
    },
    '& .react-timerange-picker__clock': {
      width: '200px',
      height: '200px',
      maxWidth: '100vw',
      padding: '25px',
      backgroundColor: 'white',
      position: 'absolute',
      top: '100%',
      left: '0',
      zIndex: '1',
      '&__closed': {
        display: 'none',
      },
    },
  },
}));
export interface ITimeRangePickerProps {
  startTime: string;
  endTime: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (timeRange: ITimeRange) => any;
}

export const TimeRangePicker: FunctionComponent<ITimeRangePickerProps> = ({
  startTime,
  endTime,
  required,
  disabled,
  className,
  onChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState([startTime, endTime]);

  const handleTimeRangeChange = (timeRange: string[]) => {
    setValue(timeRange);

    if (onChange && timeRange[0] && timeRange[1]) {
      onChange({
        startTime: timeRange[0],
        endTime: timeRange[1],
      });
    }
  };

  return (
    <div>
      <div className={classes.root} style={{ display: 'block' }}>
        <ReactTimeRangePicker
          className={className}
          value={value}
          required={required}
          rangeDivider=" - "
          format="hh:mm a"
          disabled={disabled}
          clearIcon={null}
          onChange={handleTimeRangeChange}
          disableClock
        />
      </div>
    </div>
  );
};

import {
  IInterview,
  IInterviewSegment,
  INTERVIEW_STATUSES,
  IUser,
  REQUEST_OPERATION_TYPES,
  STAGE_INTERVIEWER_ROTATION,
  USER_TYPES,
} from '@er/data-hiring';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { displayHourAndMinutesInUserTimezone } from 'apps/hiring/pages/api/utils/utils';
import { isJobOwner } from './interviews-list';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
  titleColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

export interface IAdminAgendaEditorConfirmRequestRescheduleProps {
  interview: IInterview;
  user: IUser;
  interviewSegment: IInterviewSegment;
  onClose: () => any;
  refreshAgendaEditorData: () => any;
}

export const AdminAgendaEditorConfirmRequestReschedule: FunctionComponent<IAdminAgendaEditorConfirmRequestRescheduleProps> =
  ({ interview, interviewSegment, user, onClose, refreshAgendaEditorData }) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const executeRequestReschedule = async () => {
      setLoading(true);
      try {
        await fetch(`/api/interviews/request-reschedule/${interview._id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            requestRescheduleType: selectedCard,
          }),
          method: 'POST',
        }).then((r) => r.json());
        refreshAgendaEditorData();
        onClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const interviewSegmentHasSingleInterviewer = () => {
      return interviewSegment && interviewSegment?.interviewers?.length === 1;
    };

    const userAdminOrJobOwner = () => {
      return (
        isJobOwner(interview, user) ||
        user.type === USER_TYPES.ADMIN ||
        user.type === USER_TYPES.COMPANYSPACEOWNER
      );
    };

    let selectedCardValue =
      REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_ROTATION_POLICY;
    const stageStructure = interview?.job.stages?.find(
      (stage) => stage._id === interview.stage
    );

    if (
      stageStructure &&
      stageStructure.interviewerRotation &&
      userAdminOrJobOwner() &&
      !interviewSegmentHasSingleInterviewer()
    ) {
      selectedCardValue =
        stageStructure.interviewerRotation ===
        STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY
          ? REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_ANY_INTERVIEWER
          : REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_CURRENT_INTERVIEWER;
    }

    const [selectedCard, setSelectedCard] = useState(selectedCardValue);

    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(
      selectedCard == null
    );

    const handleCardClick = (card) => {
      setSelectedCard(card);
      setConfirmButtonDisabled(false);
    };

    const displayMaximizeAvailabilityRequestRescheduleCardForMember = () => {
      return (
        <div>
          <Typography variant="body1" className={classes.textColorAndOpacity}>
            Upon confirming your the event scheduled with{' '}
            {interview?.candidate?.firstName} {interview?.candidate?.lastName}{' '}
            on{' '}
            {displayHourAndMinutesInUserTimezone(
              interview?.startTime,
              user
            ).format('MMMM-DD-YYYY')}{' '}
            at{' '}
            {displayHourAndMinutesInUserTimezone(
              interview?.startTime,
              user
            ).format('h:mm a')}{' '}
            will be cancelled. The candidate will then be prompted to reschedule
            with you or a team member.
          </Typography>
        </div>
      );
    };

    const displayRequestRescheduleCardForScheduledInterviewForAdmins = () => {
      return (
        <Box>
          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px' }}
            display="inline"
          >
            After confirming, the event scheduled on{' '}
          </Typography>
          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px', fontWeight: 600 }}
            display="inline"
          >
            {displayHourAndMinutesInUserTimezone(
              interview?.startTime,
              user
            ).format('MMMM-DD-YYYY')}{' '}
          </Typography>
          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px' }}
            display="inline"
          >
            at{' '}
          </Typography>
          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px', fontWeight: 600 }}
            display="inline"
          >
            {displayHourAndMinutesInUserTimezone(
              interview?.startTime,
              user
            ).format('h:mm a')}{' '}
          </Typography>
          <Typography
            className={classes.textColorAndOpacity}
            style={{ fontSize: '15px' }}
            display="inline"
          >
            will be canceled. You can then choose to keep the same interviewer
            or opt to schedule with any of the other interviewers for that
            stage. Selecting 'any interviewer' streamlines the scheduling
            process for the candidate.
          </Typography>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '32px',
            }}
          >
            <Card
              elevation={2}
              onClick={() =>
                handleCardClick(
                  REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_ANY_INTERVIEWER
                )
              }
              style={{
                maxWidth: '180px',
                flex: '1',
                marginRight: '16px',
                backgroundColor:
                  selectedCard ===
                  REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_ANY_INTERVIEWER
                    ? '#EEE'
                    : '#FFF',
              }}
            >
              <CardContent style={{ maxWidth: '180px' }}>
                <div style={{ pointerEvents: 'none' }}>
                  <IconButton>
                    <GroupIcon />
                  </IconButton>
                  <Typography
                    variant="body1"
                    className={classes.textColorAndOpacity}
                  >
                    Any interviewer
                  </Typography>
                </div>
              </CardContent>
            </Card>
            <Card
              elevation={2}
              onClick={() =>
                handleCardClick(
                  REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_CURRENT_INTERVIEWER
                )
              }
              style={{
                maxWidth: '180px',
                flex: '1',
                backgroundColor:
                  selectedCard ===
                  REQUEST_OPERATION_TYPES.REQUEST_RESCHEDULE_CURRENT_INTERVIEWER
                    ? '#EEE'
                    : '#FFF',
              }}
            >
              <CardContent style={{ maxWidth: '180px' }}>
                <div style={{ pointerEvents: 'none' }}>
                  <IconButton>
                    <PersonIcon />
                  </IconButton>
                </div>
                <Typography
                  variant="body1"
                  className={classes.textColorAndOpacity}
                >
                  {getCurrentInterviewerName(interview)}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Box>
      );
    };

    function getCurrentInterviewerName(interview) {
      if (
        interview &&
        interview.interviewers &&
        interview.interviewers.length > 0
      ) {
        return (
          interview.interviewers[0]?.firstName +
          ' ' +
          interview.interviewers[0]?.lastName
        );
      }

      return 'Current interviewer';
    }

    const displayRequestRescheduleCardForCanceledInterview = () => {
      return (
        <div>
          <Typography variant="body1" className={classes.textColorAndOpacity}>
            Candidate will be asked to reschedule this interview
          </Typography>
        </div>
      );
    };

    const displayRequestRescheduleCardForSchduledInterview = () => {
      if (interviewSegmentHasSingleInterviewer()) {
        const interviewer = interviewSegment.interviewers[0];

        return (
          <div>
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              display="inline"
            >
              Upon confirming, the event scheduled on{' '}
            </Typography>
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              style={{ fontWeight: 600 }}
              display="inline"
            >
              {displayHourAndMinutesInUserTimezone(
                interview?.startTime,
                user
              ).format('MMMM-DD-YYYY')}{' '}
            </Typography>
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              display="inline"
            >
              at{' '}
            </Typography>
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              style={{ fontWeight: 600 }}
              display="inline"
            >
              {displayHourAndMinutesInUserTimezone(
                interview?.startTime,
                user
              ).format('h:mm a')}{' '}
            </Typography>
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              display="inline"
            >
              will be canceled. The candidate will then be prompted to
              reschedule with{' '}
              {interviewer?.firstName + ' ' + interviewer?.lastName}.
            </Typography>
          </div>
        );
      } else if (userAdminOrJobOwner()) {
        return displayRequestRescheduleCardForScheduledInterviewForAdmins();
      } else {
        // Currently if the user is not JobOwner, Admin or CompanySpaceOwner, it means that user is a member
        // and they can only request reschedule based on the interview roation policy on the stage
        if (
          stageStructure &&
          (stageStructure.interviewerRotation ===
            STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION ||
            interviewWithInterviewerManuallyAssigned())
        ) {
          return displayRequestRescheduleWithCurrentInterviewerCardForMember();
        } else {
          return displayMaximizeAvailabilityRequestRescheduleCardForMember();
        }
      }
    };

    const interviewWithInterviewerManuallyAssigned = () => {
      return interview && interview?.interviewerManuallyAssigned;
    };

    const displayRequestRescheduleWithCurrentInterviewerCardForMember = () => {
      return (
        <div>
          <Typography
            variant="body1"
            className={classes.textColorAndOpacity}
            display="inline"
          >
            Upon confirming, the event scheduled on{' '}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textColorAndOpacity}
            style={{ fontWeight: 600 }}
            display="inline"
          >
            {displayHourAndMinutesInUserTimezone(
              interview?.startTime,
              user
            ).format('MMMM-DD-YYYY')}{' '}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textColorAndOpacity}
            display="inline"
          >
            at{' '}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textColorAndOpacity}
            style={{ fontWeight: 600 }}
            display="inline"
          >
            {displayHourAndMinutesInUserTimezone(
              interview?.startTime,
              user
            ).format('h:mm a')}{' '}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textColorAndOpacity}
            display="inline"
          >
            will be cancelled and the candidate will be asked to reschdule with
            you.
          </Typography>
        </div>
      );
    };

    const displayRequestRescheduleCard = () => {
      if (interview && interview.status === INTERVIEW_STATUSES.CANCELED) {
        return displayRequestRescheduleCardForCanceledInterview();
      } else if (
        interview &&
        interview.status === INTERVIEW_STATUSES.SCHEDULED
      ) {
        return displayRequestRescheduleCardForSchduledInterview();
      }
    };

    return (
      <Dialog
        // open={!!addStageOpen}
        open={true}
        // onClose={onClose}
        onClose={() => console.log('On close')}
        disableBackdropClick
        maxWidth="sm"
      >
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.titleColorAndOpacity}>
              <b>Request Reschedule?</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 600 }}
              className={classes.textColorAndOpacity}
            >
              {interviewSegment?.focusArea}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.textColorAndOpacity}
            >
              {interview?.candidate?.firstName +
                ' ' +
                interview?.candidate?.lastName}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {loading && <CustomCircularLoading />}
          <Box textAlign="center">
            {displayRequestRescheduleCard()}
            <br />
            <Button
              onClick={() => executeRequestReschedule()}
              style={{
                color: '#FFFFFF',
                backgroundColor: confirmButtonDisabled ? '' : '#ffad33',
                marginBottom: '32px',
                marginTop: '16px',
              }}
              variant="contained"
              disabled={confirmButtonDisabled}
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default AdminAgendaEditorConfirmRequestReschedule;

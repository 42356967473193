import { USER_TYPES } from '@er/data-hiring';
import { Tab, Tabs } from '@material-ui/core';
import NextLink from 'next/link';
import React from 'react';

export enum COMPANY_SETTINGS_TABS {
  COMPANYINFO = 0,
  MEMBERS = 1,
  INVITEMEMBERS = 2,
}

export function CompanySettingsTabs({
  user,
  tabId = COMPANY_SETTINGS_TABS.COMPANYINFO,
}) {
  if (
    user &&
    (user.type === USER_TYPES.ADMIN ||
      user.type === USER_TYPES.COMPANYSPACEOWNER)
  ) {
    return (
      <Tabs
        value={tabId}
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
      >
        <NextLink href={`/company-settings`} as={`/company-settings`} passHref>
          <Tab
            component="a"
            value={COMPANY_SETTINGS_TABS.COMPANYINFO}
            label="Company Info"
          />
        </NextLink>
        <NextLink
          href={`/company-settings/members`}
          as={`/company-settings/members`}
          passHref
        >
          <Tab
            component="a"
            label="Members"
            value={COMPANY_SETTINGS_TABS.MEMBERS}
          />
        </NextLink>
        <NextLink
          href={`/company-settings/invite-members`}
          as={`/company-settings/invite-members`}
          passHref
        >
          <Tab
            component="a"
            label="Invite members"
            value={COMPANY_SETTINGS_TABS.INVITEMEMBERS}
          />
        </NextLink>
      </Tabs>
    );
  }

  return (
    <Tabs
      value={tabId}
      indicatorColor="primary"
      textColor="primary"
      aria-label="disabled tabs example"
    >
      <NextLink href={`/company-settings`} as={`/company-settings`} passHref>
        <Tab
          component="a"
          value={COMPANY_SETTINGS_TABS.COMPANYINFO}
          label="Company Info"
        />
      </NextLink>
      <NextLink
        href={`/company-settings/members`}
        as={`/company-settings/members`}
        passHref
      >
        <Tab
          component="a"
          label="Members"
          value={COMPANY_SETTINGS_TABS.MEMBERS}
        />
      </NextLink>
    </Tabs>
  );
}

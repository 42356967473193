import { Calendar } from 'react-calendar';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import moment from 'moment';
import { IEventAvailabilityOption } from '@er/data-hiring';

/* eslint-disable-next-line */
export interface AvailabilityCalendarProps {
  availability: Partial<IEventAvailabilityOption>[];
  selectedDate?: Date;
  startDate: Date;
  endDate?: Date;
  onSchedule?: (av: Partial<IEventAvailabilityOption>) => any;
}

const useStyles = makeStyles((theme) => ({
  reactCalendar: {
    background: 'transparent',
    border: 'none',
    maxWidth: 300,
    width: 300,
    height: 350,
    maxHeight: 350,
  },
  calendarTile: {
    // border: '2px solid white',
    // borderRadius: '100%',
    // background: theme.palette.primary.light,
    // '& > abbr': {
    //   display: 'block',
    //   borderRadius: '200px',
    //   border: 'none',
    //   width: '50px',
    //   height: '50px',
    //   background: theme.palette.primary.light,
    // },
  },
}));

export const AvailabilityCalendar: FunctionComponent<AvailabilityCalendarProps> =
  ({ availability, startDate, selectedDate = null, endDate, onSchedule }) => {
    const classes = useStyles();
    const [date, setDate] = useState(selectedDate);
    const [selectedAvailability, setAvailability] = useState(null);
    const today = new Date();

    const isDateAvailable = useCallback(
      (date) => {
        return availability.find((av) =>
          moment(date).isSame(moment(av.startDateUTC), 'date')
        );
      },
      [availability]
    );

    const handleDateSelected = (date) => {
      setDate(date);
    };

    const handleAvailabilitySelected = (av) => {
      if (selectedAvailability === av) {
        return setAvailability(null);
      }

      setAvailability(av);
    };
    const handleSchedule = () => {
      if (!selectedAvailability || !onSchedule) return;

      return onSchedule(selectedAvailability);
    };

    if (date) {
      return (
        <div>
          Date selected {moment(date).format('YYYY-MM-DD')}{' '}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleDateSelected(null);
            }}
          >
            Cancel
          </Button>
          {!!selectedAvailability && (
            <div>
              Selected:{' '}
              {moment(selectedAvailability.startDateUTC).format('h:mm a')} -{' '}
              {moment(selectedAvailability.endDateUTC).format('h:mm a')}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSchedule}
              >
                Schedule
              </Button>
            </div>
          )}
          <ul>
            {availability
              .filter((av) => moment(av.startDateUTC).isSame(date, 'date'))
              .map((av) => (
                <li key={av.startDateUTC.getTime()}>
                  <Button
                    variant={
                      av === selectedAvailability ? 'contained' : 'outlined'
                    }
                    onClick={() => handleAvailabilitySelected(av)}
                    color="primary"
                  >
                    {moment(av.startDateUTC).format('h:mm a')} -{' '}
                    {moment(av.endDateUTC).format('h:mm a')}
                  </Button>
                </li>
              ))}
          </ul>
        </div>
      );
    }

    return (
      <Calendar
        className={classes.reactCalendar}
        tileClassName={classes.calendarTile}
        defaultActiveStartDate={startDate}
        minDate={startDate}
        maxDate={endDate}
        showNavigation={true}
        onChange={handleDateSelected}
        tileDisabled={({ date, view }) => {
          return view === 'month' && !isDateAvailable(date);
        }}
      />
    );
  };

AvailabilityCalendar.defaultProps = {};

export default AvailabilityCalendar;

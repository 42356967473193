import { AddJobFormData } from '@er/ui-lib';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import absoluteUrl from 'next-absolute-url';
import ShareIcon from '@material-ui/icons/Share';

import CloseIcon from '@material-ui/icons/Close';
import { IJob } from '@er/data-hiring';

export interface IShareLinkProps {
  onClose?: () => void;
  shareLinkOpen: Boolean;
  job: IJob;
}

const defaultValues: AddJobFormData = {
  title: '',
  description: '',
};

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.55,
  },
  titleColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },

  dialogContent: {
    minWidth: 700,
  },
}));

export const inviteUri = (token, inviteId) => {
  if (token) {
    const host = absoluteUrl().origin;
    return `${host}/invite/${inviteId}/${token}`;
  }

  return ``;
};

export const ShareLink: FunctionComponent<IShareLinkProps> = ({
  shareLinkOpen,
  job,
  onClose,
}) => {
  const [token, setToken] = useState<string>('');
  const [inviteId, setInviteId] = useState<string>('');
  const classes = useStyles();

  const { handleSubmit, formState, control, errors, reset } =
    useForm<AddJobFormData>({
      defaultValues,
    });

  const handleDialogClose = () => {
    reset();
    setToken('');
    setInviteId('');
    if (onClose) {
      onClose();
    }
  };

  const generateInviteToJob = async () => {
    const invite = await fetch(`${origin}/api/invites`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type: 'candidate', status: 'active', job: job }),
      method: 'POST',
    })
      .then((r) => r.json())
      .then((r) => r.data);

    setToken(invite.token);
    setInviteId(invite._id);
  };

  return (
    <Dialog open={!!shareLinkOpen} disableBackdropClick maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <ShareIcon
            fontSize="large"
            style={{ color: '#0D293A', opacity: 0.85 }}
          />
          <Box ml={1}>
            {' '}
            <Typography variant="h6" className={classes.titleColorAndOpacity}>
              Job Invite
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.titleColorAndOpacity}
            >
              {job?.title}
            </Typography>
          </Box>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          style={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleDialogClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '24px',
              marginTop: '24px',
            }}
          >
            <Box
              textAlign="center"
              style={{
                backgroundColor: 'rgba(217, 217, 217, 0.25)',
                padding: '8px 16px',
                borderRadius: '4px',
                maxWidth: '300px',
                width: '100%',
                height: '40px',
              }}
            >
              <Typography
                variant="body1"
                className={classes.textColorAndOpacity}
              >
                {token}
              </Typography>
            </Box>
            <CopyToClipboard text={inviteUri(token, inviteId)}>
              <IconButton>
                <FileCopyIcon />
              </IconButton>
            </CopyToClipboard>
            <br />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              style={{
                maxWidth: '200px',
                marginBottom: '15px',
              }}
              size="medium"
              type="submit"
              color="primary"
              onClick={generateInviteToJob}
            >
              Create new
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

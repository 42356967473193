import { IHiringProcess, IStage } from '@er/data-hiring';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export interface ICandidateConfirmDispositionProps {
  hiringProcess: IHiringProcess;
  onClose: () => any;
  onSubmit: (
    hiringProcessId: string,
    sendCandidateNotificationChecked: boolean
  ) => any;
  stage: IStage;
}

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
  titleColorAndOpacity: {
    color: '#0D293A',
    opacity: 0.85,
  },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const CandidateConfirmDisposition: FunctionComponent<ICandidateConfirmDispositionProps> =
  ({ hiringProcess, stage, onClose, onSubmit }) => {
    const classes = useStyles();

    const [
      sendCandidateNotificationChecked,
      setSendCandidateNotificationChecked,
    ] = useState(true);

    const handleNotificationOptionChange = (e) => {
      setSendCandidateNotificationChecked(e.target.checked);
    };

    return (
      <Dialog open={true} disableBackdropClick maxWidth="sm">
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.titleColorAndOpacity}>
              <strong>Confirm Disposition</strong>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              className={classes.textColorAndOpacity}
              style={{
                fontWeight: 600,
                marginBottom: '-8px',
              }}
            >
              {stage?.title}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.textColorAndOpacity}
            >
              {hiringProcess?.candidate?.firstName +
                ' ' +
                hiringProcess?.candidate?.lastName}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              style={{ fontSize: '15px' }}
            >
              After confirming, the candidate will be removed from the
              'Interview Board' and placed in 'Dispositioned'. Additionally,
              interviews in ‘Requested' or 'Canceled' status associated with the
              candidate for this stage will be deleted.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  color="primary"
                  checked={sendCandidateNotificationChecked}
                  onChange={handleNotificationOptionChange}
                />
              }
              style={{
                marginTop: '15px',
              }}
              label={
                <Typography
                  className={classes.textColorAndOpacity}
                  style={{ fontSize: '15px' }}
                >
                  Send disposition notification
                </Typography>
              }
            />
            <br />
            <Button
              onClick={() =>
                onSubmit(hiringProcess._id, sendCandidateNotificationChecked)
              }
              style={{
                color: '#FFFFFF',
                backgroundColor: '#FA595F',
                opacity: 0.85,
                marginTop: '25px',
                marginBottom: '15px',
              }}
              variant="contained"
            >
              CONFIRM
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default CandidateConfirmDisposition;

import mongoose, { model, Schema } from 'mongoose';
import { ICompany, ICompanyModel } from './company.interface';
import { COMPANY_STATUSES } from './company.constants';

export const CompanySchema: Schema<ICompany, ICompanyModel> = new Schema(
  {
    name: { type: String, required: true },
    logo: { type: String },
    description: { type: String },
    website: { type: String },
    status: {
      type: String,
      enum: Object.values(COMPANY_STATUSES),
      default: COMPANY_STATUSES.ACTIVE,
      required: true,
    },
  },
  { timestamps: true }
);

export const CompanyModel: ICompanyModel = (mongoose.models?.Company ||
  model('Company', CompanySchema)) as ICompanyModel;

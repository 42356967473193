import { IUser } from '@er/data-hiring';

import {
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Button,
  TextField,
  Theme,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import React, { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SelectTimezone } from '@er/ui-lib';
import validate from 'validate.js';
import { FileDropzone, IUploadedFile, Stepper } from '@er/ui-lib';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import Dropzone from 'react-dropzone';

export type ICandidateProfileProps = {
  user: IUser;
  avatar?: string;
  onProfileUpdate: (data: IUserFormData) => any;
  onResumeDrop: (file: File) => any;
  onResumeDeleteBeforeUpload: () => any;
  resume?: IUploadedFile;
  processing?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
  })
);

export type IUserFormData = {
  email: string;
  firstName: string;
  lastName: string;
  timeZone: string;
};

export async function viewResumeButtonClick(user) {
  const resumeLink = await fetch(`/api/users/resume/${user?._id}`, {
    headers: {
      'Content-Type': 'application/json',
    },

    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res.link;
    });

  // Open the link in a new tab
  if (resumeLink) {
    window.open(resumeLink, '_blank', 'noopener,noreferrer');
  }
}

export const CandidateProfile: FunctionComponent<ICandidateProfileProps> = ({
  user,
  avatar,
  onProfileUpdate,
  onResumeDrop,
  onResumeDeleteBeforeUpload,
  resume,
  processing = false,
}) => {
  const classes = useStyles();
  const defaultValues: IUserFormData = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    timeZone: user.timeZone,
  };
  const { handleSubmit, formState, control, errors, reset } =
    useForm<IUserFormData>({
      defaultValues,
    });

  const onFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onProfileUpdate(data);
  });

  const handleFilesDrop = (files) => {
    onResumeDrop(files[0]);
  };

  const displayUploadResumeInfo = () => {
    if (user?.resumeFileName) {
      return (
        <Button
          color="primary"
          style={{
            textTransform: 'none',
            marginTop: '32px',
            fontWeight: 600,
            marginLeft: '4px',
            textAlign: 'center',
          }}
          onClick={() => viewResumeButtonClick(user)}
        >
          VIEW RESUME
        </Button>
      );
    }
  };

  return (
    <Card className={classes.root} style={{ paddingRight: 0 }}>
      <CardContent style={{ paddingRight: 0 }}>
        <Grid container style={{ marginTop: '16px', marginLeft: '8px' }}>
          <Grid item container direction="column" spacing={2} xs={7}>
            <Grid item>
              <Controller
                control={control}
                rules={{
                  required: 'First Name is required',
                }}
                name="firstName"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    fullWidth
                    onBlur={onBlur}
                    variant="outlined"
                    label="First Name"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof IUserFormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: 'Last Name is required',
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    fullWidth
                    onBlur={onBlur}
                    variant="outlined"
                    label="Last Name"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof IUserFormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: 'email is required',
                  validate: (value) => {
                    const err = validate.single(value, {
                      presence: true,
                      email: true,
                    });
                    if (!err) return;
                    return err[0];
                  },
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    InputProps={{
                      readOnly: true, // Set the readOnly prop to make it non-editable
                    }}
                    fullWidth
                    onBlur={onBlur}
                    variant="outlined"
                    label="Email"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof IUserFormData}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="timeZone"
                rules={{
                  required: 'Timezone is required',
                }}
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <SelectTimezone
                    onBlur={onBlur}
                    label="Select Timezone"
                    inputRef={ref}
                    value={value || undefined}
                    error={invalid}
                    style={{ minWidth: '300px' }}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof IUserFormData}
                      />
                    }
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={5}
            alignItems="center"
            justifyContent="center"
          >
            <FileDropzone
              files={resume ? [resume] : []}
              Icon={NoteAddOutlinedIcon}
              RejectIcon={CancelIcon}
              accept={[
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
              title="Upload New Resume"
              subtitle="pdf, doc or docx"
              maxFiles={1}
              hideOnFileLimit
              onDropAccepted={handleFilesDrop}
              onFileDelete={onResumeDeleteBeforeUpload}
            />
          </Grid>
          <Grid item container xs={7} direction="column" spacing={2}></Grid>
          <Grid
            item
            container
            xs={5}
            alignItems="center"
            justifyContent="center"
          >
            {displayUploadResumeInfo()}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          disabled={processing}
          onClick={(e) => onFormSubmit(e)}
          style={{ marginTop: '64px' }}
        >
          Update
        </Button>
      </CardContent>
    </Card>
  );
};

export default CandidateProfile;

import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core/styles';
import { defaultsDeep } from 'lodash';

export const MUI_THEME_JSON: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#71A7A6',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#C38958',
      contrastText: '#19191a',
    },
    error: {
      main: '#F44335',
    },
    background: {
      default: '#f8f8f8',
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
  },
};

export const MUI_THEME_DARK_JSON: ThemeOptions = defaultsDeep(
  {},
  {
    palette: {
      type: 'dark',
      background: {
        default: '#303030',
      },
    },
  },
  MUI_THEME_JSON
);

export const createMuiTheme = (options: ThemeOptions = {}) => {
  const theme = defaultsDeep({}, options, MUI_THEME_JSON);

  return responsiveFontSizes(createTheme(theme));
};

export const muiTheme = createMuiTheme();
export const muiThemeDark = createMuiTheme(MUI_THEME_DARK_JSON);

import React, { FunctionComponent, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  makeStyles,
  Input,
} from '@material-ui/core';
import { IInterviewer, IJob } from '@er/data-hiring';
import { INTERVIEW_TYPES } from 'libs/data-hiring/src/lib/interview/interview.constants';
import { Controller, useForm } from 'react-hook-form';
import { capitalize, cloneDeep, defaults, omit } from 'lodash';
import { ErrorMessage } from '@hookform/error-message';

export interface IJobEventSettingsModalProps {
  onClose?: () => void;
  onSubmit?: (jobId: string, data: EventSettingsForm) => void;
  job: IJob;
  interviewers: IInterviewer[];
}

export type EventSettingsForm = {
  type: INTERVIEW_TYPES;
  durationMinutes: number;
  meetingLink?: string;
  title: string;
  description?: string;
  interviewers: string[];
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export const JobEventSettingsModal: FunctionComponent<IJobEventSettingsModalProps> =
  ({ job, interviewers, onClose, onSubmit }) => {
    if (!job) {
      return <></>;
    }
    const classes = useStyles();

    const eventSettings = defaults<EventSettingsForm, IJob['eventSettings']>(
      {
        ...job.eventSettings,
        interviewers:
          job.eventSettings?.interviewers.map((i) => (i._id ? i._id : i)) || [],
      },
      {
        type: INTERVIEW_TYPES.ON_ON_ONE,
        durationMinutes: 60,
        title: '',
        description: '',
        meetingLink: '',
        interviewers: [] as string[],
      }
    );

    const { handleSubmit, formState, control, errors, reset } =
      useForm<EventSettingsForm>({
        defaultValues: eventSettings,
      });

    const handleFormSubmit = handleSubmit((data, e) => {
      e.preventDefault();
      onSubmit(job._id, data);
      handleDialogClose();
    });

    const handleDialogClose = () => {
      reset();
      if (onClose) {
        onClose();
      }
    };

    const labelRef = useRef();
    const labelWidth =
      labelRef && labelRef.current
        ? (labelRef.current as any).clientWidth
        : 110;
    return (
      <Dialog open={!!job} onClose={onClose} disableBackdropClick>
        <form onSubmit={handleFormSubmit}>
          <DialogTitle>Event Settings for {job.title}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              style={{ gap: 10 }}
            >
              <Controller
                control={control}
                name="type"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <FormControl variant="outlined" error={invalid}>
                    <InputLabel
                      ref={labelRef}
                      shrink
                      htmlFor="interview_form_interview_type"
                    >
                      Stage Type
                    </InputLabel>
                    <Select
                      onBlur={onBlur}
                      id="interview_form_interview_type"
                      inputRef={ref}
                      variant="outlined"
                      labelWidth={labelWidth}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {Object.values(INTERVIEW_TYPES).map((type) => (
                        <MenuItem key={type} value={type}>
                          {capitalize(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="title"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    variant="outlined"
                    label="Event Title"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof EventSettingsForm}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />

              <Controller
                control={control}
                name="intervalMinutes"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    variant="outlined"
                    label="Interval"
                    type="number"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof EventSettingsForm}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="durationMinutes"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    variant="outlined"
                    label="Duration"
                    type="number"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof EventSettingsForm}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="meetingLink"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    variant="outlined"
                    label="Meeting link"
                    type="text"
                    inputRef={ref}
                    value={value}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof EventSettingsForm}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="interviewers"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <FormControl variant="outlined" error={invalid}>
                    <InputLabel id="event_settings_form_interviewers">
                      Interviewers
                    </InputLabel>
                    <Select
                      onBlur={onBlur}
                      labelId="event_settings_form_interviewers"
                      inputRef={ref}
                      value={value}
                      multiple
                      input={<Input />}
                      renderValue={(selected: string[]) => (
                        <div className={classes.chips}>
                          {selected.map((value) => {
                            const interviewer: IInterviewer = interviewers.find(
                              (i) => i._id === value
                            );

                            if (!interviewer) {
                              return null;
                            }

                            return (
                              <Chip
                                key={value}
                                label={`${interviewer.lastName} ${interviewer.firstName}`}
                                className={classes.chip}
                              />
                            );
                          })}
                        </div>
                      )}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {interviewers.map((interviewer) => (
                        <MenuItem key={interviewer._id} value={interviewer._id}>
                          {interviewer.lastName} {interviewer.firstName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="description"
                render={(
                  { onChange, onBlur, value, name, ref },
                  { invalid, isTouched, isDirty }
                ) => (
                  <TextField
                    onBlur={onBlur}
                    variant="outlined"
                    label="Event Description"
                    type="text"
                    inputRef={ref}
                    value={value}
                    multiline
                    rows={5}
                    error={invalid}
                    helperText={
                      <ErrorMessage
                        errors={formState.errors}
                        name={name as keyof EventSettingsForm}
                      />
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

export default JobEventSettingsModal;

import {
  IJob,
  INTERVIEW_TYPES,
  IStage,
  STAGE_INTERVIEWER_ROTATION,
} from '@er/data-hiring';
import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InterviewSegment, { PanelType } from './interview-segment-editor';
import { InterviewerRotation } from './interviewer-rotation';

export interface IPanelProps {
  onClose?: () => void;
  onSubmit?: (
    data: StageDataForm,
    interviewSegments: PanelType[],
    interviewerRotation: STAGE_INTERVIEWER_ROTATION
  ) => any;
  onTypeChange?: (data: any) => void;
  addStageOpen: Boolean;
  initialInterviewSegments?: PanelType[];
  job: any;
  stage: IStage;
  loading: boolean;
}

export type StageDataForm = {
  type: INTERVIEW_TYPES;
  stageName: string;
  eventDescription?: string;
};

export const SetupPanelStage: FunctionComponent<IPanelProps> = ({
  addStageOpen,
  onSubmit,
  onClose,
  onTypeChange,
  initialInterviewSegments,
  job,
  stage,
  loading,
}) => {
  const [interviewSegments, setInterviewSegments] = useState<PanelType[]>(
    initialInterviewSegments
  );

  const defaultValues: StageDataForm = {
    type: INTERVIEW_TYPES.PANEL,
    stageName: stage?.title,
    eventDescription: stage?.eventDescription ? stage.eventDescription : '',
  };

  const { handleSubmit, formState, control, errors, reset, setValue } =
    useForm<StageDataForm>({
      defaultValues,
    });

  const handleFormSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    onSubmit(data, interviewSegments, interviewerRotation);
  });

  const handleDialogClose = () => {
    setInterviewSegments([]);
    if (onClose) {
      onClose();
    }
  };

  const handleFocusAreaChange = (id, focusArea: string) => {
    const newInterviewSegments = [...interviewSegments];
    newInterviewSegments[id].focusArea = focusArea;

    setInterviewSegments(newInterviewSegments);
  };

  const handleInterviewersChange = (id, interviewers) => {
    const newInterviewSegments = [...interviewSegments];
    newInterviewSegments[id].interviewers = interviewers;

    setInterviewSegments(newInterviewSegments);
  };

  const handleInterviewerRemove = (id, interviewerToRemove) => {
    const newInterviewSegments = [...interviewSegments];

    newInterviewSegments[id].interviewers = newInterviewSegments[
      id
    ].interviewers.filter(
      (interviewer, idx) => interviewer !== interviewerToRemove._id
    );

    setInterviewSegments(newInterviewSegments);
  };

  const handleDurationChange = (id, durationMinutes) => {
    const newInterviewSegments = [...interviewSegments];
    newInterviewSegments[id].durationMinutes = durationMinutes;

    setInterviewSegments(newInterviewSegments);
  };

  const handleAddClicked = () => {
    const newInterviewSegments = [
      ...interviewSegments,
      { durationMinutes: 60, focusArea: '', interviewers: [] },
    ];

    setInterviewSegments(newInterviewSegments);
  };

  const handleSlotDelete = (id) => {
    setInterviewSegments(interviewSegments.filter((slot, idx) => idx !== id));
  };

  const labelRef = useRef();
  const labelWidth =
    labelRef && labelRef.current ? (labelRef.current as any).clientWidth : 110;

  let initialInterviewRotation = STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION;

  if (stage?.interviewerRotation) {
    initialInterviewRotation = stage?.interviewerRotation;
  }

  const handleChange = (action) => {
    if (action === STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY) {
      setInterviewerRotation(STAGE_INTERVIEWER_ROTATION.MAXIMIZE_AVAILABILITY);
    } else if (action === STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION) {
      setInterviewerRotation(STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION);
    }
  };

  const [interviewerRotation, setInterviewerRotation] =
    useState<STAGE_INTERVIEWER_ROTATION>(initialInterviewRotation);

  return (
    <Dialog
      open={!!addStageOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Sequential: Setting
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" style={{ gap: 10 }}>
          <Controller
            control={control}
            name="type"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl variant="outlined" error={invalid}>
                <InputLabel
                  ref={labelRef}
                  shrink
                  htmlFor="interview_form_interview_type"
                >
                  Stage Type
                </InputLabel>
                <Select
                  onBlur={onBlur}
                  id="interview_form_interview_type"
                  inputRef={ref}
                  variant="outlined"
                  labelWidth={labelWidth}
                  value={value}
                  onChange={(e) => {
                    onTypeChange(e.target.value);
                    reset(defaultValues);
                    onChange(e.target.value);
                  }}
                >
                  <MenuItem
                    key={INTERVIEW_TYPES.PANEL}
                    value={INTERVIEW_TYPES.PANEL}
                  >
                    {capitalize(INTERVIEW_TYPES.PANEL)}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="stageName"
            rules={{
              required: 'Stage name is required',
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Stage name"
                type="text"
                inputRef={ref}
                value={value}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof StageDataForm}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Interview Segments
          </Typography>
          <InterviewSegment
            interviewSegments={interviewSegments}
            onInterviewSegmentInterviewerRemove={handleInterviewerRemove}
            onInterviewSegmentInterviewerChange={handleInterviewersChange}
            onInterviewSegmentDurationChange={handleDurationChange}
            onInterviewSegmentFocusAreaChange={handleFocusAreaChange}
            onAddInterviewSegment={handleAddClicked}
            onDeleteInterviewSegment={handleSlotDelete}
            control={control}
            formState={formState}
            job={job}
          />
          <InterviewerRotation
            interviewerRotation={interviewerRotation}
            handleChange={handleChange}
          ></InterviewerRotation>
          <Controller
            control={control}
            name="eventDescription"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                onBlur={onBlur}
                variant="outlined"
                label="Event Description"
                type="text"
                inputRef={ref}
                value={value}
                multiline
                rows={5}
                error={invalid}
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name={name as keyof StageDataForm}
                  />
                }
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
          style={{ marginBottom: '24px' }}
        >
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            style={{ marginLeft: '24px' }}
            disabled={loading}
          >
            Update
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

import React from 'react';

import AsyncSelect from 'react-select/async';

const StageFilter = ({
  onFilterChange,
  selectedStages,
  companyId,
  displayInterviewMode,
}) => {
  const handleSelectChange = (selectedCandidates) => {
    onFilterChange(selectedCandidates);
  };

  const promiseOptions = (inputValue: string) => {
    const url = `/api/jobs/company/${companyId}/stages/filter-by-name?type=${displayInterviewMode}${
      inputValue ? '&searchName=' + inputValue : ''
    }`;

    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        return res.data;
      });
  };

  return (
    <AsyncSelect
      placeholder="Select Stage"
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      instanceId="1"
      onChange={handleSelectChange}
      components={{
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null,
      }}
      defaultValue={selectedStages}
    />
  );
};

export default StageFilter;

import { IHiringProcess, IStage } from '@er/data-hiring';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const useStyles = makeStyles((theme) => ({
  textColorAndOpacity: {
    color: '#000000',
    opacity: 0.55,
  },
  titleColorAndOpacity: {
    color: '#000000',
    opacity: 0.65,
  },
}));

export type IEditReviewStageTitleData = {
  reviewStageTitle: string;
};

export interface IEditReviewStageTitleProps {
  stage: IStage;

  onClose: () => any;

  onSubmit: (data: IEditReviewStageTitleData, stage: IStage) => any;
}

export const EditReviewStageTitle: FunctionComponent<IEditReviewStageTitleProps> =
  ({ stage, onClose, onSubmit }) => {
    const classes = useStyles();

    const defaultValues: IEditReviewStageTitleData = {
      reviewStageTitle: stage?.title,
    };

    const { handleSubmit, formState, control, errors, reset } =
      useForm<IEditReviewStageTitleData>({
        defaultValues,
      });

    const handleFormSubmit = handleSubmit((data, e) => {
      e.preventDefault();

      onSubmit(data, stage);
    });

    return (
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.titleColorAndOpacity}>
              <b>Edit Name</b>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Controller
              control={control}
              name="reviewStageTitle"
              rules={{
                required: 'Review stage name is required',
              }}
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  fullWidth
                  onBlur={onBlur}
                  variant="outlined"
                  label="Review Stage"
                  type="text"
                  inputRef={ref}
                  value={value}
                  error={invalid}
                  helperText={
                    <ErrorMessage
                      errors={formState.errors}
                      name={name as keyof IEditReviewStageTitleData}
                    />
                  }
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <Button
              onClick={handleFormSubmit}
              color="primary"
              style={{
                marginTop: '25px',
                marginBottom: '15px',
              }}
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default EditReviewStageTitle;

import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { TimeRangeSlot } from './time-picker-slot';
import { ITimeRange } from './availability-editor-slot';

export interface IDayOfWeekTimeRanges {
  timeSegmentSelection: ITimeRange[];
  dayOfWeek: string;

  onDelete?: (dayOfWeek: string, id: number) => any;
  onChange?: (
    dayOfWeek: string,
    id: number,
    startTime: string,
    endTime: string
  ) => any;
}

export const DayOfWeekTimeRanges: FunctionComponent<IDayOfWeekTimeRanges> = ({
  timeSegmentSelection,
  dayOfWeek,
  onChange,
  onDelete,
}) => {
  const handleTimeSlotDelete = (dayOfWeek: string, id: number) => {
    onDelete(dayOfWeek, id);
  };

  const displayTimeSegmentSelection = () => {
    if (timeSegmentSelection && timeSegmentSelection.length > 0) {
      return timeSegmentSelection.map((value, idx) => (
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '8px',
          }}
        >
          <TimeRangeSlot
            dayOfWeek={dayOfWeek}
            id={idx}
            startTime={value.startTime}
            endTime={value.endTime}
            onChange={onChange}
            onDelete={handleTimeSlotDelete}
          ></TimeRangeSlot>
        </Grid>
      ));
    } else {
      return (
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            align="center"
            style={{ marginTop: '6px' }}
          >
            Unavailable
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Grid container direction="column">
      {displayTimeSegmentSelection()}
    </Grid>
  );
};

export default DayOfWeekTimeRanges;

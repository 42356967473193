import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes, { InferProps } from 'prop-types';

export type JoinToOrganizationProps = InferProps<
  typeof JoinToOrganization.propTypes
>;

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: 155,
  },
}));

export function JoinToOrganization(props: JoinToOrganizationProps) {
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center" spacing={4}>
      <Grid item>
        <Typography variant="h5">
          Do you want to create a new organization or join an existing one?
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Button
              variant="outlined"
              className={classes.button}
              color="primary"
            >
              Create a new organization
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              className={classes.button}
              color="primary"
            >
              Join an existing organization
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

JoinToOrganization.propTypes = {
  onSubmit: PropTypes.func,
  google: PropTypes.bool,
};
JoinToOrganization.defaultProps = {};

export default JoinToOrganization;

import {
  IEventAvailabilityOption,
  IHiringProcess,
  INTERVIEW_STATUSES,
  IStage,
} from '@er/data-hiring';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import classNames from 'classnames';
const labels = require('@er/ui-lib/lib/common/shared/labels.js');

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import ScheduleInterviewPanelSlot from './schedule-interview-panel-slot';
import { icons } from './candidate-interview-agenda';
import moment from 'moment';
import SnackBarErrorLayout from '@er/ui-lib/lib/common/shared/snackbar-error-layout';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      flexWrap: 'nowrap',
    },
    interviewStatus: {
      display: 'inline-flex',
      flexDirection: 'row',

      textTransform: 'capitalize',
      fontWeight: 'bolder',
      [`&.${INTERVIEW_STATUSES.REQUESTED}`]: {
        color: theme.palette.text.secondary,
      },
      [`&.${INTERVIEW_STATUSES.CANCELED}`]: {
        color: '#FF0000',
      },
      [`&.${INTERVIEW_STATUSES.SCHEDULED}`]: {
        color: theme.palette.primary.main,
      },
      [`&.${INTERVIEW_STATUSES.COMPLETED}`]: {
        color: theme.palette.primary.main,
      },
    },
  })
);

export interface IScheduleInterviewPanelProps {
  availability?: Map<string, Partial<IEventAvailabilityOption>[]>;
  hiringProcess: IHiringProcess;
  currentStage?: IStage;
  scheduledTimeMap: Map<string, IEventAvailabilityOption>;
  sortedDatesWithAvailability: string[];
  open?: boolean;
  fullScreen?: boolean;
  startDate?: Date;
  onClose: () => any;
  redirectToInterviewAgendaWithSuccessMessage: (
    hiringProcess: IHiringProcess
  ) => any;
  loading: boolean;
}

export const ScheduleInterviewPanel: FunctionComponent<IScheduleInterviewPanelProps> =
  ({
    hiringProcess,
    availability = new Map(),
    startDate,
    currentStage,
    open = true,
    fullScreen = true,
    scheduledTimeMap,
    sortedDatesWithAvailability,
    onClose,
    redirectToInterviewAgendaWithSuccessMessage,
  }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [date, setDate] = useState(null);

    const [selectedTimeMap, setSelectedTimeMap] = useState(scheduledTimeMap);
    const [interviewSegments, setInterviewSegment] = useState(
      currentStage.interviewSegment
    );
    const [loading, setLoading] = useState(false);

    const [scheduleButtonDisabled, setScheduleButtonDisabled] = useState(true);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [openForceRefreshPopUp, setOpenForceRefreshPopUp] = useState(false);

    useEffect(() => {
      // Set a timer to check after 10 minutes (600000 milliseconds)
      const timer = setTimeout(() => {
        setOpenForceRefreshPopUp(true);
      }, 600000);

      // Clear the timer if the component is unmounted before the 10 minutes is up
      return () => clearTimeout(timer);
    }, []);

    const handleDateSelected = (date) => {
      setDate(date);
    };

    const handleAvailabilitySelected = (av, interviewSegment) => {
      let newMap = { ...selectedTimeMap };
      newMap[interviewSegment._id] = av;

      setSelectedTimeMap(newMap);
      determineScheduleButtonClickStatus(newMap);
    };

    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    const determineScheduleButtonClickStatus = (newMap) => {
      const cancelledInterviewsCount =
        hiringProcessStage && hiringProcessStage.interviews
          ? hiringProcessStage?.interviews.filter(
              (interview) => interview?.status === INTERVIEW_STATUSES.CANCELED
            ).length
          : 0;

      var selectedTimes = Object.keys(newMap).length;

      if (
        selectedTimes + cancelledInterviewsCount ==
        interviewSegments.length
      ) {
        setScheduleButtonDisabled(false);
      } else {
        setScheduleButtonDisabled(true);
      }
    };

    const handleChangeTimeClicked = (interviewSegment) => {
      let newMap = { ...selectedTimeMap };
      delete newMap[interviewSegment._id];

      setSelectedTimeMap(newMap);
      determineScheduleButtonClickStatus(newMap);
    };

    function checkOverlap(interval1, interval2) {
      return (
        interval1.startDateUTC < interval2.endDateUTC &&
        interval2.startDateUTC < interval1.endDateUTC
      );
    }

    function areIntervalsOverlapping(
      selectedTime: Map<string, IEventAvailabilityOption>
    ) {
      const count = selectedTime.size;
      const selectedTimeList = Object.entries(selectedTime);

      for (let i = 0; i < selectedTimeList.length; i++) {
        for (let j = i + 1; j < selectedTimeList.length; j++) {
          if (checkOverlap(selectedTimeList[i][1], selectedTimeList[j][1])) {
            return true;
          }
        }
      }

      return false;
    }

    const handleOnClick = (
      hiringProcess: IHiringProcess,
      selectedTime: Map<string, IEventAvailabilityOption>
    ) => {
      if (areIntervalsOverlapping(selectedTime)) {
        setOpenSnackbar(true);
        setErrorMessage(labels.SelectedTimeAreOverlapping);
        return;
      }

      handleSchedule(hiringProcess, selectedTime);
    };

    const handleSchedule = async (
      hiringProcess: IHiringProcess,
      selectedTime: Map<string, IEventAvailabilityOption>
    ) => {
      setLoading(true);
      try {
        await fetch(`/api/hiring-processes/${hiringProcess._id}/scheduleV2`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'PUT',
          body: JSON.stringify({
            hiringProcess: hiringProcess,
            selectedTime,
          }),
        })
          .then(async (res) => {
            return res.json();
          })
          .then((res) => {
            if (res?.status === 'error') {
              setErrorMessage('Something went wrong, please try again.');
              setOpenSnackbar(true);
              setLoading(false);
            } else {
              redirectToInterviewAgendaWithSuccessMessage(hiringProcess);
            }
          });
      } catch (error) {
        console.error(error);
      }
    };

    const displayDaysWithAvailability = () => {
      return (
        <List
          component="nav"
          aria-label="main mailbox folders"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {sortedDatesWithAvailability.map((day: string) => (
            <ListItem
              button
              selected={day === date}
              style={{
                justifyContent: 'center',
                border:
                  day === date
                    ? `3px solid ${theme.palette.primary.main}`
                    : `1px solid lightgrey`,
                marginBottom: '15px',
                borderRadius: '8px',
                paddingBottom: day === date ? '7px' : '10px',
                paddingTop: day === date ? '7px' : '10px',
                maxWidth: '300px',
              }}
              onClick={(event) => handleDateSelected(day)}
            >
              <Typography
                variant="body1"
                align="center"
                style={{ fontWeight: 6000 }}
              >
                {moment(day).format('dddd, MMMM D')}
              </Typography>
            </ListItem>
          ))}
        </List>
      );
    };

    const displayUserTimeZone = () => {
      if (
        hiringProcess &&
        hiringProcess.candidate &&
        hiringProcess.candidate.timeZone
      ) {
        return (
          <Typography
            variant="body1"
            style={{ color: '#000000', opacity: 0.55 }}
          >
            Timezone: &nbsp;
            <Box fontWeight="bold" display="inline">
              {hiringProcess.candidate.timeZone}
            </Box>
          </Typography>
        );
      }
    };

    const displayStatusIcon = (interviewSegment, currentStage) => {
      const currentInterview = hiringProcessStage?.interviews.find(
        (interview) => interview.interviewSegment === interviewSegment._id
      );

      const currentInterviewStatus = currentInterview
        ? currentInterview.status
        : INTERVIEW_STATUSES.REQUESTED;

      const Icon: typeof MailOutlineIcon = icons[currentInterviewStatus];
      return (
        <Typography
          variant="body1"
          className={classNames(
            classes.interviewStatus,
            currentInterviewStatus
          )}
        >
          &nbsp; &mdash; &nbsp;
          {!!Icon && <Icon fontSize="small" />}
          &nbsp;
          {currentInterviewStatus}
        </Typography>
      );
    };

    const handleClose = (
      event: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpenSnackbar(false);
    };

    const refreshPage = () => {
      setOpenForceRefreshPopUp(false);
      // Refresh the page
      window.location.reload();
    };

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <SnackBarErrorLayout
          open={openSnackbar}
          handleClose={handleClose}
          errorMessage={errorMessage}
        ></SnackBarErrorLayout>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              style={{ color: '#000000' }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ color: '#000000' }}>
              Schedule interview for {hiringProcess.job.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {loading && <CustomCircularLoading />}
          <Grid container direction="row" spacing={1}>
            <Grid item sm={6}>
              <Card
                elevation={2}
                style={{
                  marginTop: '16px',
                  marginLeft: '16px',
                  marginRight: '8px',
                }}
              >
                <CardContent>
                  <Typography variant="h4" align="center">
                    <strong>Select days you're available</strong>
                  </Typography>
                  <br />
                  <br />
                  {displayDaysWithAvailability()}
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={6}>
              <Card
                elevation={2}
                style={{
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  marginTop: '16px',
                  marginLeft: '8px',
                  marginRight: '16px',
                }}
              >
                <Box style={{ textAlign: 'center', marginTop: '16px' }}>
                  <Typography variant="h4" display="inline">
                    <b>Agenda</b>{' '}
                  </Typography>
                  <Typography color="primary" variant="h4" display="inline">
                    <strong>Builder</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: '#000000', opacity: 0.55 }}
                  >
                    {currentStage?.title}
                  </Typography>
                  {displayUserTimeZone()}
                </Box>
                <br />

                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  justifyContent="center"
                >
                  {interviewSegments &&
                    interviewSegments.map((interviewSegment) => (
                      <Grid key={interviewSegment._id} item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{
                            marginBottom: '5px',
                          }}
                        >
                          <Typography variant="body1">
                            <strong> {interviewSegment.focusArea}</strong>
                          </Typography>
                          {displayStatusIcon(interviewSegment, currentStage)}
                        </Box>

                        <Card
                          style={{
                            minHeight: '160px',
                            marginBottom: '25px',
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #71A7A6',
                          }}
                          elevation={3}
                        >
                          <CardContent
                            style={{
                              flexGrow: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ height: '100%' }}
                            >
                              <Grid item xs={12}>
                                <ScheduleInterviewPanelSlot
                                  interviewSegment={interviewSegment}
                                  handleChangeTimeClicked={
                                    handleChangeTimeClicked
                                  }
                                  handleAvailabilitySelected={
                                    handleAvailabilitySelected
                                  }
                                  selectedTimeMap={selectedTimeMap}
                                  availability={availability}
                                  date={date}
                                  hiringProcess={hiringProcess}
                                ></ScheduleInterviewPanelSlot>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
                <Box style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    disabled={scheduleButtonDisabled || loading}
                    onClick={() =>
                      handleOnClick(hiringProcess, selectedTimeMap)
                    }
                    color="primary"
                    style={{ marginBottom: '36px' }}
                  >
                    <Typography align="center">Confirm</Typography>
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Dialog open={openForceRefreshPopUp}>
            <DialogTitle>{'Inactive for 10 minutes'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You've been inactive for over 10 minutes. Please refresh the
                page for updated content.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={refreshPage} color="primary">
                Refresh
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
      </Dialog>
    );
  };

export default ScheduleInterviewPanel;

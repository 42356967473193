import React from 'react';
import CentralCardLayout, {
  CentralCardLayoutProps,
} from '../../layout/central-card-layout/central-card-layout';
import JoinToOrganization from '../join-to-organization/join-to-organization';
import PropTypes, { InferProps } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '../../common/stepper/stepper';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 25,
  },
}));

export type JoinOrganizationPageProps = InferProps<
  typeof JoinOrganizationPage.propTypes
>;

export function JoinOrganizationPage(props: JoinOrganizationPageProps) {
  const { onSubmit, steps, currentStep } = props;
  const classes = useStyles();
  const layoutProps: CentralCardLayoutProps = {
    contentLogo: true,
    alignContentLogo: 'left',
    width: 560,
    height: 325,
  };
  if (steps > 0) {
    layoutProps.preContentElementHeight = 30;
    layoutProps.preContentElement = (
      <Stepper steps={steps} current={currentStep} />
    );
  }
  return (
    <CentralCardLayout {...layoutProps}>
      <div className={classes.content}>
        <JoinToOrganization onSubmit={onSubmit} google />
      </div>
    </CentralCardLayout>
  );
}

JoinOrganizationPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.number,
  currentStep: PropTypes.number,
};

JoinOrganizationPage.defaultProps = {
  currentStep: 1,
  steps: 0,
};

export default JoinOrganizationPage;

import { IHiringProcess, IJob, IUser } from '@er/data-hiring';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  AdminAgendaEditorInterviewSegmentSlot,
  viewResumeButtonClick,
} from 'apps/hiring/components';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { sortInterviews } from 'apps/hiring/pages/api/utils/utils';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export interface IAdminAgendaEditorProps {
  onClose: () => void;
  refreshAgendaEditorData: () => void;
  hiringProcess: IHiringProcess;
  adminAgendaEditorOpen: boolean;
  job: IJob;
  user: IUser;
}

export const AdminAgendaEditor: FunctionComponent<IAdminAgendaEditorProps> = ({
  adminAgendaEditorOpen,
  onClose,
  hiringProcess,
  job,
  refreshAgendaEditorData,
  user,
}) => {
  const classes = useStyles();

  const candidateName = () => {
    const candidate = hiringProcess?.candidate;

    if (candidate && candidate?.resumeFileName) {
      return (
        <Button
          style={{
            textTransform: 'none',
            paddingLeft: 0,
            color: '#0D293A',
            opacity: 0.85,
          }}
          onClick={() => viewResumeButtonClick(candidate)}
        >
          <Typography style={{ fontSize: '14px' }}>
            {candidate.firstName + ' ' + candidate.lastName}
          </Typography>
        </Button>
      );
    }

    return candidate ? candidate.firstName + ' ' + candidate.lastName : '';
  };

  const candidateEmail = () => {
    return hiringProcess.candidate ? hiringProcess.candidate.email : '';
  };

  const refreshAvailabilityForInterviewers = async () => {
    await fetch(`/api/cronofy/refresh-availability/${hiringProcess._id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {});

    refreshAgendaEditorData();
  };

  const determineRefreshAvailabilityDisabled = () => {
    let currentStage = job.stages.find(
      (stage) => stage._id == hiringProcess?.currentStage
    );

    if (!currentStage?.lastRefreshAvailabilityClicked) {
      return false;
    }

    let lastRefreshAvailabilityClickedPlusOneMinute = new Date(
      currentStage?.lastRefreshAvailabilityClicked
    );

    lastRefreshAvailabilityClickedPlusOneMinute.setTime(
      lastRefreshAvailabilityClickedPlusOneMinute.getTime() + 1 * 60 * 1000
    );

    const currentTime = new Date();
    if (currentTime < lastRefreshAvailabilityClickedPlusOneMinute) {
      return true;
    }

    return false;
  };

  function getInterviewForInterviewSegment(interviewSegment) {
    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    let currentInterview = null;

    if (hiringProcessStage && hiringProcessStage.interviews) {
      currentInterview = hiringProcessStage.interviews.find(
        (interview) => interview.interviewSegment === interviewSegment._id
      );
    }

    return currentInterview;
  }

  function sortInterviewSegment(a, b) {
    return sortInterviews(a.interview, b.interview);
  }

  const displayContent = () => {
    if (!hiringProcess) {
      return <div>Hiring Process could not be found</div>;
    }

    let currentStage = job.stages.find(
      (stage) => stage._id == hiringProcess?.currentStage
    );

    return (
      <div>
        <Card>
          <CardContent>
            <Grid container style={{ marginLeft: '12px' }}>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '16px' }}>Candidate</Typography>
                <Typography variant="body1">{candidateName()}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography style={{ fontSize: '16px' }}>Email</Typography>

                <Link
                  href={'mailto:' + candidateEmail()}
                  style={{ color: '#2196F3', fontSize: '14px' }}
                >
                  {candidateEmail()}
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box textAlign="center">
          {' '}
          <Button
            variant="text"
            color="primary"
            onClick={refreshAvailabilityForInterviewers}
            disabled={determineRefreshAvailabilityDisabled()}
          >
            Refresh Availability
          </Button>
        </Box>
        <br />
        {currentStage &&
          currentStage.interviewSegment &&
          currentStage.interviewSegment
            .map((interviewSegment) => ({
              interviewSegment: interviewSegment,
              interview: getInterviewForInterviewSegment(interviewSegment),
            }))
            .sort(sortInterviewSegment)
            .map((element) => (
              <AdminAgendaEditorInterviewSegmentSlot
                refreshAgendaEditorData={refreshAgendaEditorData}
                interviewSegment={element?.interviewSegment}
                hiringProcess={hiringProcess}
                user={user}
              ></AdminAgendaEditorInterviewSegmentSlot>
            ))}
      </div>
    );
  };

  const displayStageName = () => {
    if (!hiringProcess) {
      return;
    }

    let currentStage = job.stages.find(
      (stage) => stage._id == hiringProcess?.currentStage
    );

    if (currentStage?.title) {
      return currentStage.title;
    }
  };

  return (
    <Dialog
      open={!!adminAgendaEditorOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box textAlign="center">
          <Typography variant="h4">
            <strong>Agenda Editor</strong>
          </Typography>
          {displayStageName()}
          <IconButton
            edge="start"
            color="inherit"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {displayContent()}
      </DialogContent>
    </Dialog>
  );
};

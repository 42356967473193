import { ICalendar, IOpenHoursSlot } from '@er/data-hiring';
import { AvailabilityEditor } from '@er/ui-lib';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

export interface ICalendarAvailabilityModalProps {
  calendar: ICalendar;
  onClose: () => any;
  onSubmit: (calendar: ICalendar) => any;
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 660,
  },
}));

export const CalendarAvailabilityModal: FunctionComponent<ICalendarAvailabilityModalProps> =
  ({ calendar, onSubmit, onClose }) => {
    const [editCalendar, setEditCalendar] = useState<ICalendar>(calendar);
    const classes = useStyles();

    const handleAvailabilityChange = (openHours: ICalendar['openHours']) => {
      setEditCalendar({ ...editCalendar, openHours });
    };

    const handleSubmit = (evt) => {
      evt.preventDefault();
      if (onSubmit) onSubmit(editCalendar);
      handleDialogClose();
    };

    const handleDialogClose = () => {
      setEditCalendar(null);
      if (onClose) {
        onClose();
      }
    };

    useEffect(() => {
      setEditCalendar(calendar);
    }, [calendar]);

    if (!editCalendar) return null;

    return (
      <Dialog
        open={!!calendar}
        onClose={onClose}
        disableBackdropClick
        maxWidth="md"
      >
        <DialogTitle>Edit Calendar Availability</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {/* <AvailabilityEditor
            openHours={editCalendar.openHours}
            onChange={handleAvailabilityChange}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

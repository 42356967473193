import * as _ from 'lodash';
import moment from 'moment-timezone';

export interface ITimeZone {
  label: string;
  value: string;
  time: string;
}

export function listTimeZones(): ITimeZone[] {
  const timeZoneNames: string[] = moment.tz.names();
  const timeZones: ITimeZone[] = timeZoneNames.map((n) => timeZoneFromName(n));

  return _.sortBy(timeZones, [
    function (el) {
      return -el.time;
    },
  ]);
}

export function timeZoneFromName(name: string): ITimeZone {
  const tz = moment
    .tz(name)
    .format('Z')
    .replace(':00', '')
    .replace(':30', '.5');
  const x = +tz === 0 ? 0 : parseInt(tz).toFixed(2);

  return {
    label: `(GMT${moment.tz(name).format('Z')}) ${name}`,
    value: `${name}`,
    time: `${x}`,
  };
}

export function getCurrentTimeZone(): string {
  return moment.tz.guess();
}

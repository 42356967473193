import React from 'react';
import CentralCardLayout, {
  CentralCardLayoutProps,
} from '../../layout/central-card-layout/central-card-layout';
import SignUpForm from '../sign-up-form/sign-up-form';
import PropTypes, { InferProps } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '../../common/stepper/stepper';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 25,
  },
}));

export type SignUpPageProps = InferProps<typeof SignUpPage.propTypes>;

export function SignUpPage(props: SignUpPageProps) {
  const { onSubmit, steps, currentStep, redirectLink } = props;
  const classes = useStyles();
  const layoutProps: CentralCardLayoutProps = {
    contentLogo: true,
  };

  if (steps > 0) {
    layoutProps.preContentElementHeight = 30;
    layoutProps.preContentElement = (
      <Stepper steps={steps} current={currentStep} />
    );
  }

  return (
    <CentralCardLayout {...layoutProps}>
      <div className={classes.content}>
        <SignUpForm onSubmit={onSubmit} redirectLink={redirectLink} />
      </div>
    </CentralCardLayout>
  );
}

SignUpPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.number,
  currentStep: PropTypes.number,
  redirectLink: PropTypes.string,
};

SignUpPage.defaultProps = {
  currentStep: 1,
  steps: 0,
};

export default SignUpPage;

import { IOpenHoursSlot, WEEK_DAYS } from '@er/data-hiring';
import {
  Grid,
  makeStyles,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { TimeRangePicker } from '../time-range-picker/time-range-picker';
import { AvailabilityEditorWeekDay } from './weekday';
import DeleteIcon from '@material-ui/icons/Delete';
import { PanelType } from '../jobs';
import DayOfWeekTimeRanges from './availability-segment-editor';

export interface ITimeRange {
  startTime: string;
  endTime: string;
}

export interface IDayOfWeekSlot {
  id?: any;
  dayOfWeek: string;
  timeSegmentSelection: ITimeRange[];
  onAdd?: (dayOfWeek: string) => any;
  onDelete?: (dayOfWeek: string, id: number) => any;
  onChange?: (
    dayOfWeek: string,
    id: number,
    startTime: string,
    endTime: string
  ) => any;
}

const useStyles = makeStyles((theme) => ({
  timePicker: {
    lineHeight: '35px',
    marginLeft: 10,
  },
}));

export const DayOfWeekSlot: FunctionComponent<IDayOfWeekSlot> = ({
  dayOfWeek,
  timeSegmentSelection,
  onDelete,
  onChange,
  onAdd,
}) => {
  const handleAddTimeSlotClick = (evt) => {
    onAdd(dayOfWeek);
  };

  function capitalize(s) {
    return s[0].toUpperCase() + s[1] + s[2];
  }

  const classes = useStyles();
  return (
    <Grid container direction="row" style={{ marginBottom: '16px' }}>
      <Grid item xs={1} container>
        <Typography variant="body1" style={{ marginTop: '6px' }}>
          <Box fontWeight="bold"> {capitalize(dayOfWeek)}</Box>
        </Typography>
      </Grid>
      <Grid item xs={10} direction="row">
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <DayOfWeekTimeRanges
            timeSegmentSelection={timeSegmentSelection}
            dayOfWeek={dayOfWeek}
            onChange={onChange}
            onDelete={onDelete}
          />
        </Box>
      </Grid>

      <Grid item xs={1}>
        <IconButton onClick={handleAddTimeSlotClick}>
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

import React from 'react';
import CentralCardLayout, {
  CentralCardLayoutProps,
} from '../../layout/central-card-layout/central-card-layout';
import PropTypes, { InferProps } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '../../common/stepper/stepper';
import { JoinToOrganization } from '../join-to-organization';
import { LoginForm } from '../login-form';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 25,
  },
}));

export type LoginPageProps = InferProps<typeof LoginPage.propTypes>;

export function LoginPage(props: LoginPageProps) {
  const { onSubmit, steps, currentStep } = props;
  const classes = useStyles();
  const layoutProps: CentralCardLayoutProps = {
    contentLogo: true,
  };
  if (steps > 0) {
    layoutProps.preContentElementHeight = 30;
    layoutProps.preContentElement = (
      <Stepper steps={steps} current={currentStep} />
    );
  }
  return (
    <CentralCardLayout {...layoutProps}>
      <div className={classes.content}>
        <LoginForm onSubmit={onSubmit} />
      </div>
    </CentralCardLayout>
  );
}

LoginPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.number,
  currentStep: PropTypes.number,
};

LoginPage.defaultProps = {
  currentStep: 1,
  steps: 0,
};

export default LoginPage;

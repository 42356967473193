import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { RootRef, makeStyles, Theme, Grid } from '@material-ui/core';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { FileDropzoneContainer } from './file-dropzone-container';
import {
  FileDropzoneContent,
  IFileDropzoneContentProps,
} from './file-dropzone-content';
import { IUploadedFile } from './file-uploader.interface';
import { FileList } from './file-list';

export interface IFileDropzoneProps extends DropzoneOptions {
  title?: string;
  subtitle?: string;
  hideOnFileLimit?: boolean;
  acceptTitle?: string;
  acceptSubtitle?: string;
  rejectTitle?: string;
  rejectSubtitle?: string;
  files?: IUploadedFile[];
  onFileDelete?: (file: IUploadedFile) => any;
  Icon?: typeof NoteAddOutlinedIcon;
  RejectIcon?: typeof CancelIcon;
}

export const FileDropzone: FunctionComponent<IFileDropzoneProps> = ({
  title = 'Resume Upload',
  subtitle,
  acceptTitle = 'Drop File here',
  acceptSubtitle,
  rejectTitle = 'File type not allowed',
  rejectSubtitle,
  files = [],
  hideOnFileLimit = false,
  Icon = NoteAddOutlinedIcon,
  RejectIcon = CancelIcon,
  onFileDelete,
  ...dropZoneOptions
}) => {
  let { maxFiles = 0, disabled = false } = dropZoneOptions;
  let fileLimitReached = false;
  if (maxFiles > 0) {
    maxFiles = Math.max(maxFiles - files.length, 0);
    fileLimitReached = maxFiles <= 0;
  }

  if (fileLimitReached) {
    disabled = true;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ ...dropZoneOptions, maxFiles, disabled });

  const containerProps = getRootProps();

  let contentProps: IFileDropzoneContentProps = {
    title,
    subtitle,
    colorText: 'textPrimary',
    colorIcon: 'primary',
    Icon,
  };

  if (disabled) {
    contentProps = {
      ...contentProps,
      colorText: 'textSecondary',
      colorIcon: 'disabled',
    };
  } else if (isDragActive && isDragAccept) {
    contentProps = {
      ...contentProps,
      colorText: 'primary',
      title: acceptTitle || contentProps.title,
      subtitle: acceptSubtitle || contentProps.subtitle,
    };
  } else if (isDragActive && isDragReject) {
    contentProps = {
      ...contentProps,
      colorText: 'error',
      colorIcon: 'error',
      title: rejectTitle || contentProps.title,
      subtitle: rejectSubtitle || contentProps.subtitle,
      Icon: RejectIcon || contentProps.Icon,
    };
  }

  return (
    <>
      {(!hideOnFileLimit || !fileLimitReached) && (
        <FileDropzoneContainer
          containerProps={containerProps}
          accepting={isDragAccept}
          rejecting={isDragReject}
          active={isDragActive}
          disabled={disabled}
        >
          <input {...getInputProps()} />

          <FileDropzoneContent {...contentProps} />
        </FileDropzoneContainer>
      )}

      {!!files.length && <FileList files={files} onDelete={onFileDelete} />}
    </>
  );
};

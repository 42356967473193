import { Tab, Tabs } from '@material-ui/core';
import NextLink from 'next/link';
import React from 'react';

export enum USER_SETTINGS_TABS {
  MYPROFILE = 0,
  INTEGRATIONS = 1,
  PASSWORD = 2,
}

export function UserSettingsTabs({
  user,
  tabId = USER_SETTINGS_TABS.MYPROFILE,
}) {
  return (
    <Tabs
      value={tabId}
      indicatorColor="primary"
      textColor="primary"
      aria-label="disabled tabs example"
    >
      <NextLink href={`/settings`} as={`/settings`} passHref>
        <Tab
          component="a"
          value={USER_SETTINGS_TABS.MYPROFILE}
          label="Basic Info"
        />
      </NextLink>
      <NextLink
        href={`/settings/integrations`}
        as={`/settings/integrations`}
        passHref
      >
        <Tab
          component="a"
          label="Integrations"
          value={USER_SETTINGS_TABS.INTEGRATIONS}
        />
      </NextLink>
      <NextLink href={`/settings/password`} as={`/settings/password`} passHref>
        <Tab
          component="a"
          label="Password"
          value={USER_SETTINGS_TABS.PASSWORD}
        />
      </NextLink>
    </Tabs>
  );
}

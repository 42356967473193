export interface IAdminAgendaEditorConfirmRequestRescheduleProps {
  interview: IInterview;
  onClose: () => any;
  refreshAgendaEditorData: () => any;
}

import React, { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  HIRING_PROCESS_STATUSES,
  IInterview,
  INTERVIEW_COLOR_AGES,
  INTERVIEW_SORT_FIELDS,
  INTERVIEW_SORT_ORDER,
  INTERVIEW_STATUSES,
  INTERVIEW_TYPES,
  USER_TYPES,
} from '@er/data-hiring';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import NameFilter from 'apps/hiring/components/candidate-portal/candidate-jobs/name-filter';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  assignInterviewerDisabled,
  interviewActive,
} from './admin-agenda-editor-interview-segment-slot';
import { displayHourAndMinutesInUserTimezone } from 'apps/hiring/pages/api/utils/utils';
import { displayJoinInterview, icons } from './candidate-interview-agenda';
import AdminAgendaEditorConfirmCancelInterview from './admin-agenda-editor-confirm-cancel-interview';
import AdminAgendaEditorConfirmRequestReschedule from './admin-agenda-editor-confirm-request-reschedule';
import { SendReminder } from './admin-agenda-editor-confirm-send-reminder';
import JobFilter from './job-filter';
import StageFilter from './stage-filter';
import interviewActions from './interview-actions';
import InterviewerFilter from './interviewer-filter';
import AdminAgendaEditorMarkAsNoShow from './admin-agenda-editor-mark-as-no-show';
import AdminAgendaEditorChangeInterviewer from './admin-agenda-editor-change-interviewer';
import AdminAgendaEditorChangeInterviewerRequestedInterview from './admin-agenda-editor-change-interviewer-requested-interview';
import SchduledInterviewsActionButtonGroup from './schduled-interview-action-items';
import { viewResumeButtonClick } from '../candidate-profile';
import { capitalizeFirstLetter } from 'apps/hiring/pages/jobs/[id]';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';
import { InterviewSegment } from '@er/ui-lib';

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export function isJobOwner(interview, user) {
  const jobOwners = interview?.job?.jobOwners;
  let isJobOwner = false;

  if (jobOwners && jobOwners.length > 0 && jobOwners.includes(user?._id)) {
    isJobOwner = true;
  }

  return isJobOwner;
}

export const CHANGE_INTERVIEWER = 'Change Interviewer';
const REQUEST_RESCHEDULE = 'Request Reschedule';
const CANCEL = 'Cancel Interview';
export const MARK_AS_NO_SHOW = 'Mark as no show';

export const SchduledInterviewAdminActionsList = [
  CHANGE_INTERVIEWER,
  REQUEST_RESCHEDULE,
  CANCEL,
  MARK_AS_NO_SHOW,
];

export const SchduledInterviewMemberActionsList = [
  REQUEST_RESCHEDULE,
  MARK_AS_NO_SHOW,
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    minWidth: 650,
  },
  calendar: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingRight: '8px',
      },
    },
  },
}));

export enum DISPLAY_INTERVIEWS_MODE {
  MY_INTERVIEWS = 0,
  ALL_COMPANY_INTERVIEWS = 1,
  HIREHAC_ADMIN = 2,
}

export default function InterviewsListPage({
  user,
  companyId,
  interviews,
  displayInterviewMode = DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS,
}) {
  const classes = useStyles();

  const [myInterviews, setMyInterviews] = React.useState(interviews || []);

  function displayInterviewStage(interview) {
    const stageStructure = interview?.job.stages?.find(
      (stage) => stage._id === interview.stage
    );

    if (stageStructure) {
      if (stageStructure.type === INTERVIEW_TYPES.PANEL) {
        return (
          <div>
            <Typography
              style={{
                fontWeight: 600,
              }}
            >
              {stageStructure.title}
            </Typography>
            <Typography variant="caption" style={{ fontSize: '14px' }}>
              {interview.title}
            </Typography>
          </div>
        );
      } else {
        return (
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            {stageStructure.title}
          </Typography>
        );
      }
    }

    return '';
  }

  function displayHiringProcessStatus(interview) {
    const hiringProcessStatus = interview?.hiringProcess?.status;

    if (!hiringProcessStatus) {
      return '';
    }

    return (
      <Typography>
        {hiringProcessStatus.charAt(0).toUpperCase() +
          hiringProcessStatus.slice(1)}
      </Typography>
    );
  }

  function displayCandidateName(interview) {
    const candidate = interview.candidate;

    if (candidate && candidate?.resumeFileName) {
      return (
        <Button
          onClick={() => viewResumeButtonClick(candidate)}
          style={{
            color: '#0D293A',
            marginBottom: '12px',
            textTransform: 'none',
          }}
        >
          <Typography align="center" style={{ fontWeight: 600 }}>
            {candidate.firstName + ' ' + candidate.lastName}
          </Typography>
        </Button>
      );
    }

    return (
      <Typography
        style={{
          fontWeight: 600,
        }}
      >
        {interview.candidate?.firstName} {interview.candidate?.lastName}
      </Typography>
    );
  }

  function displayInterviewer(interview) {
    if (
      interview &&
      interview.interviewers &&
      interview.interviewers.length > 0
    ) {
      const interviewer = interview.interviewers[0];

      return (
        <Typography>
          {interviewer.firstName + ' ' + interviewer.lastName}
        </Typography>
      );
    }
    return '';
  }

  function displayInterviewStatus(interview) {
    const colorAge =
      interview && interview?.colorAge
        ? interview.colorAge
        : INTERVIEW_COLOR_AGES.GREY;

    const Icon: typeof MailOutlineIcon = icons[interview.status];

    return (
      <div style={{ alignItems: 'center' }}>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {!!Icon && <Icon fontSize="small" style={{ color: colorAge }} />}
          &nbsp;
          <Typography style={{ color: colorAge }}>
            {capitalizeFirstLetter(interview.status)}
          </Typography>
        </div>
      </div>
    );
  }

  function displayColorAging(interview) {
    const color =
      interview && interview?.colorAge
        ? interview.colorAge
        : INTERVIEW_COLOR_AGES.GREY;

    return (
      <Typography style={{ color: color }}>
        {capitalizeFirstLetter(color)}
      </Typography>
    );
  }

  function displayInterviewStartTime(interview) {
    if (!interview.startTime) {
      return '';
    }

    return (
      <div>
        <Typography>
          {displayHourAndMinutesInUserTimezone(
            interview.startTime,
            user
          ).format('MMM DD, YYYY')}
        </Typography>
        <Typography
          style={{ fontWeight: 600, fontFamily: '"Open Sans", sans-serif' }}
        >
          {displayHourAndMinutesInUserTimezone(
            interview.startTime,
            user
          ).format('h:mm a') +
            ' - ' +
            displayHourAndMinutesInUserTimezone(interview.endTime, user).format(
              'h:mm a'
            )}
        </Typography>
      </div>
    );
  }

  const handleActionItemClick = (selectedAction, interview) => {
    if (selectedAction == CHANGE_INTERVIEWER) {
      changeInterviewerClicked(interview);
    } else if (selectedAction == REQUEST_RESCHEDULE) {
      requestRescheduleClicked(interview);
    } else if (selectedAction == CANCEL) {
      cancelInterviewClicked(interview);
    } else if (selectedAction == MARK_AS_NO_SHOW) {
      markAsNoShowClicked(interview);
    }
  };

  function displayInterviewActionItemsForAdminOrCompanySpaceOwner(interview) {
    if (interview.status === INTERVIEW_STATUSES.SCHEDULED) {
      return displayScheduledInterviewAction(
        interview,
        SchduledInterviewAdminActionsList
      );
    } else if (interview.status === INTERVIEW_STATUSES.CANCELED) {
      return (
        <div
          style={{
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          <Button
            onClick={() => requestRescheduleClicked(interview)}
            style={{
              color: '#ffad33',
            }}
          >
            Request Reschedule
          </Button>
        </div>
      );
    } else if (interview.status === INTERVIEW_STATUSES.COMPLETED) {
      return displayMarkAsNoShowForCompletedInterview(interview);
    } else if (interview.status === INTERVIEW_STATUSES.REQUESTED) {
      const job = interview?.job;
      let currentStage = job?.stages?.find(
        (stage) => stage._id === interview?.stage
      );

      let interviewSegment = currentStage?.interviewSegment?.find(
        (interviewSegment) =>
          interviewSegment._id === interview?.interviewSegment
      );

      return (
        <div
          style={{
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          <SendReminder
            currentInterview={interview}
            refreshData={refreshData}
            hiringProcessId={interview.hiringProcess._id}
            focusArea={interview.title}
          ></SendReminder>

          <Button
            onClick={() =>
              changeInterviewerClickedForRequestedInterview(interview)
            }
            style={{
              color: assignInterviewerDisabled(interview, interviewSegment)
                ? ''
                : '#ffad33',
            }}
            disabled={assignInterviewerDisabled(interview, interviewSegment)}
          >
            Assign Interviewer
          </Button>
        </div>
      );
    }
  }

  function displayJoinInterviewButton(interview) {
    if (displayInterviewMode === DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginBottom: '8px' }}
        >
          {displayJoinInterview(interview)}
        </Grid>
      );
    }
  }

  function displayScheduledInterviewAction(interview, options) {
    if (interviewActive(interview, interview.hiringProcess)) {
      return (
        <div
          style={{
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          {displayJoinInterviewButton(interview)}
          <SchduledInterviewsActionButtonGroup
            handleClick={handleActionItemClick}
            interview={interview}
            options={options}
          ></SchduledInterviewsActionButtonGroup>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function displayMarkAsNoShowForCompletedInterview(interview) {
    if (interviewActive(interview, interview.hiringProcess)) {
      return (
        <div
          style={{
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          <Button
            onClick={() => markAsNoShowClicked(interview)}
            style={{
              color: '#808080',
            }}
          >
            Mark as no show
          </Button>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function displayInterviewActionItemsForMember(interview) {
    if (interview.status === INTERVIEW_STATUSES.SCHEDULED) {
      return displayScheduledInterviewAction(
        interview,
        SchduledInterviewMemberActionsList
      );
    } else if (interview.status === INTERVIEW_STATUSES.COMPLETED) {
      return displayMarkAsNoShowForCompletedInterview(interview);
    } else if (interview.status === INTERVIEW_STATUSES.REQUESTED) {
      return (
        <div
          style={{
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          <SendReminder
            currentInterview={interview}
            refreshData={refreshData}
            hiringProcessId={interview.hiringProcess._id}
            focusArea={interview.title}
          ></SendReminder>
        </div>
      );
    }
  }

  function displayInterviewActionItems(interview) {
    if (displayInterviewMode === DISPLAY_INTERVIEWS_MODE.HIREHAC_ADMIN) {
      return;
    }

    if (
      isJobOwner(interview, user) ||
      user?.type === USER_TYPES.ADMIN ||
      user?.type === USER_TYPES.COMPANYSPACEOWNER
    ) {
      return displayInterviewActionItemsForAdminOrCompanySpaceOwner(interview);
    } else {
      return displayInterviewActionItemsForMember(interview);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElStartTime, setAnchorElStartTime] = React.useState(null);
  const [anchorElColorAge, setAnchorElColorAge] = React.useState(null);
  const [anchorElHiringProcessStatus, setAnchorElHiringProcessStatus] =
    React.useState(null);

  const handleClickName = (event) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickStage = (event) => {
    setStageOpen((prevOpen) => !prevOpen);
  };

  const handleClickInterviewer = (event) => {
    setInterviewerOpen((prevOpen) => !prevOpen);
  };

  const handleClickJob = (event) => {
    setJobOpen((prevOpen) => !prevOpen);
  };

  const handleClickColorAge = (event) => {
    setAnchorElColorAge(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickStartTime = (event) => {
    setAnchorElStartTime(event.currentTarget);
  };

  const handleClickHiringProcessStatus = (event) => {
    setAnchorElHiringProcessStatus(event.currentTarget);
  };

  const handleCloseStartTime = () => {
    setAnchorElStartTime(null);
  };

  const handleCloseColorAge = () => {
    setAnchorElColorAge(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseHiringProcessStatus = () => {
    setAnchorElHiringProcessStatus(null);
  };

  const anchorRef = React.useRef(null);
  const anchorRefStage = React.useRef(null);
  const anchorRefInterviewer = React.useRef(null);
  const anchorRefJob = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [stageOpen, setStageOpen] = React.useState(false);
  const [interviewerOpen, setInterviewerOpen] = React.useState(false);
  const [jobOpen, setJobOpen] = React.useState(false);

  const handleCloseName = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseStage = (event) => {
    if (
      anchorRefStage.current &&
      anchorRefStage.current.contains(event.target)
    ) {
      return;
    }
    setStageOpen(false);
  };

  const handleCloseInterviewer = (event) => {
    if (
      anchorRefInterviewer.current &&
      anchorRefInterviewer.current.contains(event.target)
    ) {
      return;
    }
    setInterviewerOpen(false);
  };

  const handleCloseJob = (event) => {
    if (anchorRefJob.current && anchorRefJob.current.contains(event.target)) {
      return;
    }
    setJobOpen(false);
  };

  const [candidateNameFilter, setCandidateNameFilter] = React.useState([]);
  const [selectedStages, setSelectedStages] = React.useState([]);
  const [selectedInterviewers, setSelectedInterviewers] = React.useState([]);
  const [selectedJobs, setSelectedJobs] = React.useState([]);

  INTERVIEW_STATUSES.SCHEDULED;
  const [statusCheckBoxFilter, setStatusCheckboxFilter] = React.useState({
    [INTERVIEW_STATUSES.SCHEDULED]: true,
    [INTERVIEW_STATUSES.CANCELED]: false,
    [INTERVIEW_STATUSES.COMPLETED]: false,
    [INTERVIEW_STATUSES.REQUESTED]: false,
  });

  const [
    hiringProcessStatusCheckBoxFilter,
    setHiringProcessStatusCheckBoxFilter,
  ] = React.useState({
    [HIRING_PROCESS_STATUSES.ACTIVE]: false,
    [HIRING_PROCESS_STATUSES.DISPOSITIONED]: false,
    [HIRING_PROCESS_STATUSES.WITHDRAWN]: false,
    [HIRING_PROCESS_STATUSES.HIRED]: false,
    [HIRING_PROCESS_STATUSES.OFFER_DECLINED]: false,
  });

  const [colorAgeCheckBoxFilter, setColorAgeCheckboxFilter] = React.useState({
    [INTERVIEW_COLOR_AGES.GREY]: false,
    [INTERVIEW_COLOR_AGES.ORANGE]: false,
    [INTERVIEW_COLOR_AGES.RED]: false,
    [INTERVIEW_COLOR_AGES.GREEN]: false,
  });

  const handleCandidatNameFilterChange = (selectedCandidates) => {
    setCandidateNameFilter(selectedCandidates);

    onFilterChange(
      selectedCandidates,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const handleStageChange = (selectedStages) => {
    setSelectedStages(selectedStages);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const handleInterviewerChange = (interviewers) => {
    setSelectedInterviewers(interviewers);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      interviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const handleJobChange = (jobs) => {
    setSelectedJobs(jobs);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      jobs,
      selectedSortingOrder,
      selectedSortingField
    );
  };

  const onFilterChange = async (
    selectedCandidates,
    statusFilter,
    colorAgeFilter,
    selectedStages,
    hiringProcessStatusFilter,
    fromStartTimeFilter,
    toStartTimeFilter,
    selectedInterviewers,
    selectedJobs,
    sortingField,
    sortingOrder
  ) => {
    const candidateNameFilter = selectedCandidates.map(
      (option) => option.value
    );

    const interviewerNameFilter = selectedInterviewers.map(
      (option) => option.value
    );

    const jobFilter = selectedJobs.map((option) => option.value);

    const stagesFilter = [];

    for (const stage of selectedStages) {
      if (stage.value) {
        stagesFilter.push(...stage.value);
      }
    }

    let nextDay = null;

    if (toStartTimeFilter) {
      nextDay = new Date(toStartTimeFilter);
      nextDay.setDate(toStartTimeFilter.getDate() + 1);
    }

    let localInterviews = [];

    if (
      displayInterviewMode === DISPLAY_INTERVIEWS_MODE.ALL_COMPANY_INTERVIEWS
    ) {
      localInterviews = await fetch(`/api/interviews/filters`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidateNameFilter: candidateNameFilter,
          statusFilter: statusFilter,
          userId: user._id,
          stagesFilter: stagesFilter,
          hiringProcessStatusFilter: hiringProcessStatusFilter,
          fromStartTimeFilter: fromStartTimeFilter,
          toStartTimeFilter: nextDay,
          interviewerNameFilter: interviewerNameFilter,
          jobFilter: jobFilter,
          sortingField: sortingField,
          sortingOrder: sortingOrder,
          colorAgeFilter: colorAgeFilter,
        }),

        method: 'POST',
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          return res.data;
        });
    } else if (displayInterviewMode === DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS) {
      localInterviews = await fetch(`/api/interviews/filters/my-interviews`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidateNameFilter: candidateNameFilter,
          statusFilter: statusFilter,
          userId: user._id,
          stagesFilter: stagesFilter,
          fromStartTimeFilter: fromStartTimeFilter,
          toStartTimeFilter: nextDay,
          jobFilter: jobFilter,
          sortingField: sortingField,
          sortingOrder: sortingOrder,
        }),

        method: 'POST',
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          return res.data;
        });
    } else {
      localInterviews = await fetch(`/api/interviews/filters/hirehac-admin`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId: companyId,
          candidateNameFilter: candidateNameFilter,
          statusFilter: statusFilter,
          stagesFilter: stagesFilter,
          hiringProcessStatusFilter: hiringProcessStatusFilter,
          fromStartTimeFilter: fromStartTimeFilter,
          toStartTimeFilter: nextDay,
          interviewerNameFilter: interviewerNameFilter,
          jobFilter: jobFilter,
          sortingField: sortingField,
          sortingOrder: sortingOrder,
          colorAgeFilter: colorAgeFilter,
        }),

        method: 'POST',
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          return res.data;
        });
    }

    setMyInterviews(localInterviews);
  };

  const getCurrentJob = (currentInterview) => {
    return fetch(`/api/jobs/${currentInterview?.job?._id}`, {
      method: 'GET',
    }).then((r) => r.json());
  };

  const [confirmRequestRescheduleOpen, setConfirmRequestRescheduleOpen] =
    useState<Boolean>(false);

  const [jobForSelectedInterview, setJobForSelectedInterview] = useState(null);

  const requestRescheduleClicked = async (currentInterview) => {
    setConfirmRequestRescheduleOpen(true);
    setSelectedInterview(currentInterview);

    const job = (await getCurrentJob(currentInterview))?.data;
    setJobForSelectedInterview(job);
  };

  const {
    confirmCancelInterviewOpen,
    markAsNoShowOpen,
    changeInterviewerOpen,
    selectedInterview,
    onMarkAsNoShowClose,
    onChangeInterviewerClose,
    onCancelInterviewClose,
    markAsNoShowClicked,
    cancelInterviewClicked,
    changeInterviewerClicked,
    changeInterviewerMap,
    changeInterviewerForRequestedInterviewOpen,
    changeInterviewerClickedForRequestedInterview,
    onChangeInterviewerForRequestedInterviewClose,
    changeInterviewerForRequestedInterviewList,
    refreshInterviewerAvailability,
    setSelectedInterview,
  } = interviewActions();

  const handleStatusCheckboxChange = (status) => {
    const newStatusCheckBoxFilter = {
      ...statusCheckBoxFilter,
      [status]: !statusCheckBoxFilter[status],
    };
    setStatusCheckboxFilter(newStatusCheckBoxFilter);

    onFilterChange(
      candidateNameFilter,
      newStatusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const handleHiringProcessStatusCheckboxChange = (status) => {
    const newHiringProcessStatusCheckBoxFilter = {
      ...hiringProcessStatusCheckBoxFilter,
      [status]: !hiringProcessStatusCheckBoxFilter[status],
    };

    setHiringProcessStatusCheckBoxFilter(newHiringProcessStatusCheckBoxFilter);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      newHiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const handleColorAgeCheckboxChange = (colorAge) => {
    const newColorAgeCheckBoxFilter = {
      ...colorAgeCheckBoxFilter,
      [colorAge]: !colorAgeCheckBoxFilter[colorAge],
    };
    setColorAgeCheckboxFilter(newColorAgeCheckBoxFilter);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      newColorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);

  const handleFromDateChange = (fromDate) => {
    if (fromDate) {
      fromDate = new Date(fromDate.toDateString());
    }

    // In the current version on MaterialUI, hours and minutes are defaulted to current time, so we need to set them to 0
    setSelectedFromDate(fromDate);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      fromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const handleToDateChange = (toDate) => {
    if (toDate) {
      toDate = new Date(toDate.toDateString());
    }

    // In the current version on MaterialUI, hours and minutes are defaulted to current time, so we need to set them to 0

    setSelectedToDate(toDate);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      toDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const [selectedSortingField, setSelectedSortingField] = React.useState(
    INTERVIEW_SORT_FIELDS.START_TIME
  );

  const [selectedSortingOrder, setSelectedSortingOrder] = React.useState(
    INTERVIEW_SORT_ORDER.ASCENDING
  );

  const changeSorting = (
    interviewSortField: INTERVIEW_SORT_FIELDS,
    interviewSortOrder: INTERVIEW_SORT_ORDER
  ) => {
    setSelectedSortingField(interviewSortField);
    setSelectedSortingOrder(interviewSortOrder);

    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      interviewSortField,
      interviewSortOrder
    );
  };

  const displaySorting = (sortingField: INTERVIEW_SORT_FIELDS) => {
    return (
      <div>
        <IconButton
          style={{ padding: '8px' }}
          color={
            selectedSortingField &&
            selectedSortingOrder &&
            selectedSortingOrder === INTERVIEW_SORT_ORDER.ASCENDING &&
            selectedSortingField === sortingField
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            changeSorting(sortingField, INTERVIEW_SORT_ORDER.ASCENDING)
          }
        >
          <ArrowUpwardIcon />
        </IconButton>

        <IconButton
          style={{ padding: '8px' }}
          color={
            selectedSortingField &&
            selectedSortingOrder &&
            selectedSortingOrder === INTERVIEW_SORT_ORDER.DESCENDING &&
            selectedSortingField === sortingField
              ? 'primary'
              : 'default'
          }
        >
          <ArrowDownwardIcon
            onClick={() =>
              changeSorting(sortingField, INTERVIEW_SORT_ORDER.DESCENDING)
            }
          />
        </IconButton>
      </div>
    );
  };

  const displaySortingCandidateName = () => {
    return displaySorting(INTERVIEW_SORT_FIELDS.CANDIDATE_NAME);
  };

  const displaySortingStartTime = () => {
    return displaySorting(INTERVIEW_SORT_FIELDS.START_TIME);
  };

  const displaySortingInterviewStatus = () => {
    return displaySorting(INTERVIEW_SORT_FIELDS.INTERVIEW_STATUS);
  };

  const displaySortingJobTitle = () => {
    return displaySorting(INTERVIEW_SORT_FIELDS.JOB_TITLE);
  };

  const displaySortingInterviewerName = () => {
    return displaySorting(INTERVIEW_SORT_FIELDS.INTERVIEWER_NAME);
  };

  const displaySortingHiringProcessStatus = () => {
    return displaySorting(INTERVIEW_SORT_FIELDS.HIRING_PROCESS_STATUS);
  };

  const interviewStatusToDisplayList = () => {
    if (
      displayInterviewMode === DISPLAY_INTERVIEWS_MODE.ALL_COMPANY_INTERVIEWS ||
      displayInterviewMode === DISPLAY_INTERVIEWS_MODE.HIREHAC_ADMIN
    ) {
      return Object.values(INTERVIEW_STATUSES);
    } else {
      return [
        INTERVIEW_STATUSES.SCHEDULED,
        INTERVIEW_STATUSES.COMPLETED,
        INTERVIEW_STATUSES.REQUESTED,
      ];
    }
  };

  const refreshData = () => {
    onFilterChange(
      candidateNameFilter,
      statusCheckBoxFilter,
      colorAgeCheckBoxFilter,
      selectedStages,
      hiringProcessStatusCheckBoxFilter,
      selectedFromDate,
      selectedToDate,
      selectedInterviewers,
      selectedJobs,
      selectedSortingField,
      selectedSortingOrder
    );
  };

  const displayHiringProcessStatusColumn = () => {
    if (displayInterviewMode !== DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS) {
      return (
        <TableCell align="center">
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClickHiringProcessStatus}
            style={{ textTransform: 'none' }}
          >
            <Box display="flex" alignItems="center">
              <b>Status</b>
              {displayHiringProcessStatusFilterIcon()}
            </Box>
          </Button>
          <Menu
            elevation={4}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id="customized-menu"
            anchorEl={anchorElHiringProcessStatus}
            keepMounted
            open={Boolean(anchorElHiringProcessStatus)}
            onClose={handleCloseHiringProcessStatus}
          >
            <div
              style={{
                width: '250px',
                marginLeft: '16px',
                marginRight: '16px',
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">Filter by</Typography>
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    Status
                  </Typography>
                </Grid>
                <Grid item>{displaySortingHiringProcessStatus()}</Grid>
              </Grid>
              <div
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                {Object.values(HIRING_PROCESS_STATUSES).map((status) => (
                  <MenuItem
                    style={{
                      paddingLeft: 0,
                      marginLeft: 0,
                    }}
                    key={status}
                    value={status}
                    onClick={() =>
                      handleHiringProcessStatusCheckboxChange(status)
                    }
                  >
                    <Checkbox
                      style={{
                        paddingLeft: 0,
                        marginLeft: 0,
                      }}
                      color="primary"
                      checked={hiringProcessStatusCheckBoxFilter[status]}
                      name={status}
                    />
                    {capitalizeFirstLetter(status)}
                  </MenuItem>
                ))}
              </div>
            </div>
          </Menu>
        </TableCell>
      );
    }
  };

  const displayColorAgingColumn = () => {
    if (displayInterviewMode !== DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS) {
      return (
        <TableCell align="center">
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClickColorAge}
            style={{ textTransform: 'none' }}
          >
            <Box display="flex" alignItems="center">
              <b>Color Age</b>
              {displayColorAgeFilterIcon()}
            </Box>
          </Button>
          <Menu
            elevation={10}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id="customized-menu"
            anchorEl={anchorElColorAge}
            keepMounted
            open={Boolean(anchorElColorAge)}
            onClose={handleCloseColorAge}
          >
            <div
              style={{
                marginLeft: '16px',
                marginRight: '16px',
                width: '250px',
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">Filter by</Typography>
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    ColorAge
                  </Typography>
                </Grid>
              </Grid>
              <div
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                {Object.values(INTERVIEW_COLOR_AGES).map((status) => (
                  <MenuItem
                    style={{
                      paddingLeft: 0,
                      marginLeft: 0,
                    }}
                    key={status}
                    value={status}
                    onClick={() => handleColorAgeCheckboxChange(status)}
                  >
                    <Checkbox
                      style={{
                        paddingLeft: 0,
                        marginLeft: 0,
                      }}
                      checked={colorAgeCheckBoxFilter[status]}
                      name={status}
                      color="primary"
                    />
                    {capitalizeFirstLetter(status)}
                  </MenuItem>
                ))}
              </div>
            </div>
          </Menu>
        </TableCell>
      );
    }
  };

  const displayActionItemsColumn = () => {
    if (displayInterviewMode === DISPLAY_INTERVIEWS_MODE.HIREHAC_ADMIN) {
      return;
    }

    return (
      <TableCell align="center">
        <b>Actions</b>
      </TableCell>
    );
  };

  const displayInterviewerColumn = () => {
    if (displayInterviewMode !== DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS) {
      return (
        <TableCell align="center">
          <div>
            <Button
              ref={anchorRefInterviewer}
              aria-controls={stageOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickInterviewer}
              style={{ textTransform: 'none' }}
            >
              <Box display="flex" alignItems="center">
                <b>Interviewer</b>
                {displayInterviewerFilterIcon()}
              </Box>
            </Button>
            <Popper
              placement="bottom"
              open={interviewerOpen}
              anchorEl={anchorRefInterviewer.current}
              role={undefined}
              style={{ width: 350 }}
              transition
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseInterviewer}>
                      <div
                        style={{
                          marginLeft: '16px',
                          marginRight: '16px',
                        }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6">Filter by</Typography>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 600 }}
                            >
                              Interviewers
                            </Typography>
                          </Grid>
                          <Grid item>{displaySortingInterviewerName()}</Grid>
                        </Grid>
                        <div
                          style={{
                            marginTop: '16px',
                            marginBottom: '8px',
                          }}
                        >
                          <InterviewerFilter
                            selectedInterviewers={selectedInterviewers}
                            onFilterChange={handleInterviewerChange}
                            companyId={companyId}
                          />
                        </div>
                        <br />
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </TableCell>
      );
    }
  };

  function getInterviewSegment() {
    const currentStage = jobForSelectedInterview?.stages.find(
      (stage) => stage._id === selectedInterview?.stage
    );

    return currentStage?.interviewSegment?.find(
      (interviewSegment) =>
        interviewSegment._id === selectedInterview?.interviewSegment
    );
  }

  function displayFilterIcon() {
    return (
      <IconButton
        color="primary"
        style={{ pointerEvents: 'none', padding: '4px' }}
      >
        <FilterListIcon fontSize="small" />
      </IconButton>
    );
  }

  function displayCandidateFilterIcon() {
    if (candidateNameFilter && candidateNameFilter?.length > 0) {
      return displayFilterIcon();
    }
  }

  function displayJobFilterIcon() {
    if (selectedJobs && selectedJobs?.length > 0) {
      return displayFilterIcon();
    }
  }

  function displayStageFilterIcon() {
    if (selectedStages && selectedStages?.length > 0) {
      return displayFilterIcon();
    }
  }
  function displayInterviewStatusFilterIcon() {
    let filterApplied = false;
    for (const [key, value] of Object.entries(statusCheckBoxFilter)) {
      if (value) {
        filterApplied = true;
      }
    }

    if (filterApplied) {
      return displayFilterIcon();
    }
  }

  function displayColorAgeFilterIcon() {
    let filterApplied = false;
    for (const [key, value] of Object.entries(colorAgeCheckBoxFilter)) {
      if (value) {
        filterApplied = true;
      }
    }

    if (filterApplied) {
      return displayFilterIcon();
    }
  }

  function displayInterviewerFilterIcon() {
    if (selectedInterviewers && selectedInterviewers?.length > 0) {
      return displayFilterIcon();
    }
  }

  function displayStartTimeFilterIcon() {
    if (selectedFromDate || selectedToDate) {
      return displayFilterIcon();
    }
  }

  function displayHiringProcessStatusFilterIcon() {
    let filterApplied = false;
    for (const [key, value] of Object.entries(
      hiringProcessStatusCheckBoxFilter
    )) {
      if (value) {
        filterApplied = true;
      }
    }

    if (filterApplied) {
      return displayFilterIcon();
    }
  }

  function onRequestRescheduleCloseFromInterviewsList() {
    setConfirmRequestRescheduleOpen(false);
    setSelectedInterview(null);
    setJobForSelectedInterview(null);
  }

  return (
    <div>
      <br />
      {confirmRequestRescheduleOpen && (
        <AdminAgendaEditorConfirmRequestReschedule
          interviewSegment={getInterviewSegment()}
          refreshAgendaEditorData={refreshData}
          interview={selectedInterview}
          onClose={onRequestRescheduleCloseFromInterviewsList}
          user={user}
        />
      )}
      {confirmCancelInterviewOpen && (
        <AdminAgendaEditorConfirmCancelInterview
          refreshAgendaEditorData={refreshData}
          interview={selectedInterview}
          interviewSegment={getInterviewSegment()}
          onClose={onCancelInterviewClose}
          user={user}
        />
      )}
      {changeInterviewerOpen && (
        <AdminAgendaEditorChangeInterviewer
          refreshAgendaEditorData={refreshData}
          changeInterviewerMap={changeInterviewerMap}
          interview={selectedInterview}
          interviewSegment={getInterviewSegment()}
          onClose={onChangeInterviewerClose}
        />
      )}
      {changeInterviewerForRequestedInterviewOpen && (
        <AdminAgendaEditorChangeInterviewerRequestedInterview
          refreshAgendaEditorData={refreshData}
          changeInterviewerList={changeInterviewerForRequestedInterviewList}
          interview={selectedInterview}
          onClose={onChangeInterviewerForRequestedInterviewClose}
          refreshInterviewerAvailability={refreshInterviewerAvailability}
          user={user}
          interviewSegment={getInterviewSegment()}
        />
      )}
      {markAsNoShowOpen && (
        <AdminAgendaEditorMarkAsNoShow
          interviewSegment={getInterviewSegment()}
          refreshAgendaEditorData={refreshData}
          interview={selectedInterview}
          onClose={onMarkAsNoShowClose}
          selectCandidateOrInterviewerNoShow={
            displayInterviewMode ===
            DISPLAY_INTERVIEWS_MODE.ALL_COMPANY_INTERVIEWS
          }
        />
      )}
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <div>
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleClickName}
                      style={{ textTransform: 'none' }}
                    >
                      <Box display="flex" alignItems="center">
                        <b>Candidate</b>
                        {displayCandidateFilterIcon()}
                      </Box>
                    </Button>
                    <Popper
                      placement="bottom-start"
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      style={{ width: 350 }}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                          <Paper elevation={10}>
                            <ClickAwayListener onClickAway={handleCloseName}>
                              <div
                                style={{
                                  marginLeft: '16px',
                                  marginRight: '16px',
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography variant="h6">
                                      Filter by
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: 600 }}
                                    >
                                      Candidate
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    {displaySortingCandidateName()}
                                  </Grid>
                                </Grid>
                                <div
                                  style={{
                                    marginTop: '16px',
                                    marginBottom: '8px',
                                  }}
                                >
                                  <NameFilter
                                    selectedNames={candidateNameFilter}
                                    onFilterChange={
                                      handleCandidatNameFilterChange
                                    }
                                    displayInterviewMode={displayInterviewMode}
                                    companyId={companyId}
                                  />
                                </div>
                                <br />
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </TableCell>
                {displayHiringProcessStatusColumn()}
                <TableCell align="center">
                  <div>
                    <Button
                      ref={anchorRefJob}
                      aria-controls={stageOpen ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleClickJob}
                      style={{ textTransform: 'none' }}
                    >
                      <Box display="flex" alignItems="center">
                        <b>Job</b>
                        {displayJobFilterIcon()}
                      </Box>
                    </Button>
                    <Popper
                      open={jobOpen}
                      anchorEl={anchorRefJob.current}
                      role={undefined}
                      style={{ width: 350 }}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                          <Paper elevation={10}>
                            <ClickAwayListener onClickAway={handleCloseJob}>
                              <div
                                style={{
                                  marginLeft: '16px',
                                  marginRight: '16px',
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography variant="h6">
                                      Filter by
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: 600 }}
                                    >
                                      Job
                                    </Typography>
                                  </Grid>
                                  <Grid item>{displaySortingJobTitle()}</Grid>
                                </Grid>
                                <div
                                  style={{
                                    marginTop: '16px',
                                    marginBottom: '8px',
                                  }}
                                >
                                  <JobFilter
                                    selectedJobs={selectedJobs}
                                    onFilterChange={handleJobChange}
                                    companyId={companyId}
                                    displayInterviewMode={displayInterviewMode}
                                  />
                                </div>
                                <br />
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    <Button
                      ref={anchorRefStage}
                      aria-controls={stageOpen ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleClickStage}
                      style={{ textTransform: 'none' }}
                    >
                      <Box display="flex" alignItems="center">
                        <b>Stage</b>
                        {displayStageFilterIcon()}
                      </Box>
                    </Button>
                    <Popper
                      open={stageOpen}
                      anchorEl={anchorRefStage.current}
                      role={undefined}
                      style={{ width: 350 }}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                          <Paper>
                            <ClickAwayListener onClickAway={handleCloseStage}>
                              <div
                                style={{
                                  marginLeft: '16px',
                                  marginRight: '16px',
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography variant="h6">
                                      Filter by
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: 600 }}
                                    >
                                      Stage
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <div
                                  style={{
                                    marginTop: '16px',
                                    marginBottom: '8px',
                                  }}
                                >
                                  <StageFilter
                                    selectedStages={selectedStages}
                                    onFilterChange={handleStageChange}
                                    companyId={companyId}
                                    displayInterviewMode={displayInterviewMode}
                                  />
                                </div>
                                <br />
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={{ textTransform: 'none' }}
                  >
                    <b>Interview Status</b>
                    {displayInterviewStatusFilterIcon()}
                  </Button>
                  <Menu
                    elevation={10}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <div
                      style={{
                        marginLeft: '16px',
                        marginRight: '16px',
                        width: '250px',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="h6">Filter by</Typography>
                          <Typography variant="h6" style={{ fontWeight: 600 }}>
                            Interview Status
                          </Typography>
                        </Grid>
                        <Grid item>{displaySortingInterviewStatus()}</Grid>
                      </Grid>
                      <div
                        style={{
                          marginTop: '16px',
                          marginBottom: '8px',
                        }}
                      >
                        {interviewStatusToDisplayList().map((status) => (
                          <MenuItem
                            style={{
                              paddingLeft: 0,
                              marginLeft: 0,
                            }}
                            key={status}
                            value={status}
                            onClick={() => handleStatusCheckboxChange(status)}
                          >
                            <Checkbox
                              style={{
                                paddingLeft: 0,
                                marginLeft: 0,
                              }}
                              color="primary"
                              checked={statusCheckBoxFilter[status]}
                              name={status}
                            />
                            {capitalizeFirstLetter(status)}
                          </MenuItem>
                        ))}{' '}
                      </div>
                    </div>
                  </Menu>
                </TableCell>
                {displayColorAgingColumn()}
                <TableCell align="center">
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={handleClickStartTime}
                    style={{ textTransform: 'none' }}
                  >
                    <Box display="flex" alignItems="center">
                      <b>Day/Time</b>
                      {displayStartTimeFilterIcon()}
                    </Box>
                  </Button>
                  <Menu
                    elevation={10}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    id="customized-menu"
                    anchorEl={anchorElStartTime}
                    keepMounted
                    open={Boolean(anchorElStartTime)}
                    onClose={handleCloseStartTime}
                  >
                    <div
                      style={{
                        marginLeft: '16px',
                        marginRight: '16px',
                        width: '400px',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="h6">Filter by</Typography>
                          <Typography variant="h6" style={{ fontWeight: 600 }}>
                            Date/Time
                          </Typography>
                        </Grid>
                        <Grid item>{displaySortingStartTime()}</Grid>
                      </Grid>
                      <div
                        style={{
                          marginTop: '16px',
                          marginBottom: '8px',
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography style={{ fontWeight: 600 }}>
                              From:
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Box
                                display="flex"
                                alignItems="center"
                                maxWidth="180px"
                                marginRight="8px"
                              >
                                <KeyboardDatePicker
                                  className={classes.calendar}
                                  inputVariant="outlined"
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  value={selectedFromDate}
                                  onChange={handleFromDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Box>
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography
                              style={{
                                visibility: 'hidden',
                                width: '0px',
                                marginTop: '8px',
                              }}
                            >
                              Hidden text
                            </Typography>
                            &mdash;
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography style={{ fontWeight: 600 }}>
                              To:
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div
                                style={{
                                  maxWidth: '164px',
                                  marginLeft: '8px',
                                }}
                              >
                                <KeyboardDatePicker
                                  className={classes.calendar}
                                  inputVariant="outlined"
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  value={selectedToDate}
                                  onChange={handleToDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </div>
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Menu>
                </TableCell>
                {displayInterviewerColumn()}
                {displayActionItemsColumn()}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!myInterviews?.length &&
                myInterviews?.map((interview) => (
                  <TableRow key={interview._id}>
                    <TableCell component="th" scope="row" align="center">
                      {displayCandidateName(interview)}
                    </TableCell>
                    {displayInterviewMode !==
                      DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS && (
                      <TableCell align="center">
                        {displayHiringProcessStatus(interview)}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Typography>{interview?.job?.title}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {displayInterviewStage(interview)}
                    </TableCell>
                    <TableCell align="center">
                      {displayInterviewStatus(interview)}
                    </TableCell>
                    {displayInterviewMode !==
                      DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS && (
                      <TableCell align="center">
                        {displayColorAging(interview)}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {displayInterviewStartTime(interview)}
                    </TableCell>
                    {displayInterviewMode !==
                      DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS && (
                      <TableCell align="center">
                        {displayInterviewer(interview)}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {displayInterviewActionItems(interview)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

import React, { FunctionComponent } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { WEEK_DAYS } from '@er/data-hiring';
import classNames from 'classnames';

export interface IAvailabilityEditorWeekDayProps {
  day: WEEK_DAYS;
  selected?: boolean;
  onToggle?: (day: WEEK_DAYS, selected: boolean) => any;
}

const useStyles = makeStyles((theme) => ({
  tile: {
    display: 'inline-block',
    cursor: 'pointer',
    width: 50,
    height: 35,
    textAlign: 'center',
    borderBottom: `3px solid transparent`,
    lineHeight: '35px',
    color: theme.palette.text.secondary,
    '&.selected': {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      color: theme.palette.text.primary,
    },
  },
}));

export const AvailabilityEditorWeekDay: FunctionComponent<IAvailabilityEditorWeekDayProps> = ({
  day,
  selected = false,
  onToggle,
}) => {
  const classes = useStyles();
  const rootClass = classNames(classes.tile, { selected });

  const handleClick = (evt) => {
    evt.preventDefault();
    if (onToggle) {
      return onToggle(day, !selected);
    }
  };

  return (
    <Typography onClick={handleClick} className={rootClass} variant="body1">
      {WEEK_DAYS[day]?.charAt(0).toUpperCase()}
    </Typography>
  );
};

import React, { FunctionComponent } from 'react';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import {
  Grid,
  makeStyles,
  SvgIconProps,
  Theme,
  Typography,
  TypographyProps,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneContent: {
    height: '100%',
    textAlign: 'center',
  },
}));

export interface IFileDropzoneContentProps {
  Icon?: typeof NoteAddOutlinedIcon;
  title?: string;
  subtitle?: string;
  colorIcon?: SvgIconProps['color'];
  colorText?: TypographyProps['color'];
}

export const FileDropzoneContent: FunctionComponent<IFileDropzoneContentProps> =
  ({
    title = 'File Upload',
    subtitle,
    Icon = NoteAddOutlinedIcon,
    colorIcon = 'primary',
    colorText = 'textPrimary',
  }) => {
    const classes = useStyles();
    return (
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        className={classes.dropzoneContent}
      >
        <Grid item>
          <Typography
            variant="h5"
            color={colorText}
            style={{ marginBottom: '32px', marginTop: '16px' }}
          >
            {title}
          </Typography>
          <Icon color={colorIcon} fontSize="large" />
          {!!subtitle && (
            <div style={{ marginTop: '32px', marginBottom: '16px' }}>
              <Typography
                variant="subtitle1"
                color={colorText}
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                Select file or drag and drop here
              </Typography>
              <Typography variant="subtitle1" color={colorText}>
                {subtitle}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles<Theme>((theme) => ({
  step: {
    width: 32,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    opacity: 0.2,
    '&.active': {
      opacity: 1,
    },
  },
}));

type StepProps = InferProps<typeof Step.propTypes>;

function Step(props: StepProps) {
  const classes = useStyles();
  const className = classNames({
    [`${classes.step}`]: true,
    active: props.active,
  });
  return <div className={className}></div>;
}

Step.propTypes = {
  active: PropTypes.bool,
};

Step.defaultProps = {
  active: false,
};

export type StepperProps = InferProps<typeof Stepper.propTypes>;

export function Stepper(props: StepperProps) {
  const { steps, current, exclusive } = props;
  const stepElements = [];
  for (let i = 1; i <= steps; i++) {
    stepElements.push(
      <Grid key={i} item>
        <Step active={exclusive ? i === current : i <= current} />
      </Grid>
    );
  }
  return (
    <Grid container direction="row" justifyContent="center" spacing={1}>
      {stepElements}
    </Grid>
  );
}

Stepper.propTypes = {
  steps: PropTypes.number.isRequired,
  current: PropTypes.number,
  exclusive: PropTypes.bool.isRequired,
};

Stepper.defaultProps = {
  current: 1,
  exclusive: true,
};

export default Stepper;

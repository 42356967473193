import { uid } from 'rand-token';
import {
  IInterview,
  InterviewModel,
  INTERVIEW_CANCEL_REASONS,
  INTERVIEW_REQUEST_REASONS,
  INTERVIEW_STATUSES,
} from '../interview';
import { IUser, UserModel } from '../user';
import { HiringProcessModel } from './hiring-process.model';
import { STAGE_INTERVIEWER_ROTATION } from '../stage';
import { JobModel } from '../job';

export function getRequestText(
  requestReason: INTERVIEW_REQUEST_REASONS,
  interview: IInterview | null = null,
  requestedBy: IUser | null
) {
  let requestText = '';

  const requestedByName = requestedBy?.firstName + ' ' + requestedBy?.lastName;

  switch (requestReason) {
    case INTERVIEW_REQUEST_REASONS.NEW_STAGE:
      requestText = `Candidate was requested by ${requestedByName}: `;
      break;
    case INTERVIEW_REQUEST_REASONS.COMPANY_REQUEST_RESCHEDULE:
      requestText = `${requestedByName} requested reschedule: `;
      break;
    case INTERVIEW_REQUEST_REASONS.INTERVIEWER_DECLINE_CALENDAR:
      requestText = `Interviewer ${requestedByName} declined interview from calendar: `;
      break;
    case INTERVIEW_REQUEST_REASONS.CANDIDATE_DECLINE_CALENDAR:
      requestText = `Candidate declined interview from calendar: `;
      break;
    case INTERVIEW_REQUEST_REASONS.INTERVIEWER_MARKED_NO_SHOW_ADMIN:
      let interviewerName = '';
      if (interview && interview.interviewers && interview.interviewers[0]) {
        interviewerName =
          interview.interviewers[0]?.firstName +
          ' ' +
          interview.interviewers[0]?.lastName;
      }
      requestText = `${requestedByName} marked ${interviewerName} as no show: `;
      break;
    case INTERVIEW_REQUEST_REASONS.INTERVIEWER_MARKED_NO_SHOW_CANDIDATE:
      requestText = `Candidate marked ${requestedByName} as no show: `;
      break;
  }

  return requestText;
}

async function assignInterviewerForEvenDistribution(
  evenDistributionInterviewerList,
  interview,
  jobId,
  nextInterviewer,
  currentStage,
  interviewSegment,
  session?
) {
  evenDistributionInterviewerList = evenDistributionInterviewerList.filter(
    (interviewer) => {
      return !nextInterviewer._id.equals(interviewer);
    }
  );

  interview.interviewers = [nextInterviewer._id];
  await interview.save({ session });

  await JobModel.updateOne(
    { _id: jobId },
    {
      $set: {
        'stages.$[stage].interviewSegment.$[segment].evenDistributionInterviewerList':
          evenDistributionInterviewerList,
      },
    },
    {
      arrayFilters: [
        { 'stage._id': currentStage._id },
        { 'segment._id': interviewSegment._id },
      ],
      session: session,
    }
  );

  return [nextInterviewer];
}

export async function findNextInterviewerForEvenDistribution(
  interview,
  jobId,
  currentStage,
  interviewSegment,
  session?
) {
  let [evenDistributionInterviewerList, nextInterviewer] =
    await getFirstInterviewerAndEvenDistributionList(interviewSegment, session);

  return await assignInterviewerForEvenDistribution(
    evenDistributionInterviewerList,
    interview,
    jobId,
    nextInterviewer,
    currentStage,
    interviewSegment,
    session
  );
}

async function getFirstInterviewerAndEvenDistributionList(
  interviewSegment,
  session
) {
  let evenDistributionInterviewerList = [];

  if (
    !interviewSegment?.evenDistributionInterviewerList ||
    interviewSegment?.evenDistributionInterviewerList?.length === 0
  ) {
    evenDistributionInterviewerList = interviewSegment?.interviewers;
  } else {
    evenDistributionInterviewerList =
      interviewSegment?.evenDistributionInterviewerList;
  }

  let interviewer = await UserModel.findById({
    _id: evenDistributionInterviewerList[0],
  }).session(session);

  if (!interviewer) {
    // the interviewer should always exist, but in an unexpected situation where the interviewer is not found, we will instantiate the evenDistributionInterviewerList again and try to find the next interviewer

    evenDistributionInterviewerList = interviewSegment?.interviewers;
    interviewer = await UserModel.findById({
      _id: evenDistributionInterviewerList[0],
    }).session(session);
    while (!interviewer && evenDistributionInterviewerList.length > 0) {
      evenDistributionInterviewerList.shift();
      interviewer = await UserModel.findById({
        _id: evenDistributionInterviewerList[0],
      }).session(session);
    }
  }

  return [evenDistributionInterviewerList, interviewer];
}

export function getCancellationText(
  cancelReason: INTERVIEW_CANCEL_REASONS,
  canceledBy: IUser
) {
  let cancelText = '';

  const canceledByName = canceledBy?.firstName + ' ' + canceledBy?.lastName;

  switch (cancelReason) {
    case INTERVIEW_CANCEL_REASONS.ADMIN_CANCEL:
      cancelText = `${canceledByName} canceled interview: `;
      break;
    case INTERVIEW_CANCEL_REASONS.CANDIDATE_NO_SHOW:
      cancelText = `Candidate was marked as no show by ${canceledByName}: `;
      break;
    case INTERVIEW_CANCEL_REASONS.NOT_FIRST_TIME_ON_THIS_STAGE:
      cancelText = `Candidate was reactivated from dispositioned.`;
      break;
  }

  return cancelText;
}

export function evenDistributionStage(stage) {
  return (
    stage &&
    stage?.interviewerRotation === STAGE_INTERVIEWER_ROTATION.EVEN_DISTRIBUTION
  );
}

export class HiringProcessService {
  static async createRequestedInterviewsForStage(
    hiringProcess,
    currentStageStructure,
    requestedBy,
    session?
  ) {
    const interviews = [];

    for (const interviewSegment of currentStageStructure.interviewSegment) {
      const requestInformation = getRequestText(
        INTERVIEW_REQUEST_REASONS.NEW_STAGE,
        null,
        requestedBy
      );

      let interviewers = [];
      let singleInterviewerOnStage = false;

      if (interviewSegment && interviewSegment?.interviewers?.length === 1) {
        interviewers = [
          await UserModel.findById(interviewSegment.interviewers[0]),
        ];
        singleInterviewerOnStage = true;
      }

      const interview = (
        await InterviewModel.create(
          [
            {
              title: interviewSegment.focusArea,
              job: hiringProcess.job,
              hiringProcess: hiringProcess._id,
              status: INTERVIEW_STATUSES.REQUESTED,
              candidate: hiringProcess.candidate,
              interviewSegment: interviewSegment._id,
              stage: currentStageStructure._id,
              statusChangedAt: new Date(),
              company: hiringProcess.job.company,
              requestReason: INTERVIEW_REQUEST_REASONS.NEW_STAGE,
              requestAtUTC: new Date(),
              requestInformation: requestInformation,
              interviewers: interviewers,
              meetingLink: '',
            },
          ],
          { session: session }
        )
      )?.[0];

      if (
        evenDistributionStage(currentStageStructure) &&
        !singleInterviewerOnStage
      ) {
        await findNextInterviewerForEvenDistribution(
          interview,
          hiringProcess.job._id,
          currentStageStructure,
          interviewSegment,
          session
        );
      }

      interviews.push(interview);
    }

    await HiringProcessModel.updateOne(
      {
        _id: hiringProcess._id,
        'stages.stage': { $ne: currentStageStructure._id },
      },
      {
        $push: {
          stages: { stage: currentStageStructure._id, interviews: [] },
        },
      },
      { session: session }
    );

    const result = await HiringProcessModel.updateOne(
      { _id: hiringProcess._id, 'stages.stage': currentStageStructure._id },
      {
        $addToSet: {
          'stages.$.interviews': interviews,
        },
      },
      { session: session }
    );
  }
}

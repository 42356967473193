import mongoose, { model, Schema } from 'mongoose';
import { FILE_UPLOAD_PURPOSE } from './file.constants';
import { IFile, IFileModel } from './file.interface';

export const FileSchema: Schema<IFile, IFileModel> = new Schema(
  {
    mime: { type: String, required: true },
    name: { type: String, required: true },
    size: { type: Number, required: true },
    location: { type: String, required: true },
    purpose: {
      type: String,
      enum: Object.values(FILE_UPLOAD_PURPOSE),
    },
    uploadedBy: { type: Schema.Types.ObjectId, ref: 'User', required: true },
  },
  {
    timestamps: true,
  }
);

export const FileModel: IFileModel = (mongoose.models?.File ||
  model('File', FileSchema)) as IFileModel;

import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      zIndex: 10000,
    },
    textColorAndOpacity: {
      color: '#0D293A',
      opacity: 0.85,
      fontFamily: '"Open Sans", sans-serif',
    },
  })
);

export default function CustomAvatar({
  interview,
  mustDisplayLinkedInIcon = false,
}) {
  const classes = useStyles();

  const linkedInIconClicked = (linkedInURL) => {
    const absoluteUrl = linkedInURL.startsWith('http')
      ? linkedInURL
      : `https://${linkedInURL}`;

    window.open(absoluteUrl, '_blank');
  };

  const displayLinkedInIcon = (interview) => {
    const interviewer = interview?.interviewers?.[0];

    if (interviewer?.linkedInURL) {
      return (
        <IconButton
          onClick={(event) => linkedInIconClicked(interviewer?.linkedInURL)}
          style={{ color: '#0072b1', padding: 0 }}
        >
          <LinkedInIcon />
        </IconButton>
      );
    }

    return (
      <IconButton style={{ padding: 0 }} disabled>
        <LinkedInIcon />
      </IconButton>
    );
  };

  return (
    <Grid item>
      <Grid container wrap="nowrap" style={{ height: '100%' }}>
        <Grid item>
          <Avatar
            style={{
              backgroundColor: 'primary',
              width: 50,
              height: 50,
            }}
          >
            {interview?.interviewers?.[0].firstName?.[0] +
              interview?.interviewers?.[0]?.lastName?.[0]}
          </Avatar>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item container direction="column" wrap="nowrap">
          <Grid item>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Typography
                  variant="h6"
                  className={classes.textColorAndOpacity}
                  style={{
                    marginTop: '-6px',
                    marginLeft: '-8px',
                  }}
                >
                  &nbsp; &nbsp;
                  {interview?.interviewers?.[0].firstName +
                    ' ' +
                    interview?.interviewers?.[0]?.lastName}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  paddingLeft: '4px',
                  marginTop: '-4px',
                }}
              >
                {mustDisplayLinkedInIcon
                  ? displayLinkedInIcon(interview)
                  : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              className={classes.textColorAndOpacity}
              style={{ paddingLeft: '8px' }}
            >
              {interview?.interviewers?.[0]?.jobTitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

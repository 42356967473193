import React, { FunctionComponent, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { Card, CardHeader, CssBaseline } from '@material-ui/core';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/client';
import { closedDrawerWidth } from '@er/ui-lib';
import CandidatePortalSideBar from '@er/ui-lib/lib/common/shared/candidate-portal-sidebar';

export interface ICandidatePortalLayoutProps {
  currentPage?: string;
  title?: string;
  error?: string;
  ready?: boolean;
  loading?: boolean;
}

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    appBar: {
      marginLeft: closedDrawerWidth,
      width: `calc(100% - ${closedDrawerWidth}px)`,
      zIndex: theme.zIndex.drawer,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: -20,
    },
    hide: {
      display: 'none',
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    contentSection: {
      flexGrow: 1,
      padding: theme.spacing(0, 3),
    },

    contentPadding: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },

    grow: {
      flexGrow: 1,
    },

    title: {},
  })
);

export const CandidatePortalLayout: FunctionComponent<ICandidatePortalLayoutProps> =
  ({ children, error = false, loading = false, ready = true, title }) => {
    const classes = useStyles();
    const router = useRouter();

    const [open, setOpen] = useState(true);

    const handleLogOutClick = async () => {
      await signOut({ redirect: false });

      router.push('/users/logout');
    };

    return (
      <div>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            elevation={0}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            ></Toolbar>
            <Divider />
          </AppBar>
          <CandidatePortalSideBar open={open} />
          <div className={classes.contentSection}>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {title && (
                <Card className={classes.title}>
                  <CardHeader title={title} />
                </Card>
              )}
              {children}
            </main>
          </div>
        </div>
      </div>
    );
  };

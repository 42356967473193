import { Tab, Tabs, Typography } from '@material-ui/core';
import NextLink from 'next/link';
import React from 'react';

export enum JOB_PAGE_TABS {
  BOARD = 0,
  INFO = 1,
  INVITES = 2,
  HIRED = 3,
  DISPOSITIONED = 4,
}

export function JobPageTabs({ job, tabId = JOB_PAGE_TABS.BOARD }) {
  return (
    <Tabs
      value={tabId}
      indicatorColor="primary"
      textColor="primary"
      aria-label="disabled tabs example"
    >
      <NextLink href={`/jobs/[id]`} as={`/jobs/${job._id}`} passHref>
        <Tab
          style={{ textTransform: 'none' }}
          component="a"
          value={JOB_PAGE_TABS.BOARD}
          label={<Typography>Interview Board</Typography>}
        />
      </NextLink>
      <NextLink href={`/jobs/[id]/info`} as={`/jobs/${job._id}/info`} passHref>
        <Tab
          style={{ textTransform: 'none' }}
          component="a"
          label={<Typography>Job Info</Typography>}
          value={JOB_PAGE_TABS.INFO}
        />
      </NextLink>
      <NextLink
        href={`/jobs/[id]/invites`}
        as={`/jobs/${job._id}/invites`}
        passHref
      >
        <Tab
          style={{ textTransform: 'none' }}
          component="a"
          label={<Typography>Invites</Typography>}
          value={JOB_PAGE_TABS.INVITES}
        />
      </NextLink>
      <NextLink
        href={`/jobs/[id]/hired`}
        as={`/jobs/${job._id}/hired`}
        passHref
      >
        <Tab
          style={{ textTransform: 'none' }}
          component="a"
          label={<Typography>Hired</Typography>}
          value={JOB_PAGE_TABS.HIRED}
        />
      </NextLink>
      <NextLink
        href={`/jobs/[id]/disposition`}
        as={`/jobs/${job._id}/disposition`}
        passHref
      >
        <Tab
          style={{ textTransform: 'none' }}
          component="a"
          label={<Typography>Dispositioned</Typography>}
          value={JOB_PAGE_TABS.DISPOSITIONED}
        />
      </NextLink>
    </Tabs>
  );
}

import {
  IEventAvailabilityOption,
  IHiringProcess,
  IInterviewSegment,
  INTERVIEW_STATUSES,
} from '@er/data-hiring';
import { Box, Button, Grid, Theme, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { displayHourAndMinutesInUserTimezone } from 'apps/hiring/pages/api/utils/utils';

export interface IScheduleInterviewPanelSlotProps {
  availability?: Map<string, Partial<IEventAvailabilityOption>[]>;

  hiringProcess: IHiringProcess;
  selectedTimeMap: Map<string, IEventAvailabilityOption>;
  interviewSegment: IInterviewSegment;
  date?: any;
  handleChangeTimeClicked?: (interviewSegment: IInterviewSegment) => any;
  handleAvailabilitySelected?: (availability, interviewSegment) => any;
}

export const ScheduleInterviewPanelSlot: FunctionComponent<IScheduleInterviewPanelSlotProps> =
  ({
    interviewSegment,
    handleChangeTimeClicked,
    handleAvailabilitySelected,
    selectedTimeMap,
    availability = new Map(),
    date,
    hiringProcess,
  }) => {
    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    let currentInterview;

    if (hiringProcessStage) {
      currentInterview = hiringProcessStage.interviews.find(
        (interview) => interview.interviewSegment === interviewSegment._id
      );
    }

    const buildComponentWithSelectedTime = (interviewSegment) => {
      return (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography style={{ color: '#000000', opacity: 0.55 }}>
              <strong>Day: </strong>{' '}
              {displayHourAndMinutesInUserTimezone(
                selectedTimeMap[interviewSegment._id].startDateUTC,
                hiringProcess.candidate
              ).format('MMMM-DD-YYYY')}
            </Typography>
            <Typography style={{ color: '#000000', opacity: 0.55 }}>
              <strong>Time: </strong>{' '}
              {displayHourAndMinutesInUserTimezone(
                selectedTimeMap[interviewSegment._id].startDateUTC,
                hiringProcess.candidate
              ).format('h:mm a')}{' '}
              -{' '}
              {displayHourAndMinutesInUserTimezone(
                selectedTimeMap[interviewSegment._id].endDateUTC,
                hiringProcess.candidate
              ).format('h:mm a')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleChangeTimeClicked(interviewSegment)}
              color="primary"
            >
              EDIT DATE/TIME
            </Button>
          </Grid>
        </Grid>
      );
    };

    const buildComponentWithAvailableTimes = (interviewSegment) => {
      return (
        <ul>
          {availability[interviewSegment._id]
            .filter((av) => moment(av.startDateUTC).isSame(date, 'date'))
            .map((av) => (
              <Button
                key={moment.utc(av.startDateUTC).toDate().getTime()}
                variant={'outlined'}
                onClick={() => handleAvailabilitySelected(av, interviewSegment)}
                color="primary"
                style={{
                  border: '1.5px solid #71A7A6',
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  paddingBottom: '2px',
                  paddingTop: '2px',
                  marginRight: '6px',
                  marginBottom: '6px',
                  maxWidth: '160px',
                  width: '100%',
                  borderRadius: '6px',
                }}
              >
                <div>
                  {displayHourAndMinutesInUserTimezone(
                    av.startDateUTC,
                    hiringProcess.candidate
                  ).format('h:mm a')}{' '}
                  -{' '}
                  {displayHourAndMinutesInUserTimezone(
                    av.endDateUTC,
                    hiringProcess.candidate
                  ).format('h:mm a')}
                </div>
              </Button>
            ))}
        </ul>
      );
    };

    let component;

    if (
      currentInterview &&
      currentInterview.status == INTERVIEW_STATUSES.COMPLETED
    ) {
      component = <div>You have already completed this interview.</div>;
    } else if (
      currentInterview &&
      currentInterview.status == INTERVIEW_STATUSES.CANCELED
    ) {
      component = (
        <div>
          Interview was canceled by an administrator. Please await further
          instructions.
        </div>
      );
    } else if (selectedTimeMap && selectedTimeMap[interviewSegment._id]) {
      // there is a selected time for current interview segment

      component = buildComponentWithSelectedTime(interviewSegment);
    } else if (
      date &&
      availability[interviewSegment._id].filter((av) =>
        moment(av.startDateUTC).isSame(date, 'date')
      ).length > 0
    ) {
      component = buildComponentWithAvailableTimes(interviewSegment);
    } else if (date) {
      component = (
        <Typography align="center" style={{ color: '#000000', opacity: 0.35 }}>
          There are no available times for this day.
          <br />
          Please select a different date.
        </Typography>
      );
    } else {
      component = (
        <Typography align="center" style={{ color: '#000000', opacity: 0.35 }}>
          Times will appear when days are selected.
        </Typography>
      );
    }

    return component;
  };

export default ScheduleInterviewPanelSlot;

import { Grid, GridSize, GridSpacing } from '@material-ui/core';
import React, { Children, FC, ReactNode } from 'react';

const DEFAULT_COLS = 1;
const MAX_COLS = 12;
const MIN_COLS = 1;

export type GridItemListCols = 'auto' | 1 | 2 | 3 | 4 | 6 | 12;

export type GridItemListLayoutProps = {
  cols?: GridItemListCols;
  colsXL?: GridItemListCols;
  colsLG?: GridItemListCols;
  colsMD?: GridItemListCols;
  colsSM?: GridItemListCols;
  colsXS?: GridItemListCols;
  spacing?: GridSpacing;
  emptyList?: ReactNode;
  item?: boolean;
  itemClass?: string;
  listClass?: string;
};

const normalizeCols = (
  cols: GridItemListCols,
  defaultCols: GridItemListCols = DEFAULT_COLS
): GridItemListCols => {
  if (!cols) {
    cols = defaultCols;
  }

  if (typeof cols === 'string') {
    return cols;
  }

  return Math.max(Math.min(cols, MAX_COLS), MIN_COLS) as GridItemListCols;
};

const colToWidth = (col: GridItemListCols): GridSize => {
  if (typeof col === 'string') {
    return col;
  }

  return Math.floor(MAX_COLS / col) as GridSize;
};

const colsToWidth = (
  cols: GridItemListCols,
  colsXL?: GridItemListCols,
  colsLG?: GridItemListCols,
  colsMD?: GridItemListCols,
  colsSM?: GridItemListCols,
  colsXS?: GridItemListCols
): {
  xl: GridSize;
  lg: GridSize;
  md: GridSize;
  sm: GridSize;
  xs: GridSize;
} => {
  cols = normalizeCols(cols);
  colsXL = normalizeCols(colsXL, cols);
  colsLG = normalizeCols(colsLG, colsXL);
  colsMD = normalizeCols(colsMD, colsLG);
  colsSM = normalizeCols(colsSM, colsMD);
  colsXS = normalizeCols(colsXS, colsSM);
  return {
    xl: colToWidth(colsXL),
    lg: colToWidth(colsLG),
    md: colToWidth(colsMD),
    sm: colToWidth(colsSM),
    xs: colToWidth(colsXS),
  };
};

export const GridItemListLayout: FC<GridItemListLayoutProps> = ({
  cols = 'auto',
  colsXL,
  colsLG,
  colsMD,
  colsSM,
  colsXS,
  emptyList,
  spacing = 2,
  children,
  item = false,
  itemClass,
  listClass,
}) => {
  const sizes = colsToWidth(cols, colsXL, colsLG, colsMD, colsSM, colsXS);
  if (!children) {
    return <>{emptyList}</>;
  }
  return (
    <Grid
      container
      item={item}
      direction="row"
      spacing={spacing}
      className={listClass}
    >
      {Children.map(children, (child) => (
        <Grid item {...sizes} className={itemClass}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

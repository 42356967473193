import { addDays, isBefore } from 'date-fns';
import { pick } from 'lodash';
import mongoose, { model, Schema } from 'mongoose';
import { uid } from 'rand-token';
import { USER_TYPES } from '../user';
import { INVITE_STATUSES } from './invite.constants';
import {
  IInviteModel,
  ICreateInviteOptions,
  IInviteDocument,
} from './invite.interface';
import crypto from 'crypto';

export const INVITE_TOKEN_DEFAULT_DAYS = 10;
export const INVITE_TOKEN_LENGTH = 20;

export const InviteSchema: Schema<IInviteDocument, IInviteModel> = new Schema(
  {
    token: { type: String, required: true },
    type: {
      type: String,
      required: true,
      enum: Object.values(USER_TYPES),
    },
    user: { ref: 'User', type: Schema.Types.ObjectId },
    job: { type: Schema.Types.ObjectId, ref: 'Job' },
    expiresAt: { type: Date, required: true },
    status: {
      type: String,
      enum: Object.values(INVITE_STATUSES),
      default: INVITE_STATUSES.ACTIVE,
      required: true,
    },
    createdBy: {
      ref: 'User',
      type: Schema.Types.ObjectId,
      required: true,
    },
    verificationEmailAddress: { type: String },
    verificationToken: { type: String },
    verificationTimeZone: { type: String },
    verificationFirstName: { type: String },
    verificationLastName: { type: String },
  },
  { timestamps: true }
);

InviteSchema.methods.isActive = function (this: IInviteDocument): boolean {
  return (
    [INVITE_STATUSES.ACCEPTED, INVITE_STATUSES.EXERCISED].includes(
      this.status
    ) ||
    ((!this.expiresAt || isBefore(Date.now(), this.expiresAt)) &&
      this.status === INVITE_STATUSES.ACTIVE)
  );
};

InviteSchema.statics.createInvite = async function createInvite(
  this: IInviteModel,
  options: ICreateInviteOptions = {
    type: USER_TYPES.CANDIDATE,
    createdBy: '',
  }
) {
  const token = crypto.randomBytes(INVITE_TOKEN_LENGTH / 2).toString('hex');

  const status = options.status || INVITE_STATUSES.ACTIVE;
  const type = options.type;
  const expiresAt =
    options.expiresAt ||
    addDays(new Date(), options.tokenDays || INVITE_TOKEN_DEFAULT_DAYS);
  const invite = await this.create({
    ...pick(options, ['job', 'jobProcess', 'user', 'createdBy']),
    expiresAt,
    type,
    token,
    status,
  });
  return invite;
};

export const InviteModel: IInviteModel = (mongoose.models?.Invite ||
  model('Invite', InviteSchema)) as IInviteModel;

import {
  IconButton,
  Box,
  makeStyles,
  Button,
  Menu,
  MenuItem,
  Grid,
  Divider,
  Typography,
} from '@material-ui/core';
import Board from 'react-trello';
import { MovableCardWrapper } from 'react-trello/dist/styles/Base';
import AddIcon from '@material-ui/icons/Add';
import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import BaseLayout, {
  closedDrawerWidth,
  drawerWidth,
} from '../../../../../libs/ui-lib/src/lib/common/shared/base-layout';
import { ShowJob } from '../../../../../libs/ui-lib/src/lib/common/jobs/job-page';
import { NextPageContext } from 'next';
import { withRequestContext } from 'apps/hiring/middleware/withRequestContext';
import absoluteUrl from 'next-absolute-url';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import FlashOffIcon from '@material-ui/icons/FlashOff';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {
  IJob,
  IJobDocument,
  INTERVIEW_COLOR_AGES,
  INTERVIEW_STATUSES,
  INTERVIEW_TYPES,
  IStage,
  IUser,
  JOB_STATUSES,
  STAGE_INTERVIEWER_ROTATION,
} from '@er/data-hiring';
import {
  AddStage,
  AddStageFormData,
  AdminAgendaEditor,
  JOB_PAGE_TABS,
  PanelType,
} from '../../../../../libs/ui-lib/src/lib/common/jobs';
import {
  OneOnOneStageDataForm,
  SetupOneOnOneStage,
} from '../../../../../libs/ui-lib/src/lib/common/jobs/one-on-one-stage-setting';
import {
  SetupPanelStage,
  StageDataForm,
} from '../../../../../libs/ui-lib/src/lib/common/jobs/panel-stage-setting';
import useSWR, { mutate } from 'swr';
import * as Sentry from '@sentry/nextjs';
import CandidateConfirmDisposition from 'apps/hiring/components/candidate-portal/candidate-jobs/candidate-confirm-disposition';
import CandidateConfirmAdvanceWithInterviewRequestedOrCanceled from 'apps/hiring/components/candidate-portal/candidate-jobs/candidate-confirm-advance-with-interview-requested-or-canceled';
import { icons } from 'apps/hiring/components/candidate-portal/candidate-jobs/candidate-interview-agenda';
import SnackBarLayout from '@er/ui-lib/lib/common/shared/snackbar-layout';
import SnackBarErrorLayout from '@er/ui-lib/lib/common/shared/snackbar-error-layout';
import CustomCircularLoading from '@er/ui-lib/lib/common/shared/custom-circular-loading';
import { authenticateLoggedInCompanyUser } from '../../users/me';
import EditReviewStageTitle, {
  IEditReviewStageTitleData,
} from 'apps/hiring/components/candidate-portal/candidate-jobs/edit-review-stage-title';
import { useSidebar } from '@er/ui-lib';
import { findJobById } from '../../api/jobs/[id]';
import { getJobWithStagesSortedByStageOrder } from '../../api/jobs/[id]/stages-sorted-by-order';

const labels = require('@er/ui-lib/lib/common/shared/labels.js');

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 500,
  },
  hoverAcceptButton: {
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  greenColor: { color: '#FF0000' },
  buttonStyle: {
    width: '200px',
  },
  borderedButton: {
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  contentPadding: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRIght: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const interviewStatusOrdered = [
  INTERVIEW_STATUSES.REQUESTED,
  INTERVIEW_STATUSES.SCHEDULED,
  INTERVIEW_STATUSES.CANCELED,
  INTERVIEW_STATUSES.COMPLETED,
];

export function onClickBackButton(router, user) {
  router.push('/jobs-page/' + user?.company?._id);
}

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export async function getServerSideProps({
  req,
  query,
  res,
}: NextPageContext & { req: { session: any; user: any } }) {
  let job: IJob;
  let jobError: Error;
  let user: IUser;

  let initialJobStages = [];

  try {
    await withRequestContext(req, res);
    const { origin } = absoluteUrl(req, 'localhost:4200');
    let { id } = query;

    if (Array.isArray(id)) {
      id = id[0];
    }

    const authCheck = await authenticateLoggedInCompanyUser(req);

    if (authCheck.redirect) {
      return authCheck; // If the user isn't authenticated, return the redirect
    }

    user = authCheck.props.user;

    job = await findJobById(id);

    if (!job || !job.company.equals(user.company._id)) {
      return { redirect: { destination: '/' } };
    }

    initialJobStages = await getJobWithStagesSortedByStageOrder(job._id);
  } catch (error) {
    console.error('Job Error', error);
    Sentry.captureException(error);
    jobError = error;
  }

  return {
    props: {
      user: JSON.parse(JSON.stringify(user)),
      job: JSON.parse(JSON.stringify(job)),
      error: jobError?.message || null,
      initialJobStages: JSON.parse(JSON.stringify(initialJobStages)),
    },
  };
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

// I think that we completely should stop using job variable and use the swr data instead
export function JobPage({ job, user, error, initialJobStages }) {
  const router = useRouter();
  const { id } = router.query;

  const classes = useStyles();

  const jobSWR = useSWR(`/api/jobs/${id}`, fetchFetcher, {
    fallbackData: job && { data: job },
  })?.data?.data;

  //  I think that this variable should be rename to 'stagesSWR' and revisit the usages to be clear that we receive the stages only and not a job with the full infomation
  const jobsSWR = useSWR(
    `/api/jobs/${id}/stages-sorted-by-order`,
    fetchFetcher,
    {
      fallbackData: { data: initialJobStages },
    }
  );

  const [counter, setCounter] = useState(0);

  const loadingUseRef = useRef(false);
  const shouldShowSuccessSnackbar = useRef(false);
  const successMessage = useRef('');
  const shouldShowErrorSnackbar = useRef(false);
  const errorMessage = useRef('');
  const [loading, setLoading] = useState(false);
  const { open, toggleSidebar } = useSidebar();

  const [panelStageOpen, setPanelOpen] = useState<boolean>(false);
  const [confirmDispositionOpen, setConfirmDispositionOpen] =
    useState<Boolean>(false);

  const [oneOnOneStageOpen, setOneOnOneStageOpen] = useState<boolean>(false);
  const [editReviewStageNameOpen, setEditReviewStageNameOpen] =
    useState<boolean>(false);

  const [stage, setStage] = useState(null);

  const [agendaEditorOpen, setAgendaEditorOpen] = useState<boolean>(false);
  const [selectedHiringProcess, setSelectedHiringProcess] = useState({
    hiringProcessId: '',
    stageId: '',
  });
  const [advanceCandidateSourceStage, setAdvanceCandidateSourceStage] =
    useState(null);
  const [
    advanceCandidateDestinationStage,
    setAdvanceCandidateDestinationStage,
  ] = useState(null);

  const [advanceCandidatePosition, setAdvanceCandidatePosition] =
    useState(null);

  const [initialInterviewSegments, setInitialInterviewSegments] = useState<
    PanelType[]
  >([]);

  const [
    addStageOnTheRightOfSelectedStageOpen,
    setAddStageOnTheRightOfSelectedStageOpen,
  ] = useState<boolean>(false);
  const [addStageOnTheLeftOpen, setAddStageOnTheLeftOpen] =
    useState<boolean>(false);
  const [confirmAdvanceCandidateOpen, setConfirmAdvanceCandidateOpen] =
    useState<Boolean>(false);

  const [
    selectedStageForNewStageOnTheRight,
    setSelectedStageForNewStageOnTheRight,
  ] = useState(null);

  const createFirstStage = () => {
    setAddStageOnTheRightOfSelectedStageOpen(true);
    setSelectedStageForNewStageOnTheRight(null);
  };

  async function handleSubmitCreateStageOnTheRight(data: AddStageFormData) {
    setLoading(true);

    try {
      const createdStage = await fetch(
        '/api/stages/add-stage-on-the-right/' + job._id,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
            selectedStage: selectedStageForNewStageOnTheRight,
          }),
          method: 'POST',
        }
      )
        .then((r) => r.json())
        .then((r) => r.data);

      setAddStageOnTheRightOfSelectedStageOpen(false);
      setStage(createdStage);
      openDialogForInterviewType(data);

      mutate(`/api/jobs/${id}/stages-sorted-by-order`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitCreateStageOnTheLeft(data: AddStageFormData) {
    setLoading(true);
    try {
      const createdStage = await fetch(
        '/api/stages/add-stage-on-the-left/' + job._id,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
          }),
          method: 'POST',
        }
      )
        .then((r) => r.json())
        .then((r) => r.data);

      setAddStageOnTheLeftOpen(false);
      setStage(createdStage);
      openDialogForInterviewType(data);

      mutate(`/api/jobs/${id}/stages-sorted-by-order`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const openCreateReviewStageOnTheRight = async (selectedStage) => {
    setAddStageOnTheRightOfSelectedStageOpen(true);
    setSelectedStageForNewStageOnTheRight(selectedStage);
  };

  const openCreateStageOnTheLeft = async (selectedStage) => {
    setAddStageOnTheLeftOpen(true);
  };

  const onClickEditReviewStageName = (toEditStage) => {
    setStage(toEditStage);
    setEditReviewStageNameOpen(true);
  };

  const onClickEditStageButton = (toEditStage) => {
    const interviewSegments: PanelType[] = [];

    if (toEditStage && toEditStage.interviewSegment) {
      for (const interviewSegment of toEditStage?.interviewSegment) {
        interviewSegments.push({
          _id: interviewSegment._id,
          durationMinutes: interviewSegment.durationMinutes
            ? interviewSegment.durationMinutes
            : 60,
          focusArea: interviewSegment.focusArea,
          interviewers: interviewSegment.interviewers.map((interviewer) => {
            return interviewer._id;
          }),
        });
      }
    }
    setInitialInterviewSegments(interviewSegments);
    setStage(toEditStage);

    if (toEditStage.type.toLowerCase() == INTERVIEW_TYPES.PANEL.toLowerCase()) {
      setPanelOpen(true);
    } else if (
      toEditStage.type.toLowerCase() == INTERVIEW_TYPES.ON_ON_ONE.toLowerCase()
    ) {
      setOneOnOneStageOpen(true);
    }
  };

  const onClickDeleteStageButton = async (toDeleteStage) => {
    setLoading(true);
    try {
      await fetch('/api/stages/' + toDeleteStage._id, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ job: job, toDeleteStage: toDeleteStage }),
        method: 'DELETE',
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.status === 'success') {
            mutate(`/api/jobs/${id}/stages-sorted-by-order`);
          } else {
            openErrorMessageSnackBar(response?.errorMessage);
          }
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createReviewStageOnTheRight = async (selectedStage) => {
    setLoading(true);
    try {
      const createdStage = await fetch(
        '/api/stages/add-review-stage-on-the-right/' + job._id,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            selectedStage: selectedStage,
          }),
          method: 'POST',
        }
      )
        .then((r) => r.json())
        .then((r) => r.data);
      mutate(`/api/jobs/${id}/stages-sorted-by-order`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateJobOnTheRightClose = () => {
    setAddStageOnTheRightOfSelectedStageOpen(false);
    setSelectedStageForNewStageOnTheRight(null);
  };

  const handleCreateJobOnTheleftClose = () => {
    setAddStageOnTheLeftOpen(false);
    setStage(null);
    setInitialInterviewSegments([]);
  };

  const handleOneOnOneSettingsClose = () => {
    setOneOnOneStageOpen(false);
    setStage(null);
    setInitialInterviewSegments([]);
  };

  const handleAdminAgendaEditorClose = () => {
    setSelectedHiringProcess({
      hiringProcessId: '',
      stageId: '',
    });
    setAgendaEditorOpen(false);
  };

  const handleConfirmDispositionClose = () => {
    setSelectedHiringProcess({
      hiringProcessId: '',
      stageId: '',
    });
    setConfirmDispositionOpen(false);
  };

  const handleAdvanceCandidateConfirmationClose = () => {
    setSelectedHiringProcess({
      hiringProcessId: '',
      stageId: '',
    });

    setConfirmAdvanceCandidateOpen(false);
    setAdvanceCandidateSourceStage(null);
    setAdvanceCandidateDestinationStage(null);
  };

  const handleEditReviewStageNameClose = () => {
    setStage(null);
    setEditReviewStageNameOpen(false);
  };

  const handlePanelSettingsClose = () => {
    setPanelOpen(false);
    setStage(null);
    setInitialInterviewSegments([]);
  };

  const handleChangeType = (value) => {
    if (value == INTERVIEW_TYPES.PANEL) {
      setOneOnOneStageOpen(false);
      setPanelOpen(true);
    } else if (value == INTERVIEW_TYPES.ON_ON_ONE) {
      setPanelOpen(false);
      setOneOnOneStageOpen(true);
    }
  };

  function openDialogForInterviewType(data: AddStageFormData) {
    if (data.interviewType === INTERVIEW_TYPES.ON_ON_ONE) {
      setOneOnOneStageOpen(true);
    } else {
      setPanelOpen(true);
    }
  }

  async function handleOneOnOneStageSettingsSubmit(
    data: OneOnOneStageDataForm,
    selectedInterviewers: string[],
    interviewerRotation: STAGE_INTERVIEWER_ROTATION
  ) {
    let interviewSegment;
    if (initialInterviewSegments && initialInterviewSegments.length > 0) {
      interviewSegment = {
        _id: initialInterviewSegments[0]._id,
        durationMinutes: data.durationMinutes,
        interviewers: selectedInterviewers,
        focusArea: data.title,
      };
    } else {
      interviewSegment = {
        durationMinutes: data.durationMinutes,
        interviewers: selectedInterviewers,
        focusArea: data.title,
      };
    }

    setLoading(true);
    try {
      await fetch('/api/stages/one-on-one/edit-stage/' + stage._id, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          job: job,
          interviewSegment: interviewSegment,
          interviewerRotation: interviewerRotation,
        }),
        method: 'PATCH',
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.status === 'success') {
            openSuccessMessageSnackBar(labels.SUCCESS_IN_APP_MESSAGE);
            setOneOnOneStageOpen(false);
            setInitialInterviewSegments([]);
            setStage(null);
            mutate(`/api/jobs/${id}/stages-sorted-by-order`);
          }
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handlePanelSettingsSubmit(
    data: StageDataForm,
    interviewSegments: PanelType[],
    interviewerRotation: STAGE_INTERVIEWER_ROTATION
  ) {
    setLoading(true);
    try {
      await fetch('/api/stages/panel/edit-stage/' + stage._id, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          job: job,
          interviewSegments,
          interviewerRotation,
        }),
        method: 'PATCH',
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.status == 'Error') {
            openErrorMessageSnackBar(response?.errorMessage);
          } else {
            openSuccessMessageSnackBar(labels.SUCCESS_IN_APP_MESSAGE);
            mutate(`/api/jobs/${id}/stages-sorted-by-order`);
            setPanelOpen(false);
            setStage(null);
            setInitialInterviewSegments([]);
          }
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const displayInterviewStatuses = (hiringProcess, currentStage) => {
    if (currentStage.type == INTERVIEW_TYPES.OFFER) {
      return;
    }
    let statusCounter = new Map();
    let colorMap = buildDefaultColorMap();
    let hiringProcessStage;

    let actualStage = currentStage;

    if (currentStage.type == INTERVIEW_TYPES.REVIEW) {
      actualStage = jobsSWR?.data?.data?.stages?.find(
        (stage) => stage._id == currentStage.parentStage
      );
    }

    hiringProcessStage = hiringProcess?.stages?.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );
    let anyCompletedInterview = false;

    if (actualStage && hiringProcessStage && hiringProcessStage.interviews) {
      actualStage?.interviewSegment?.forEach((interviewSegment) => {
        const interview = hiringProcessStage.interviews.find(
          (interview) => interview?.interviewSegment === interviewSegment._id
        );

        if (!interview) {
          return;
        }

        let count = statusCounter.get(interview.status);
        statusCounter.set(interview.status, count ? count + 1 : 1);

        if (
          (interview.status === INTERVIEW_STATUSES.REQUESTED ||
            interview.status === INTERVIEW_STATUSES.COMPLETED) &&
          colorMap.get(interview.status) != INTERVIEW_COLOR_AGES.RED
        ) {
          updateColorMapForRequestedOrCompletedInterviews(colorMap, interview);
        }

        if (interview.status === INTERVIEW_STATUSES.COMPLETED) {
          anyCompletedInterview = true;
        }
      });
    }

    if (!anyCompletedInterview) {
      colorMap.set(INTERVIEW_STATUSES.COMPLETED, INTERVIEW_COLOR_AGES.GREY);
    }

    if (actualStage.type === INTERVIEW_TYPES.ON_ON_ONE) {
      if (
        hiringProcessStage.interviews &&
        hiringProcessStage.interviews?.length > 0
      ) {
        const singleInterview = hiringProcessStage.interviews[0];
        const Icon: typeof MailOutlineIcon = icons[singleInterview.status];

        return (
          <div
            style={{
              color: colorMap.get(singleInterview?.status),
              paddingLeft: 10,
              paddingBottom: 10,
            }}
          >
            <Box display="flex" alignItems="center">
              {!!Icon && <Icon />}
              &nbsp; &nbsp;
              <Typography variant="body1">
                {' '}
                {capitalizeFirstLetter(singleInterview?.status)}
              </Typography>
            </Box>
          </div>
        );
      }

      // Something is off if we reach this code, the OneOneOne stage should always have a single interviews
      return;
    }

    return (
      <div>
        <Divider />
        <Grid item container xs={12}>
          {interviewStatusOrdered.map((status, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                style={{
                  flex: '1 1 calc(25% - 1px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography style={{ color: colorMap.get(status) }}>
                  {statusCounter.get(status) ? statusCounter.get(status) : 0}
                </Typography>
                <Typography style={{ fontSize: '12px', color: 'grey' }}>
                  {capitalizeFirstLetter(status)}
                </Typography>
              </Grid>

              {/* Add Divider between words except after the last word */}
              {index < 3 && <Divider orientation="vertical" flexItem />}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    );
  };

  const buildDefaultColorMap = () => {
    const colorMap = new Map();
    colorMap.set(INTERVIEW_STATUSES.SCHEDULED, INTERVIEW_COLOR_AGES.GREEN);
    colorMap.set(INTERVIEW_STATUSES.CANCELED, INTERVIEW_COLOR_AGES.RED);
    colorMap.set(INTERVIEW_STATUSES.REQUESTED, INTERVIEW_COLOR_AGES.GREY);
    colorMap.set(INTERVIEW_STATUSES.COMPLETED, INTERVIEW_COLOR_AGES.GREEN);

    return colorMap;
  };

  const updateColorMapForRequestedOrCompletedInterviews = (
    colorMap,
    interview
  ) => {
    const colorAge =
      interview && interview?.colorAge
        ? interview.colorAge
        : INTERVIEW_COLOR_AGES.GREY;

    const mapColor = colorMap.get(interview.status);

    if (colorAge === INTERVIEW_COLOR_AGES.RED) {
      colorMap.set(interview.status, INTERVIEW_COLOR_AGES.RED);
    } else if (
      colorAge === INTERVIEW_COLOR_AGES.ORANGE &&
      mapColor !== INTERVIEW_COLOR_AGES.RED
    ) {
      colorMap.set(interview.status, INTERVIEW_COLOR_AGES.ORANGE);
    } else if (
      colorAge === INTERVIEW_COLOR_AGES.GREY &&
      mapColor !== INTERVIEW_COLOR_AGES.RED &&
      mapColor !== INTERVIEW_COLOR_AGES.ORANGE
    ) {
      colorMap.set(interview.status, INTERVIEW_COLOR_AGES.GREY);
    }
  };

  const handleOfferAcceptedClick = async (e, hiringProcessId) => {
    e.stopPropagation();

    await fetch(`/api/hiring-processes/${hiringProcessId}/offer-accepted`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(() => {
        return mutate(`/api/jobs/${id}/stages-sorted-by-order`);
      })
      .then();
  };

  const handleOfferDeclinedCliked = async (e, hiringProcessId) => {
    e.stopPropagation();

    await fetch(`/api/hiring-processes/${hiringProcessId}/offer-declined`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(() => {
        return mutate(`/api/jobs/${id}/stages-sorted-by-order`);
      })
      .then();
  };

  const handleConfirmDispositionClick = async (
    hiringProcessId,
    sendCandidateNotification
  ) => {
    await fetch(
      `/api/hiring-processes/${hiringProcessId}/disposition-candidate`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sendCandidateNotification: sendCandidateNotification,
        }),
        method: 'POST',
      }
    )
      .then(() => {
        return mutate(`/api/jobs/${id}/stages-sorted-by-order`);
      })
      .then();

    setConfirmDispositionOpen(false);
    setSelectedHiringProcess({
      hiringProcessId: '',
      stageId: '',
    });
  };

  const handleConfirmAdvanceCandidateClick = async (
    hiringProcess,
    sourceLane,
    targetLane,
    position
  ) => {
    setLoading(true);
    try {
      await advanceCandidate(sourceLane, targetLane, position, hiringProcess);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    handleAdvanceCandidateConfirmationClose();
  };

  const handleSaveReviewStageName = async (
    data: IEditReviewStageTitleData,
    localStage: IStage
  ) => {
    await fetch('/api/stages/review-stage-name/' + localStage._id, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reviewStageTitle: data?.reviewStageTitle,
        jobId: id,
      }),
      method: 'PATCH',
    })
      .then((r) => r.json())
      .then((response) => {
        if (response?.status == 'Error') {
          openErrorMessageSnackBar(response?.errorMessage);
        } else {
          openSuccessMessageSnackBar(labels.SUCCESS_IN_APP_MESSAGE);
        }
      });

    mutate(`/api/jobs/${id}/stages-sorted-by-order`);
    handleEditReviewStageNameClose();
  };

  const refreshAgendaEditorData = async () => {
    return mutate(`/api/jobs/${id}/stages-sorted-by-order`);
  };

  const currentHiringProcessStageHasScheduledInterview = (hiringProcess) => {
    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    if (hiringProcessStage && hiringProcessStage.interviews) {
      for (const interview of hiringProcessStage.interviews) {
        if (interview && interview.status == INTERVIEW_STATUSES.SCHEDULED) {
          return true;
        }
      }
    }

    return false;
  };

  const handleDispositionCandidateClick = (e, hiringProcessId, stage) => {
    setConfirmDispositionOpen(true);
    setSelectedHiringProcess({
      hiringProcessId: hiringProcessId,
      stageId: stage._id,
    });

    e.stopPropagation();
  };

  const displayDispositionButton = (hiringProcess, stage) => {
    if (stage && stage.type !== INTERVIEW_TYPES.OFFER) {
      if (currentHiringProcessStageHasScheduledInterview(hiringProcess)) {
        return;
      }

      return (
        <div>
          <IconButton
            aria-label="disposition"
            onClick={(e) =>
              handleDispositionCandidateClick(e, hiringProcess._id, stage)
            }
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }
  };

  const displayHiringProcessOfferButtons = (hiringProcess, stage) => {
    if (stage.type == INTERVIEW_TYPES.OFFER) {
      return (
        <div>
          <Divider />
          <Grid item container xs={12}>
            <React.Fragment key={'Accepted'}>
              <Grid
                item
                style={{
                  flex: '1 1 calc(25% - 1px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button
                  onClick={(e) =>
                    handleOfferAcceptedClick(e, hiringProcess._id)
                  }
                  className={classes.hoverAcceptButton}
                  color="primary"
                  style={{ paddingBottom: 10, paddingTop: 10 }}
                >
                  Accepted
                </Button>
              </Grid>

              <Divider orientation="vertical" flexItem />
            </React.Fragment>
            <React.Fragment key={'Declined'}>
              <Grid
                item
                style={{
                  flex: '1 1 calc(25% - 1px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button
                  onClick={(e) =>
                    handleOfferDeclinedCliked(e, hiringProcess._id)
                  }
                  style={{ color: 'red', paddingBottom: 10, paddingTop: 10 }}
                  className={classes.hoverAcceptButton}
                  color="primary"
                >
                  Declined
                </Button>
              </Grid>
              <Divider orientation="vertical" flexItem />
            </React.Fragment>
          </Grid>
        </div>
      );
    }
  };

  const getCardName = (hiringProcess) => {
    if (
      hiringProcess?.candidate?.firstName &&
      hiringProcess?.candidate?.lastName
    ) {
      return (
        hiringProcess.candidate.firstName +
        ' ' +
        hiringProcess.candidate.lastName
      );
    }

    return hiringProcess?.candidate?.email;
  };

  const buildDragAndDropData = () => {
    if (jobsSWR.data?.data?.stages) {
      const lanesData = jobsSWR.data?.data?.stages.map((stage) => {
        const cards = stage?.hiringProcessOrdered?.map((hiringProcess) => {
          return {
            id: hiringProcess._id,
            name: getCardName(hiringProcess),
            cardStyle: {
              width: 300,
              maxWidth: 300,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              marginBottom: 15,
              border: '1px solid lightgrey',
              boxShadow:
                '0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
            },
            hiringProcess: hiringProcess,
            currentStage: stage,
            metadata: { id: hiringProcess._id },
          };
        });

        if (stage.type === INTERVIEW_TYPES.REVIEW) {
          return {
            id: stage._id,
            title: stage.title,
            label: stage.title,
            style: {
              width: 350,
              maxHeight: 'calc(100vh - 260px)',
              backgroundColor: 'white',
              boxSizing: 'border-box',
              border: '2px dashed #E5E5E5',
            },
            cards: cards,
            stage: stage,
          };
        }

        return {
          id: stage._id,
          title: stage.title,
          label: stage.title,
          style: {
            width: 350,
            boxSizing: 'border-box',
            backgroundColor: 'white',
            border: '2px solid #E5E5E5',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 260px)',
          },

          cards: cards,
          stage: stage,
        };
      });

      return { lanes: lanesData };
    }
    return { lanes: [] };
  };

  const advanceCandidate = async (
    sourceLane,
    targetLane,
    position,
    hiringProcess
  ) => {
    return fetch(
      `/api/hiring-processes/${hiringProcess?._id}/advance-candidate`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          targetLane: targetLane,
          sourceLane: sourceLane,
          position: position,
        }),
        method: 'POST',
      }
    )
      .then((r) => r.json())
      .then((response) => {
        if (response?.advanceEmailSent) {
          const candidateName =
            hiringProcess?.candidate?.firstName +
            ' ' +
            hiringProcess?.candidate?.lastName;

          const stageName = targetLane?.title;

          openSuccessMessageSnackBar(
            labels.DragDropSuccess(candidateName, stageName)
          );
        }
        mutate(`/api/jobs/${id}/stages-sorted-by-order`);
      });
  };

  const handleDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    if (sourceLaneId == targetLaneId) {
      console.log('same lane move is not allowed');
      return false;
    }

    if (jobsSWR.data?.data) {
      const sourceLane = jobsSWR.data?.data?.stages?.find(
        (stage) => stage._id == sourceLaneId
      );
      const targetLane = jobsSWR.data?.data?.stages?.find(
        (stage) => stage._id == targetLaneId
      );

      if (!sourceLane || !targetLane) {
        console.log('source or target lane not found');
        return false;
      }

      if (
        (targetLane.type === INTERVIEW_TYPES.ON_ON_ONE ||
          targetLane.type === INTERVIEW_TYPES.PANEL) &&
        targetLane.fullyConfigured === false
      ) {
        openErrorMessageSnackBar(
          labels.CannotMoveCandidateToStageNotConfigured
        );

        return false;
      }

      if (
        targetLane.type === INTERVIEW_TYPES.REVIEW &&
        targetLane.stageOrder &&
        sourceLane.stageOrder &&
        targetLane.stageOrder !== sourceLane.stageOrder + 1
      ) {
        openErrorMessageSnackBar(
          labels.CannotMoveCandidateToReviewStageNotAssociatedWithCurrent
        );

        return false;
      }

      const draggedHiringProcess = cardDetails.hiringProcess;

      if (hiringProcessHasScheduledInterview(draggedHiringProcess)) {
        const candidateName =
          draggedHiringProcess?.candidate?.firstName +
          ' ' +
          draggedHiringProcess?.candidate?.lastName;
        const stageName = sourceLane?.title;

        openErrorMessageSnackBar(
          labels.CannotMoveScheduledCandidate(candidateName, stageName)
        );
        return false;
      }

      if (
        hiringProcessHasRequestedOrCancelledInterview(draggedHiringProcess) &&
        !dragToCorrespondingReviewStage(sourceLane, targetLane) &&
        !dragBackFromReviewStage(sourceLane, targetLane)
      ) {
        setConfirmAdvanceCandidateOpen(true);
        setSelectedHiringProcess({
          hiringProcessId: draggedHiringProcess._id,
          stageId: draggedHiringProcess.currentStageOnTheBoard,
        });
        setAdvanceCandidateSourceStage(sourceLane);
        setAdvanceCandidateDestinationStage(targetLane);
        setAdvanceCandidatePosition(position);

        return false;
      }

      loadingUseRef.current = true;
      forceRerender();

      fetch(
        `/api/hiring-processes/${draggedHiringProcess?._id}/advance-candidate`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            targetLane: targetLane,
            sourceLane: sourceLane,
            position: position,
          }),
          method: 'POST',
        }
      )
        .then((r) => r.json())
        .then((response) => {
          if (response?.advanceEmailSent) {
            const candidateName =
              draggedHiringProcess?.candidate?.firstName +
              ' ' +
              draggedHiringProcess?.candidate?.lastName;

            const stageName = targetLane?.title;

            openSuccessMessageSnackBar(
              labels.DragDropSuccess(candidateName, stageName)
            );
          }
          loadingUseRef.current = false;
          mutate(`/api/jobs/${id}/stages-sorted-by-order`);
        });

      return true;
    }

    return false;
  };

  function hiringProcessHasRequestedOrCancelledInterview(hiringProcess) {
    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    if (hiringProcessStage && hiringProcessStage.interviews) {
      for (const interview of hiringProcessStage.interviews) {
        if (
          interview &&
          (interview.status == INTERVIEW_STATUSES.REQUESTED ||
            interview.status == INTERVIEW_STATUSES.CANCELED)
        ) {
          return true;
        }
      }
    }
  }

  function hiringProcessHasScheduledInterview(hiringProcess) {
    let hiringProcessStage = hiringProcess.stages.find(
      (stage) => stage.stage === hiringProcess.currentStage
    );

    if (hiringProcessStage && hiringProcessStage.interviews) {
      for (const interview of hiringProcessStage.interviews) {
        if (interview && interview.status == INTERVIEW_STATUSES.SCHEDULED) {
          return true;
        }
      }
    }
  }

  function dragBackFromReviewStage(sourceLane, targetLane) {
    return (
      sourceLane.type === INTERVIEW_TYPES.REVIEW &&
      targetLane.stageOrder &&
      sourceLane.stageOrder &&
      targetLane.stageOrder + 1 === sourceLane.stageOrder
    );
  }

  function dragToCorrespondingReviewStage(sourceLane, targetLane) {
    return (
      targetLane.type === INTERVIEW_TYPES.REVIEW &&
      targetLane.stageOrder &&
      sourceLane.stageOrder &&
      targetLane.stageOrder === sourceLane.stageOrder + 1
    );
  }

  const CustomLaneHeader = ({ label, cards, title, stage }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const displayCreateReviewStageOnTheRightMenuItem = (stage) => {
      if (stage && stage.type == INTERVIEW_TYPES.OFFER) {
        return;
      }

      // disable the button if the current stage already has a review stage
      const buttonDisabled = jobsSWR.data?.data?.stages.find(
        (localStage) => localStage.parentStage == stage._id
      )
        ? true
        : false;

      if (
        stage.type != INTERVIEW_TYPES.REVIEW &&
        stage.type != INTERVIEW_TYPES.OFFER
      ) {
        return (
          <div>
            <MenuItem
              onClick={() => {
                createReviewStageOnTheRight(stage);
              }}
              disabled={buttonDisabled}
            >
              Create Review Stage On The Right
            </MenuItem>
          </div>
        );
      }
    };

    const displayCreateStageOnTheRightMenuItem = (stage) => {
      if (stage && stage.type == INTERVIEW_TYPES.OFFER) {
        // it is not allowed to create a stage on the right of the offer stage
        return;
      }

      // disable the button if the current stage has a review stage
      const buttonDisabled = jobsSWR.data?.data?.stages.find(
        (localStage) => localStage?.parentStage == stage._id
      )
        ? true
        : false;

      return (
        <MenuItem
          disabled={buttonDisabled}
          onClick={() => {
            openCreateReviewStageOnTheRight(stage);
          }}
        >
          Create Stage On The Right
        </MenuItem>
      );
    };

    const displayCreateStageOnTheLeftMenuItem = (stage) => {
      if (stage && stage?.stageOrder === 1) {
        return (
          <MenuItem
            onClick={() => {
              openCreateStageOnTheLeft(stage);
            }}
          >
            Create Stage On The Left
          </MenuItem>
        );
      }
    };

    const displayDelete = (localStage) => {
      if (localStage.type == INTERVIEW_TYPES.OFFER) {
        return;
      }

      return (
        <IconButton
          style={{ padding: '4px' }}
          aria-label="settings"
          onClick={() => onClickDeleteStageButton(localStage)}
        >
          <DeleteIcon />
        </IconButton>
      );
    };

    const displaySettings = (localStage) => {
      if (
        localStage.type == INTERVIEW_TYPES.REVIEW ||
        localStage.type == INTERVIEW_TYPES.OFFER
      ) {
        return;
      }

      return (
        <IconButton
          style={{ padding: '4px' }}
          aria-label="settings"
          onClick={() => onClickEditStageButton(localStage)}
        >
          <SettingsIcon />
        </IconButton>
      );
    };

    function displayStageMenuAction() {
      if (jobSWR && jobSWR?.status === JOB_STATUSES.ACTIVE) {
        return (
          <div>
            {displaySettings(stage)}
            {displayDelete(stage)}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ padding: '4px' }}
            >
              <AddIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {displayCreateStageOnTheLeftMenuItem(stage)}
              {displayCreateStageOnTheRightMenuItem(stage)}
              {displayCreateReviewStageOnTheRightMenuItem(stage)}
            </Menu>
          </div>
        );
      }
    }

    const displayFlash = () => {
      if (stage.type === INTERVIEW_TYPES.OFFER) {
        // There might be a better solution to handle this situation where the Offer/Review is displayed below the OneOnOne/Panel Stages, but this seems to be working
        return (
          <IconButton style={{ visibility: 'hidden', padding: '4px' }}>
            <FlashOnIcon />
          </IconButton>
        );
      }

      if (stage.type === INTERVIEW_TYPES.REVIEW) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => onClickEditReviewStageName(stage)}
              style={{ padding: '4px', marginTop: '2px', marginBottom: '2px' }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        );
      }

      if (
        stage?.type === INTERVIEW_TYPES.ON_ON_ONE ||
        stage?.type === INTERVIEW_TYPES.PANEL
      ) {
        if (stage.fullyConfigured) {
          return (
            <div>
              <IconButton
                aria-label="settings"
                color="primary"
                style={{ padding: '4px' }}
              >
                <FlashOnIcon />
              </IconButton>
            </div>
          );
        } else {
          return (
            <div>
              <IconButton aria-label="settings" style={{ padding: '4px' }}>
                <FlashOffIcon />
              </IconButton>
            </div>
          );
        }
      }
    };

    const displayStageType = (localStage) => {
      if (stage?.type === INTERVIEW_TYPES.OFFER) {
        return;
      }

      if (stage?.type === INTERVIEW_TYPES.REVIEW) {
        const actualStage = jobsSWR?.data?.data?.stages?.find((stage1) => {
          return stage1._id == stage.parentStage;
        });
        return (
          <div style={{ fontSize: 14 }}>
            <i>for</i> {actualStage?.title}
          </div>
        );
      }

      return <div style={{ fontSize: 14 }}> {stage?.type}</div>;
    };

    return (
      <div>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box display="flex" alignItems="center" marginBottom="-8px">
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>{title}</div>
              {displayFlash()}
            </Box>
            {displayStageType(stage)}
          </Grid>
          <Grid item>
            {stage &&
              stage.type != INTERVIEW_TYPES.OFFER &&
              displayStageMenuAction()}
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 10 }} />
      </div>
    );
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    shouldShowErrorSnackbar.current = false;
    errorMessage.current = '';

    forceRerender();
  };

  const openErrorMessageSnackBar = (newErrorMessage) => {
    shouldShowSuccessSnackbar.current = false;
    shouldShowErrorSnackbar.current = true;
    errorMessage.current = newErrorMessage;

    forceRerender();
  };

  const openSuccessMessageSnackBar = (newSuccessMessage) => {
    shouldShowSuccessSnackbar.current = true;
    shouldShowErrorSnackbar.current = false;
    successMessage.current = newSuccessMessage;
  };

  const CustomCard = ({
    onClick,
    className,
    name,
    cardStyle,
    cardColor,
    hiringProcess,
    currentStage,
  }) => {
    return (
      <MovableCardWrapper
        onClick={onClick}
        style={cardStyle}
        className={className}
      >
        <header
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 6,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>{name}</div>

          <div style={{ width: '40%', textAlign: 'right', fontSize: 13 }}>
            {displayDispositionButton(hiringProcess, currentStage)}
          </div>
        </header>
        <div>{displayInterviewStatuses(hiringProcess, currentStage)}</div>
        {displayHiringProcessOfferButtons(hiringProcess, currentStage)}
      </MovableCardWrapper>
    );
  };

  const handleCardClick = (cardId, metadata, laneId) => {
    setSelectedHiringProcess({
      hiringProcessId: cardId,
      stageId: laneId,
    });
    setAgendaEditorOpen(true);
  };

  const forceRerender = () => setCounter((prevCounter) => prevCounter + 1);

  const handleCloseSuccessNotification = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    shouldShowSuccessSnackbar.current = false;
    forceRerender();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <BaseLayout companyId={job.company} user={user} noContentPadding>
        {(loading || loadingUseRef?.current) && <CustomCircularLoading />}
        {addStageOnTheRightOfSelectedStageOpen && (
          <AddStage
            addStageOpen={addStageOnTheRightOfSelectedStageOpen}
            onSubmit={handleSubmitCreateStageOnTheRight}
            onClose={handleCreateJobOnTheRightClose}
          />
        )}
        {addStageOnTheLeftOpen && (
          <AddStage
            addStageOpen={addStageOnTheLeftOpen}
            onSubmit={handleSubmitCreateStageOnTheLeft}
            onClose={handleCreateJobOnTheleftClose}
          />
        )}
        {agendaEditorOpen && (
          <AdminAgendaEditor
            adminAgendaEditorOpen={agendaEditorOpen}
            hiringProcess={
              jobsSWR.data?.data?.stages
                .filter((stage) => {
                  return stage._id === selectedHiringProcess?.stageId;
                })?.[0]
                ?.hiringProcessOrdered?.filter((hp) => {
                  return hp._id === selectedHiringProcess?.hiringProcessId;
                })?.[0] || null
            }
            job={jobsSWR.data?.data}
            onClose={handleAdminAgendaEditorClose}
            refreshAgendaEditorData={refreshAgendaEditorData}
            user={user}
          />
        )}
        {confirmDispositionOpen && (
          <CandidateConfirmDisposition
            hiringProcess={
              jobsSWR.data?.data?.stages
                .filter((stage) => {
                  return stage._id === selectedHiringProcess?.stageId;
                })?.[0]
                ?.hiringProcessOrdered?.filter((hp) => {
                  return hp._id === selectedHiringProcess?.hiringProcessId;
                })?.[0] || null
            }
            stage={
              jobsSWR.data?.data?.stages.filter((stage) => {
                return stage._id === selectedHiringProcess?.stageId;
              })?.[0]
            }
            onSubmit={handleConfirmDispositionClick}
            onClose={handleConfirmDispositionClose}
          />
        )}
        {confirmAdvanceCandidateOpen && (
          <CandidateConfirmAdvanceWithInterviewRequestedOrCanceled
            hiringProcess={
              jobsSWR.data?.data?.stages
                .filter((stage) => {
                  return stage._id === selectedHiringProcess?.stageId;
                })?.[0]
                ?.hiringProcessOrdered?.filter((hp) => {
                  return hp._id === selectedHiringProcess?.hiringProcessId;
                })?.[0] || null
            }
            sourceStage={advanceCandidateSourceStage}
            destinationStage={advanceCandidateDestinationStage}
            advanceCandidatePosition={advanceCandidatePosition}
            onSubmit={handleConfirmAdvanceCandidateClick}
            onClose={handleAdvanceCandidateConfirmationClose}
            loading={loading}
          />
        )}
        {editReviewStageNameOpen && (
          <EditReviewStageTitle
            stage={stage}
            onSubmit={handleSaveReviewStageName}
            onClose={handleEditReviewStageNameClose}
          />
        )}
        {oneOnOneStageOpen && (
          <SetupOneOnOneStage
            addStageOpen={oneOnOneStageOpen}
            onSubmit={handleOneOnOneStageSettingsSubmit}
            onClose={handleOneOnOneSettingsClose}
            onTypeChange={handleChangeType}
            job={job}
            stage={stage}
            interviewSegment={initialInterviewSegments[0]}
            loading={loading}
          />
        )}
        {panelStageOpen && (
          <SetupPanelStage
            addStageOpen={panelStageOpen}
            onSubmit={handlePanelSettingsSubmit}
            onClose={handlePanelSettingsClose}
            onTypeChange={handleChangeType}
            initialInterviewSegments={initialInterviewSegments}
            job={job}
            stage={stage}
            loading={loading}
          />
        )}
        <SnackBarLayout
          open={shouldShowSuccessSnackbar?.current ? true : false}
          handleClose={handleCloseSuccessNotification}
          message={successMessage.current}
        ></SnackBarLayout>
        <SnackBarErrorLayout
          open={shouldShowErrorSnackbar?.current ? true : false}
          handleClose={handleClose}
          errorMessage={errorMessage.current}
        ></SnackBarErrorLayout>
        <div
          style={{
            backgroundColor: 'white',
            paddingTop: '12px',
          }}
          className={classes.contentPadding}
        >
          <Button
            startIcon={
              <ArrowBackIosIcon
                style={{ padding: '4px', backgroundColor: 'white' }}
              />
            }
            style={{ marginBottom: '8px' }}
            onClick={() => onClickBackButton(router, user)}
          >
            BACK
          </Button>
          <ShowJob job={jobSWR} selectedTab={JOB_PAGE_TABS.BOARD} />
        </div>
      </BaseLayout>

      {jobsSWR.data?.data?.stages?.length > 1 ? (
        <div
          style={{
            overflow: 'hidden',
            flexGrow: 1,
            marginLeft: open ? drawerWidth : closedDrawerWidth,
          }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Board
            data={buildDragAndDropData()}
            draggable
            laneDraggable={false}
            onCardClick={handleCardClick}
            handleDragEnd={handleDragEnd}
            hideCardDeleteIcon
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              backgroundColor: '#f8f8f8',
              marginTop: '8px',
            }}
            components={{ LaneHeader: CustomLaneHeader, Card: CustomCard }}
          />
        </div>
      ) : (
        <div
          style={{
            marginLeft: open ? drawerWidth : closedDrawerWidth,
            backgroundColor: '#f8f8f8',
          }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Button
            onClick={createFirstStage}
            color="primary"
            variant="text"
            style={{
              marginTop: '32px',
              marginLeft: '24px',
            }}
            className={clsx(classes.buttonStyle, classes.borderedButton)}
          >
            Create Stage
          </Button>
        </div>
      )}
    </div>
  );
}

export default JobPage;

import React, { FC } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from 'next/link';
import { capitalize, kebabCase } from 'lodash';
import { Button } from '@material-ui/core';
import { signOut } from 'next-auth/client';
import { useRouter } from 'next/router';

const drawerWidth = 240;

export enum ADMIN_PAGES {
  Companies,
  'SpaceOwner Invites',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
  })
);

export interface IAdminSideBarProps {
  page: ADMIN_PAGES;
}

export const AdminSideBar: FC<IAdminSideBarProps> = ({ page }) => {
  const classes = useStyles();

  const router = useRouter();

  const performLogout = async () => {
    await signOut({ redirect: false });

    router.push('/users/logout');
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {Object.entries(ADMIN_PAGES)
            .filter(([key]) => isFinite(+key))
            .map(([key, value]: [string, string]) => (
              <Link key={key} href={`/admin/${kebabCase(value)}`} passHref>
                <ListItem selected={'' + page === key} button component="a">
                  <ListItemText
                    primary={capitalize(kebabCase(value)).replace(/\-/g, ' ')}
                  />
                </ListItem>
              </Link>
            ))}
        </List>
        <Button onClick={() => performLogout()} variant="contained">
          Log Out
        </Button>
      </div>
    </Drawer>
  );
};

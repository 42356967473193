import mongoose, { model, Schema } from 'mongoose';
import { USER_TYPES } from './user.constants';
import { IUser, IUserModel } from './user.interface';

export const DEFAULT_LAST_TIME_CHECKED_FOR_LOW_AVAILABILITY_DATE =
  '2000-01-01T00:00:00Z';

export const UserSchema: Schema<IUser, IUserModel> = new Schema(
  {
    type: { type: String, enum: Object.values(USER_TYPES), required: true },
    firstName: { type: String },
    lastName: { type: String },
    email: { type: String, required: true, unique: true },
    timeZone: { type: String },
    jobTitle: { type: String },
    password: { type: String, select: false },
    verified: { type: Boolean, default: false },
    accessToken: { type: String },
    refreshToken: { type: String },
    subId: { type: String },
    accessTokenExpiresAt: { type: Date },
    linkedInURL: { type: String },
    company: { type: Schema.Types.ObjectId, ref: 'Company' },
    calendarSynced: { type: Boolean, default: false },
    availabilitySet: { type: Boolean, default: false },
    // A more appropriate name should be lastTimeCheckedForLowAvailabilityCronJob
    lastTimeCheckedForLowAvailability: {
      type: Date,
      default: new Date(DEFAULT_LAST_TIME_CHECKED_FOR_LOW_AVAILABILITY_DATE),
    },
    mustCheckForLowAvailability: { type: Boolean, default: false },
    durationToCheckForLowAvailability: { type: Number, default: 0 },
    lastReadMyAvailabilitySchedulingOptions: {
      type: Date,
      default: new Date(DEFAULT_LAST_TIME_CHECKED_FOR_LOW_AVAILABILITY_DATE),
    },
    cachedLongestInterviewDuration: { type: Number, default: 0 },
    cachedSchedulingOptionsCount: { type: Number, default: 0 },
    lastZeroAvailabilityNotificationSentTriggeredByCandidateAction: {
      type: Date,
    },
    lastLowAvailabilityNotificationSentTriggeredByCandidateAction: {
      type: Date,
    },
    resumeFileName: { type: String },
    cronofyProviderService: { type: String },
    resetPasswordToken: { type: String },
    resetPasswordTokenExpiry: { type: Date },
  },
  { timestamps: true }
);

UserSchema.index({ company: 1 });

export const UserModel: IUserModel = (mongoose.models?.User ||
  model('User', UserSchema)) as IUserModel;

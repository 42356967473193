export enum INTERVIEW_TYPES { // TODO - This should be renamed to Stage_Types
  ON_ON_ONE = 'One-on-One',
  PANEL = 'Sequential', // TODO - In the interest of time I did not have time to rename this variable to Panel
  REVIEW = 'Review',
  OFFER = 'Offer',
}

export enum INTERVIEW_STATUSES {
  REQUESTED = 'requested',
  SCHEDULED = 'scheduled',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

export enum INTERVIEW_TIME_CHANGE_REASON {
  RESCHEDULE = 'reschedule',
  SWITCH_INTERVIEWER = 'switch-interviewer',
}

export enum INTERVIEW_COLOR_AGES {
  GREY = 'grey',
  ORANGE = 'orange',
  RED = 'red',
  GREEN = 'green',
}

export enum INTERVIEW_SORT_FIELDS {
  CANDIDATE_NAME = 'CandidateName',
  JOB_TITLE = 'JobTitle',
  INTERVIEW_STATUS = 'Status',
  START_TIME = 'StartTime',
  INTERVIEWER_NAME = 'InterviewerName',
  HIRING_PROCESS_STATUS = 'HiringProcessStatus',
}

export enum INTERVIEW_SORT_ORDER {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum INTERVIEW_CANCEL_REASONS {
  ADMIN_CANCEL = 'Admin Cancel',
  CANDIDATE_NO_SHOW = 'Candidate No Show',
  NOT_FIRST_TIME_ON_THIS_STAGE = 'Candidate was already on this stage',
}

export enum REQUEST_OPERATION_TYPES {
  MARK_AS_NO_SHOW_INTERVIEWER = 'MarkAsNoShowInterviewer',
  INTERVIEWER_DECLINES_FROM_CALENDAR = 'InterviewerDeclinesFromCalendar',
  CANDIDATE_DECLINES_FROM_CALENDAR = 'CandidateDeclinesFromCalendar',
  REQUEST_RESCHEDULE_ANY_INTERVIEWER = 'RequestRescheduleAnyInterviewer',
  REQUEST_RESCHEDULE_CURRENT_INTERVIEWER = 'RequestRescheduleCurrentInterviewer',
  REQUEST_RESCHEDULE_ROTATION_POLICY = 'RequestRescheduleRotationPolicy',
  FROM_CANCELLED_INTERVIEW = 'FromCancelledInterview',
}

export enum INTERVIEW_REQUEST_REASONS {
  NEW_STAGE = 'New Stage',
  COMPANY_REQUEST_RESCHEDULE = 'Company Request Reschedule',
  INTERVIEWER_DECLINE_CALENDAR = 'Interviewer Decline Calendar',
  CANDIDATE_DECLINE_CALENDAR = 'Candidate Decline Calendar',
  INTERVIEWER_MARKED_NO_SHOW_ADMIN = 'Interviewer Marked No Show Admin',
  INTERVIEWER_MARKED_NO_SHOW_CANDIDATE = 'Interviewer Marked No Show Candidate',
}

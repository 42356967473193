import { debounce } from 'lodash';
import { IOpenHoursSlot } from '@er/data-hiring';
import { Button, Grid } from '@material-ui/core';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { NextPageContext } from 'next';
import { DayOfWeekSlot, ITimeRange } from './availability-editor-slot';

export interface IAvailabilityEditorProps {
  availabilityMap: Map<string, ITimeRange[]>;
  onAdd?: (dayOfWeek: string) => any;
  onDelete?: (dayOfWeek: string, id: number) => any;
  onChange?: (
    dayOfWeek: string,
    id: number,
    startTime: string,
    endTime: string
  ) => any;
}

export interface ITimeSegment {
  startTimeUTC: number;
  endTimeUTC: number;
}

export const AvailabilityEditor: FunctionComponent<IAvailabilityEditorProps> =
  ({ availabilityMap, onAdd, onChange, onDelete }) => {
    const daysOfTheWeek = [
      { day: 'monday' },
      { day: 'tuesday' },
      { day: 'wednesday' },
      { day: 'thursday' },
      { day: 'friday' },
      { day: 'saturday' },
      { day: 'sunday' },
    ];

    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        {daysOfTheWeek &&
          daysOfTheWeek.map((slot, idx) => (
            <Grid key={idx} item>
              <DayOfWeekSlot
                dayOfWeek={slot.day}
                timeSegmentSelection={
                  availabilityMap?.get(slot.day)
                    ? availabilityMap.get(slot.day)
                    : []
                }
                onAdd={onAdd}
                onChange={onChange}
                onDelete={onDelete}
              />
            </Grid>
          ))}
      </Grid>
    );
  };

export default AvailabilityEditor;

import mongoose, { model, Schema } from 'mongoose';
import { InterviewSegmentSchema, StageSchema } from '../job';
import {
  INTERVIEW_CANCEL_REASONS,
  INTERVIEW_COLOR_AGES,
  INTERVIEW_REQUEST_REASONS,
  INTERVIEW_STATUSES,
  INTERVIEW_TIME_CHANGE_REASON,
  INTERVIEW_TYPES,
} from './interview.constants';
import { IInterview, IInterviewModel } from './interview.interface';

export const InterviewSchema: Schema<IInterview, IInterviewModel> = new Schema(
  {
    title: { type: String, required: true },
    job: { type: Schema.Types.ObjectId, ref: 'Job' },
    type: {
      type: String,
      enum: Object.values(INTERVIEW_TYPES),
    },
    status: {
      type: String,
      enum: Object.values(INTERVIEW_STATUSES),
      default: INTERVIEW_STATUSES.REQUESTED,
      required: true,
    },
    candidate: {
      ref: 'User',
      type: Schema.Types.ObjectId,
      required: true,
    },
    hiringProcess: {
      ref: 'HiringProcess',
      type: Schema.Types.ObjectId,
      required: true,
    },
    meetingLink: { type: String },
    startTime: { type: Date },
    endTime: { type: Date },
    statusChangedAt: { type: Date },
    sentReminderAt: { type: Date },

    interviewers: {
      type: [{ type: Schema.Types.ObjectId, ref: 'User' }],
      required: true,
    },
    relatedEventId: { type: String },
    smartInviteId: { type: String },
    interviewSegment: {
      type: Schema.Types.ObjectId,
      ref:
        mongoose.models?.InterviewSegment ||
        model('InterviewSegment', InterviewSegmentSchema),
    },
    stage: {
      type: Schema.Types.ObjectId,
      ref: mongoose.models?.Stage2 || model('Stage2', StageSchema),
    },

    timeChangeReason: {
      type: String,
      enum: Object.values(INTERVIEW_TIME_CHANGE_REASON),
    },
    company: { type: Schema.Types.ObjectId, ref: 'Company', required: true },
    cancelReason: {
      type: String,
      enum: Object.values(INTERVIEW_CANCEL_REASONS),
    },
    cancelAtUTC: { type: Date },
    cancelInformation: { type: String },
    requestReason: {
      type: String,
      enum: Object.values(INTERVIEW_REQUEST_REASONS),
    },
    requestAtUTC: { type: Date },
    requestInformation: { type: String },
    colorAge: {
      type: String,
      enum: Object.values(INTERVIEW_COLOR_AGES),
      default: INTERVIEW_COLOR_AGES.GREY,
    },
    interviewerManuallyAssigned: { type: Boolean },
  },
  { timestamps: true }
);

InterviewSchema.index({ candidate: 1 });
InterviewSchema.index({ company: 1 });
InterviewSchema.index({ job: 1 });
InterviewSchema.index({ status: 1, endTime: 1 });

export const InterviewModel: IInterviewModel = (mongoose.models?.Interview ||
  model('Interview', InterviewSchema)) as IInterviewModel;

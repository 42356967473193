import { IInterview, INTERVIEW_STATUSES } from '@er/data-hiring';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  assignInterviewerDisabled,
  timeNowAfterInterviewStart,
} from './admin-agenda-editor-interview-segment-slot';
import { CHANGE_INTERVIEWER, MARK_AS_NO_SHOW } from './interviews-list';

export interface IScheduledInterviewActionItemsProps {
  interview: IInterview;
  handleClick: (selectedIndex, currentInterview) => any;
  options: string[];
}

export const SchduledInterviewsActionButtonGroup: FunctionComponent<IScheduledInterviewActionItemsProps> =
  ({ handleClick, interview, options }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
      handleClick(options[index], interview);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    const optionDisabled = (option, interview) => {
      if (option === MARK_AS_NO_SHOW) {
        if (timeNowAfterInterviewStart(interview)) {
          return false;
        }
        return true;
      }
      if (option === CHANGE_INTERVIEWER) {
        const currentInterviewStage = interview?.job?.stages.find(
          (stage) => stage._id === interview?.stage
        );

        const interviewSegment = currentInterviewStage?.interviewSegment?.find(
          (interviewSegment) =>
            interviewSegment._id === interview?.interviewSegment
        );

        return assignInterviewerDisabled(interview, interviewSegment);
      }

      return false;
    };

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            style={{
              color: '#FFFFFF',
              border: '2px solid #71A7A6',
            }}
            ref={anchorRef}
            aria-label="split button"
          >
            <Button
              style={{
                color: '#71A7A6',
                backgroundColor: '#FFFFFF',
                borderRight: '2px solid #71A7A6',
              }}
              onClick={() => handleClick(options[selectedIndex], interview)}
            >
              {selectedIndex == -1 ? 'Actions' : options[selectedIndex]}
            </Button>
            <Button
              style={{ backgroundColor: 'white', color: '#71A7A6' }}
              size="small"
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={optionDisabled(option, interview)}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  };

export default SchduledInterviewsActionButtonGroup;
